import { IFreeEntry, IFreeEntryBlock, IFreeEntryReference } from '../interfaces/IFreeEntry';
import { TFreeEntry } from '../types/TFreeEntry';

export const FreeEntryBlockDTO = ({ free_entry_block }: IFreeEntryBlock): TFreeEntry =>
  FreeEntryReferenceDTO(free_entry_block);

export const FreeEntryReferenceDTO = ({ free_entry_reference }: IFreeEntryReference): TFreeEntry =>
  FreeEntryDTO(free_entry_reference[0]);

export const FreeEntryDTO = (data: IFreeEntry): TFreeEntry => {
  const expirationDate = data?.expiration_row ? new Date(data?.expiration_row) : null;

  return {
    contentTypeUid: 'free_entry',
    expirationRow: expirationDate,
    isExpired: expirationDate !== null && expirationDate.valueOf() - new Date().valueOf() < 0,
    content: data?.content,
    uid: data?.uid,
  };
};
