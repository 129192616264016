import { IVideoPanelProps } from './types/IVideoPanelProps';
import { VideoGroup } from './VideoGroup';
import styles from './VideoPanel.module.scss';
import { VideoText } from './VideoText';

const VideoPanel = ({ videoPanel }: IVideoPanelProps) => {
  return (
    <div className={styles.container}>
      <VideoGroup data-testid="video-element" videoGroup={videoPanel.videoGroup} seoData={videoPanel.seoData} />
      <VideoText
        videoLinkGroup={videoPanel?.linkGroup?.[0]}
        headline={videoPanel?.headline}
        description={videoPanel?.description}
      />
    </div>
  );
};

export default VideoPanel;
