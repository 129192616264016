import { ILNECategorySlugProps } from './LNEFeaturedArticlesBlock/types/ILNEFeaturedArticlesBlockProps';
import { TLNEAuthorReference } from '../../../types/TLNEAuthor';

const CATEGORY_SLUG: ILNECategorySlugProps = {
  'Tips & Techniques': 'tips-and-techniques',
  'Ideas & Inspiration': 'ideas-and-inspiration',
  'Products & Innovation': 'products-and-innovation',
};

export const getLNECategorySlug = (category: string) => {
  return CATEGORY_SLUG[category];
};

export const getLNECollectionUrl = (collection_slug: string) => {
  return `/learn-and-explore/l/${collection_slug}`;
};

export const getLNECategoryUrl = (category: string) => {
  return `/learn-and-explore/c/${CATEGORY_SLUG[category]}`;
};

export const getLNEArticleUrl = (category: string, articleSlug: string) => {
  return `/learn-and-explore/c/${CATEGORY_SLUG[category]}/${articleSlug}`;
};

export const getLNEArticleBylineText = (authors: TLNEAuthorReference[], creators: TLNEAuthorReference[]) => {
  const byline = {
    authorByline: '',
    authorString: '',
    creatorByline: '',
    creatorString: '',
  };
  const numAuthors = authors.length;
  const numCreators = creators.length;

  if ((!authors || !Boolean(numAuthors)) && (!creators || !Boolean(numCreators))) {
    return byline;
  }

  if (
    numAuthors === 1 &&
    numCreators === 1 &&
    authors?.[0]?.authorReference?.[0].uid === creators?.[0]?.authorReference?.[0].uid
  ) {
    // if there is one author and one creator and they're the same person, display "By and Featuring: FIRSTNAME LASTNAME"
    const name = `${authors?.[0]?.authorReference?.[0]?.firstName} ${authors?.[0]?.authorReference?.[0]?.lastName}`;
    const slug = authors?.[0]?.authorReference?.[0]?.slug;
    byline.authorByline = 'by-and-featuring';
    byline.authorString = `<a href="/learn-and-explore/p/${slug}">${
      authors?.[0]?.authorReference?.[0]?.isAmbassador ? 'Nikon Ambassador ' : ''
    }${name}</a>`;
    return byline;
  } else {
    if (Boolean(numAuthors)) {
      byline.authorByline = 'by';
      authors?.map((authorReference, index) => {
        const author = authorReference?.authorReference?.[0];

        // append comma or ampersand if not first author
        if (index !== 0) {
          if (index === numAuthors - 1) {
            // last author
            byline.authorString += ' & ';
          } else {
            // not first author, not last author
            byline.authorString += ', ';
          }
        }

        // append ambassador name
        const name = `${author?.firstName} ${author?.lastName}`;
        const slug = author?.slug;
        byline.authorString += `<a href="/learn-and-explore/p/${slug}">${
          author?.isAmbassador ? 'Nikon Ambassador ' : ''
        }${name}</a>`;
      });
    }
    if (Boolean(numCreators)) {
      byline.creatorByline = 'featuring';
      creators?.map((creatorReference, index) => {
        const creator = creatorReference?.authorReference?.[0];

        // append comma or ampersand if not first creator
        if (index !== 0) {
          if (index === numCreators - 1) {
            // last creator
            byline.creatorString += ' & ';
          } else {
            // not first creator, not last creator
            byline.creatorString += ', ';
          }
        }

        // append ambassador name
        const name = `${creator?.firstName} ${creator?.lastName}`;
        const slug = creator?.slug;
        byline.creatorString += `<a href="/learn-and-explore/p/${slug}">${
          creator?.isAmbassador ? 'Nikon Ambassador ' : ''
        }${name}</a>`;
      });
    }
  }

  return byline;
};

export const getLNEArticleContributorFooterText = (creator: TLNEAuthorReference) => {
  const name = `${creator?.authorReference?.[0]?.firstName} ${creator?.authorReference?.[0]?.lastName}`;
  const slug = creator?.authorReference?.[0]?.slug;
  return {
    authorByline: '',
    authorString: '',
    creatorByline: 'featuring',
    creatorString: `<a href="/learn-and-explore/p/${slug}">${name}</a>`,
  };
};
