import React from 'react';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import { ImageHelpers } from 'helpers/imageHelpers';
import styles from './Definitions.module.scss';
import { IDefinitionsProps } from './types/IDefinitionsProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';

const Definitions = ({ data: { headline, definitionItems } }: IDefinitionsProps) => {
  const [containerRef, isVisible] = useAnimation();

  if (!Boolean(definitionItems.length)) {
    return false as unknown as JSX.Element;
  }

  return (
    <Container
      data-testid={'definitions-block'}
      ref={containerRef as any}
      className={classNames('animate', 'content-block', { 'in-view': isVisible }, styles['container'])}
    >
      {headline && (
        <h2 className={styles['heading']} data-testid={`heading`}>
          {headline}
        </h2>
      )}

      <ul className={styles['tabs']} data-testid={'tabs'}>
        {definitionItems.map(({ definitionIcon, definitionHeadline, definitionDescription }, index) => (
          <li key={index} className={`col-xs-12 ${styles['tab-item']}`} data-testid={'tab-item'}>
            <div className={`${styles['icon-wrap']}`}>
              {definitionIcon && (
                <img
                  src={ImageHelpers.modifiedContentStackImageUrl(definitionIcon.url, '145')}
                  className={`${styles['icon']}`}
                  title={definitionIcon.title}
                  data-testid={'definition-icon'}
                  loading="lazy"
                  alt={definitionIcon.title}
                />
              )}
            </div>

            <div className={`${styles['info-wrap']}`}>
              {definitionHeadline && (
                <h3 className={`${styles['title']}`} data-testid={'definition-headline'}>
                  {definitionHeadline}
                </h3>
              )}

              {definitionDescription && (
                <p className={`${styles['description']}`} data-testid={'definition-description'}>
                  {definitionDescription}
                </p>
              )}
            </div>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default Definitions;
