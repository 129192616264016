import styles from './ResponsiveVideo.module.scss';
import { IResponsiveVideoProps } from './types/IResponsiveVideoProps';
import { HORIZONTAL_ALIGNMENTS, VERTICAL_ALIGNMENTS } from '../../../../helpers/constants/CommonFieldsConstants';
import { EHorizontalAlignments, EVerticalAlignments } from '../../../../types/CommonFields';

const ResponsiveVideo = ({
  src,
  contentType,
  posterImage,
  verticalPosition = EVerticalAlignments.Center,
  horizontalPosition = EHorizontalAlignments.Center,
  controls = false,
  autoplay = false,
  loop = false,
  muted = true,
  customClass = '',
}: IResponsiveVideoProps) => {
  if (!src || !contentType) {
    return null;
  }

  const verticalStyle = styles[VERTICAL_ALIGNMENTS[verticalPosition]];
  const horizontalStyle = styles[HORIZONTAL_ALIGNMENTS[horizontalPosition]];

  return (
    <video
      src={src}
      typeof={contentType}
      controls={controls}
      autoPlay={autoplay}
      muted={muted}
      loop={loop}
      poster={posterImage?.url}
      className={`${styles.video} ${verticalStyle} ${horizontalStyle} ${customClass}`}
      data-testid="responsive-video"
    />
  );
};

export default ResponsiveVideo;
