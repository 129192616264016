import { MetaDataVideo } from 'components/SEO/Video';
import { IVideoUploaded } from './types/IVideoUploaded';

const VideoUploaded = ({
  src = '',
  contentType,
  posterImage,
  controls = true,
  autoplay = false,
  loop = false,
  seoData,
}: IVideoUploaded) => {
  if (!src.length) {
    return null;
  }

  return (
    <video
      data-testid="video-uploaded"
      controls={controls}
      autoPlay={autoplay}
      muted={autoplay}
      loop={loop}
      poster={posterImage?.url}
    >
      <source src={src} typeof={contentType} />
      <MetaDataVideo
        name={seoData?.name || null}
        contentUrl={seoData?.contentUrl || null}
        description={seoData?.description || null}
        duration={seoData?.duration || null}
        embedUrl={seoData?.embedUrl || null}
        thumbnailUrl={seoData?.thumbnailUrl || null}
        uploadDate={seoData?.uploadDate || null}
      />
    </video>
  );
};

export default VideoUploaded;
