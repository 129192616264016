import classNames from 'classnames';
import { IRichTextEditorNoSpostoProps } from './types/IRichTextEditorNoSpostoProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';

const RichTextEditorNoSposto = ({ data: { isExpired, content, uid } }: IRichTextEditorNoSpostoProps) => {
  const [containerRef, isVisible] = useAnimation();
  if (isExpired || !Boolean(content?.length)) {
    return false as unknown as JSX.Element;
  }

  return (
    <div
      data-testid="rich-text-editor-no-sposto"
      ref={containerRef as any}
      className={classNames('animate', 'content-block', { 'in-view': isVisible })}
    >
      {content.map((block, index) => (
        <div data-testid="content-text" key={`${uid}-${index}`} dangerouslySetInnerHTML={{ __html: block }} />
      ))}
    </div>
  );
};

export default RichTextEditorNoSposto;
