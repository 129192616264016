import classNames from 'classnames';
import useAnimation from 'helpers/hooks/useAnimation';
import { IAboveProductListProps } from './types/IAboveProductListProps';

const AboveProductList = ({ data: { content } }: IAboveProductListProps) => {
  const [containerRef, isVisible] = useAnimation();

  if (!content) {
    return false as unknown as JSX.Element;
  }

  return (
    <div
      data-testid="above-product-list"
      ref={containerRef as any}
      className={classNames('animate content-block above-product-list container', { 'in-view': isVisible })}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default AboveProductList;
