import { useMemo } from 'react';
import classNames from 'classnames';
import { kebabCase } from 'lodash';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './FullWidthText.module.scss';
import FullWidthTextLink from './FullWidthTextLink';
import { IFullWidthTextProps } from './types/IFullWidthTextProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';

const FullWidthText = ({ data }: IFullWidthTextProps) => {
  const {
    title,
    headline,
    headlinePlacement,
    subHead,
    subHeadPlacement,
    description,
    descriptionPlacement,
    links,
    uid,
    backgroundColor,
    textColor,
  } = data;

  const isShow = title || headline || description || links.length;
  const [containerRef, isVisible] = useAnimation();

  const bgColor = useMemo(() => {
    if (!backgroundColor) {
      return '';
    }
    return styles[`bg-${kebabCase(backgroundColor)}`];
  }, [backgroundColor]);

  const txtColor = useMemo(() => {
    if (!textColor) {
      return '';
    }
    return styles[`text-${kebabCase(textColor)}`];
  }, [textColor]);

  if (!isShow) {
    return false as unknown as JSX.Element;
  }

  return (
    <Container
      data-testid={'full-width-text'}
      ref={containerRef as any}
      className={classNames(
        'animate',
        'content-block',
        'full-width-text',
        styles.container,
        { 'in-view': isVisible },
        {
          [styles.bg]: !!bgColor,
        },
        bgColor,
        txtColor,
      )}
    >
      <Row key={uid} className={styles.row}>
        <Col>
          {headline && (
            <h3
              className={`text-${headlinePlacement.toLowerCase()} ${styles['nkn-fw-heading']} g-0`}
              data-testid="headline"
              dangerouslySetInnerHTML={{ __html: headline || '' }}
            ></h3>
          )}

          {subHead && (
            <h4 className={`text-${subHeadPlacement.toLowerCase()} ${styles['nkn-fw-subhead']}`} data-testid="subHead">
              {subHead}
            </h4>
          )}

          {description && (
            <div
              className={`text-${descriptionPlacement.toLowerCase()} ${styles['nkn-fw-description']}`}
              data-testid="description"
              dangerouslySetInnerHTML={{ __html: description || '' }}
            ></div>
          )}

          {links && Boolean(links.length) && (
            <ul
              className={`text-${descriptionPlacement.toLowerCase()} ${styles['nkn-fw-tabs']}`}
              data-testid={`nkn-fw-tabs`}
            >
              {links.map((link) => (
                <FullWidthTextLink data={link} key={link.uid} />
              ))}
            </ul>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default FullWidthText;
