import styles from './DisclaimerTitleBlock.module.scss';
import IDisclaimerTitleBlock from './types/IDisclaimerTitleBlock';

const DisclaimerTitleBlock = ({ titleBlock }: IDisclaimerTitleBlock) => {
  if (!Boolean(titleBlock.title)) {
    return null;
  }

  return (
    <p className={styles.title} data-testid="disclaimer-title">
      {titleBlock.title}
    </p>
  );
};
export default DisclaimerTitleBlock;
