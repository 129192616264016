import { IAboveProductList, IAboveProductListBlock, IAboveProductListReference } from 'interfaces/IAboveProductList';
import { TAboveProductList } from 'types/TAboveProductList';

export const AboveProductListBlockDTO = ({ above_product_list_block }: IAboveProductListBlock) => {
  return AboveProductListReferenceDTO(above_product_list_block);
};

export const AboveProductListReferenceDTO = ({ above_product_list_reference }: IAboveProductListReference) => {
  return AboveProductListDTO(above_product_list_reference[0]);
};

export const AboveProductListDTO = (data: IAboveProductList): TAboveProductList => {
  return {
    contentTypeUid: 'above_product_list',
    content: data?.description_text,
    uid: data?.uid,
  };
};
