import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { Form } from 'react-bootstrap';
import {
  fullFilters,
  searchMenus,
} from 'components/commercetools-ui/organisms/product/product-list/components/search/components/SearchBrowBar/constant';
import SearchIcon from 'components/icons/search';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import { Locales } from 'helpers/utils/swiftType';
import useTypeAhead from 'frontastic/hooks/useTypeAhead';
import styles from './Search.module.scss';
import SearchItem from './SearchItem';

interface Props {
  toggleSearch: () => void;
}

const Search = ({ toggleSearch }: Props) => {
  const router = useRouter();
  const resultsListRef = useRef<HTMLOListElement | null>(null);
  const searchBoxRef = useRef<HTMLInputElement | null>(null);
  const { searchTerm, searchResults, handleSearchTermChange, isLoading } = useTypeAhead(
    router?.locale?.replace('-', '_') as Locales,
  );
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const [isDesktop] = useMediaQuery(desktop);

  const shouldGetTypeAhead = isDesktop;
  const isResultsListVisible = shouldGetTypeAhead && ((isLoading && searchTerm) || searchResults.length > 0);

  useEffect(() => {
    const currentSearchTerm = searchBoxRef.current?.value;
    if (shouldGetTypeAhead && currentSearchTerm && searchTerm !== currentSearchTerm) {
      handleSearchTermChange(currentSearchTerm);
    }
  }, [shouldGetTypeAhead]);

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (!resultsListRef?.current || !searchBoxRef?.current || !isResultsListVisible || !searchResults.length) return;

    const resultsList = [...resultsListRef.current.querySelectorAll('a')];
    const firstResult = resultsList[0];
    const lastResult = resultsList[resultsList.length - 1];
    const curResult = e.target as HTMLAnchorElement | HTMLInputElement;
    const isSearchElement = curResult === searchBoxRef.current;
    let nextResult, prevResult;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (isSearchElement) nextResult = firstResult;
        else if (curResult === lastResult) nextResult = searchBoxRef.current;
        else nextResult = resultsList[resultsList.indexOf(curResult as HTMLAnchorElement) + 1];

        nextResult.focus({ preventScroll: true });
        break;

      case 'ArrowUp':
        e.preventDefault();
        if (isSearchElement) prevResult = lastResult;
        else if (curResult === firstResult) prevResult = searchBoxRef.current;
        else prevResult = resultsList[resultsList.indexOf(curResult as HTMLAnchorElement) - 1];

        prevResult.focus({ preventScroll: true });
        break;

      case 'Enter':
        if (!isSearchElement) {
          router.push((curResult as HTMLAnchorElement).href);
          clearSearch();
        }
        break;
    }
  };

  const clearSearch = () => {
    handleSearchTermChange('');
    if (searchBoxRef.current) {
      searchBoxRef.current.value = '';
      searchBoxRef.current.blur();
    }
  };

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchBoxRef?.current?.value) {
      router.push({
        pathname: `/s/${searchBoxRef?.current?.value || ''}`, //@ts-ignore
        query: { facet: fullFilters[searchMenus.RESULT_OVERVIEW] },
      });
      toggleSearch();
      clearSearch();
    }
  };

  return (
    <div className={styles['search-container']}>
      <Form
        className={`d-flex ${styles['search-field']}`}
        data-testid="header-search-form-collapse"
        onSubmit={handleOnSubmit}
      >
        <Form.Control
          type="search"
          aria-label="Search"
          placeholder={formatCommonMessage({ id: 'search.placeholder', defaultMessage: 'Search' })}
          onChange={(e) => shouldGetTypeAhead && handleSearchTermChange(e.target.value)}
          ref={searchBoxRef}
          onKeyDown={handleOnKeyDown}
        />
        <button className={styles['search-icon']} type="submit" title="submit">
          <SearchIcon />
        </button>
      </Form>
      {isResultsListVisible && (
        <div className={styles['search-results-container']}>
          <h3 className={styles['search-results-title']}>
            {formatCommonMessage({ id: 'search.resultsTitle', defaultMessage: 'Matching products or accessories' })}
          </h3>

          {isLoading && (
            <div className={styles['search-results-loader-container']}>
              <img
                src={`/images/icon_loading.gif`}
                alt={formatCommonMessage({ id: 'search.loading', defaultMessage: 'loading' })}
              />
            </div>
          )}

          <ol role="listbox" className={styles['search-results-list']} ref={resultsListRef}>
            {searchResults.map((result) => (
              <SearchItem
                key={result.id}
                image={result.image}
                title={result.title}
                description={result.description}
                url={result.url}
                onKeyDown={handleOnKeyDown}
              />
            ))}
          </ol>
        </div>
      )}
    </div>
  );
};

export default Search;
