import { Variant } from '@commercetools/frontend-domain-types/product';
import styles from './Thumbnail.module.scss';
import { addThumbToUrl } from '../../../../../helpers/utils/addThumbToUrl';
import { BackgroundColor } from '../types/enums';

export interface Props {
  backgroundColor?: string;
  variants?: Variant[];
  variantSelectedIdx: number;
  onVariantSelected: (idx: number) => void;
  carrouselIdx: number;
}

const Thumbnail = ({ backgroundColor, variants, variantSelectedIdx, onVariantSelected, carrouselIdx }: Props) => {
  let dataColor = '';
  if (backgroundColor === BackgroundColor.WHITE) {
    dataColor = 'white';
  } else if (backgroundColor === BackgroundColor.YELLOW) {
    dataColor = 'yellow';
  } else if (backgroundColor === BackgroundColor.BLACK) {
    dataColor = 'black';
  }

  return (
    <div data-testid="container" className={styles['container']} style={{ backgroundColor: backgroundColor || '' }}>
      {variants?.map((current, idx) => {
        const image = current.images?.[carrouselIdx];
        return (
          <div key={idx}>
            {image && (
              <div
                data-testid={`item-container-${idx}`}
                className={`${styles['item-container']} ${idx === variantSelectedIdx ? 'active' : ''}`}
                onClick={() => onVariantSelected(idx)}
                data-color={dataColor}
              >
                <img
                  data-testid={`image-${idx}`}
                  src={addThumbToUrl(current.images?.[carrouselIdx] || '')}
                  title={`${current.attributes?.title} ${current.attributes?.color?.[0]?.label} Color`}
                  loading="lazy"
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Thumbnail;
