import React from 'react';
import classNames from 'classnames/bind';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import styles from './FAQPanel.module.scss';
import { structuredSchemaOrgData } from './structuredSchemaOrgData';
import { IFAQProps } from './types/IFAQPanelProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';

const cx = classNames.bind(styles);

const FAQPanel = ({ faqs }: IFAQProps) => {
  const [containerRef, isVisible] = useAnimation();

  return (
    <>
      {Boolean(faqs?.panelGroup?.length) && (
        <Container
          data-testid={`nkn-faq-container`}
          ref={containerRef as any}
          className={cx('animate', 'content-block', { 'in-view': isVisible }, styles['nkn-faq-container'])}
        >
          <Container data-testid="faq-header-section" className={`${styles['faq-header-section']} text-md-center`}>
            <Row className={`${styles['faq-row']}`}>
              <Col className={`${styles['faq-col']} ${styles['faq-copy-wrap']} spacer-xl`}>
                <h2 className={`${styles['faq-headline']} h2`}>
                  <abbr title="Frequently Asked Questions">{faqs.headline}</abbr>
                </h2>
              </Col>
            </Row>
          </Container>
          <Container data-testid="faq-panel-section" className={`${styles['faq-panel-section']} max-970`}>
            <Row>
              <Col>
                {faqs.panelGroup.map((panel, panelIndex) => {
                  return (
                    <Accordion key={`${panel.uid}-panel-${panelIndex}`}>
                      <Accordion.Item eventKey={`${panelIndex}`}>
                        <Accordion.Header>{panel.panelTitle}</Accordion.Header>
                        <Accordion.Body>
                          {panel.contentGroup.length > 0 && (
                            <ul data-testid="faq-content-section">
                              {panel.contentGroup.map((content, contentIdx) => {
                                return (
                                  <li
                                    key={`${content.uid}-content-${contentIdx}`}
                                    data-testid={`faq-content-list-${contentIdx}`}
                                  >
                                    {content.contentTitle.trim().length > 0 && (
                                      <div
                                        className={`${styles['content-title']} no-margin`}
                                        data-testid={`faq-content-title-${contentIdx}`}
                                      >
                                        {content.contentTitle}
                                      </div>
                                    )}
                                    {content.contentDescription.trim().length > 0 && (
                                      <div
                                        className={cx({
                                          'content-desc': true,
                                          'content-desc-font-lg': content.contentTitle.trim().length <= 0,
                                        })}
                                        dangerouslySetInnerHTML={{ __html: content.contentDescription }}
                                        data-testid={`faq-content-desc-${contentIdx}`}
                                      />
                                    )}
                                    {content.linkCopyText.trim().length > 0 && content.linkUrl.trim().length > 0 && (
                                      <a
                                        href={content.linkUrl}
                                        target={content.openInNewWindow ? '_blank' : '_self'}
                                        rel="noreferrer"
                                        className={`${styles['content-link']}`}
                                        data-testid={`faq-content-link-${contentIdx}`}
                                      >
                                        {content.linkCopyText}
                                      </a>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })}
              </Col>
            </Row>
          </Container>
        </Container>
      )}
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: structuredSchemaOrgData(faqs) }} />
    </>
  );
};

export default FAQPanel;
