import { FileDTO } from './CommonFieldsDTO';
import {
  ILNEArticleFieldsGroup,
  ILNEArticleFieldsGroupImages,
  ILNEArticleReference,
  ILNEArticlesGroup,
  ILNEFeaturedArticlesSection,
  ILNEFeaturedArticlesReference,
  ILNEArticleFieldsGroupDateSelectors,
} from '../interfaces/ILNEFeaturedArticlesSection';
import {
  TLNEArticleFieldsGroup,
  TLNEArticleFieldsGroupImages,
  TLNEArticleReference,
  TLNEArticlesGroup,
  TLNEFeaturedArticlesSection,
  TLNEFeaturedArticlesReference,
  TLNEArticleFieldsGroupDateSelectors,
} from '../types/TLNEFeaturedArticlesSection';

export const LNEArticleFieldsGroupImagesDTO = (data: ILNEArticleFieldsGroupImages): TLNEArticleFieldsGroupImages => ({
  customRectangleRepresentativeImage: FileDTO(data.custom_rectangle_representative_image),
  rectangleRepresentativeImage: FileDTO(data.rectangle_representative_image),
  representativeImage: FileDTO(data.representative_image),
  squareRepresentativeImage: FileDTO(data.square_representative_image),
});

export const LNEArticleFieldsGroupDateSelectorsDTO = (
  data: ILNEArticleFieldsGroupDateSelectors,
): TLNEArticleFieldsGroupDateSelectors => ({
  dateWritten: data?.date_written || null,
  expirationDate: data?.expiration_date || null,
  newUntilDate: data?.new_until_date || null,
});

export const LNEArticleFieldsGroupDTO = (data: ILNEArticleFieldsGroup): TLNEArticleFieldsGroup => ({
  articleCategory: data?.article_category || '',
  articleClassification: data?.article_classification || '',
  articleTitle: data?.article_title || '',
  articleSubtitle: data?.article_sub_title || '',
  articleSummary: data?.article_summary || '',
  images: data?.images ? LNEArticleFieldsGroupImagesDTO(data.images) : undefined,
  isArchived: data?.is_archived || false,
  dateSelectors: data?.date_selectors ? LNEArticleFieldsGroupDateSelectorsDTO(data.date_selectors) : undefined,
});

export const LNEArticleReferenceDTO = (data: ILNEArticleReference): TLNEArticleReference => ({
  articleFieldsGroup: LNEArticleFieldsGroupDTO(data.article_fields_group),
  code: data.code,
  slug: data.slug,
});

export const LNEArticlesGroupDTO = (data: ILNEArticlesGroup): TLNEArticlesGroup => ({
  articleReference: data?.article_reference ? data.article_reference?.map(LNEArticleReferenceDTO) : [],
});

export const LNEFeaturedArticlesReferenceDTO = (
  data: ILNEFeaturedArticlesReference,
): TLNEFeaturedArticlesReference => ({
  articlesGroup: data?.articles_group ? data.articles_group.map(LNEArticlesGroupDTO) : [],
});

export const LNEFeaturedArticlesSectionDTO = (data: ILNEFeaturedArticlesSection): TLNEFeaturedArticlesSection => ({
  headline: data?.headline,
  featuredArticlesReference: data?.featured_articles_reference
    ? data.featured_articles_reference.map(LNEFeaturedArticlesReferenceDTO)
    : [],
});
