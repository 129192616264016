import React from 'react';
import Link from 'next/link';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './LNEFeaturedCollectionsBlock.module.scss';
import { ILNEFeaturedCollectionsBlockProps } from './types/ILNEFeaturedCollectionsBlockProps';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { getLNECollectionUrl } from '../utils';

const LNEFeaturedCollectionsBlock = ({ data }: ILNEFeaturedCollectionsBlockProps) => {
  const { formatMessage } = useFormat({ name: 'learn-and-explore' });

  return (
    <div data-testid="lne-featured-collections-block" className={styles['lne-featured-collections-block']}>
      <Container>
        <Row>
          <Col>
            <h2>{formatMessage({ id: 'article-collections', defaultMessage: 'Article Collections' })}</h2>
          </Col>
        </Row>
        <Row>
          {data.featuredCollectionsBlock?.featuredCollectionReference?.map((item, index) => {
            return (
              <Col xs={12} md={6} lg={4} className={styles.collection} key={index} data-coll-id={item?.code}>
                <Link href={getLNECollectionUrl(item?.slug)}>
                  <a>
                    <div className={styles.image}>
                      <img src={item?.collectionImage?.url} loading="lazy" alt={item?.collectionImage?.title} />
                    </div>
                    <div className={styles.info}>
                      {Boolean(item?.articlesBlock.length) && (
                        <p>
                          {item?.articlesBlock.length}{' '}
                          {formatMessage({
                            id: 'articles',
                            defaultMessage: 'Articles',
                          })}
                        </p>
                      )}
                      <h3>{item?.collectionName}</h3>
                    </div>
                  </a>
                </Link>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default LNEFeaturedCollectionsBlock;
