import Link from 'next/link';
import styles from './DisclaimerIconBlock.module.scss';
import IDisclaimerIconBlock from './types/IDisclaimerIconBlock';

const DisclaimerIconBlock = ({ iconBlock }: IDisclaimerIconBlock) => {
  if (iconBlock.icons.length === 0) {
    return null;
  }

  const { icons, uid } = iconBlock;

  return (
    <div className={styles.iconBlock} key={iconBlock.uid} data-testid="disclaimer-icons">
      {icons.map(({ filePath, maxWidth, altText, redirectLinkUrl }, index) => {
        const key = `icon-${uid}-${index}`;
        const iconMaxWidth = { ...(maxWidth && { maxWidth: `${maxWidth}px` }) };

        if (filePath === null) {
          return null;
        }

        if (redirectLinkUrl) {
          return (
            <Link href={redirectLinkUrl} key={key}>
              <a target="_blank" aria-label={altText} data-testid="icon-with-link">
                <img src={filePath.url} alt={altText} style={{ ...iconMaxWidth }} />
              </a>
            </Link>
          );
        }

        return (
          <img key={key} src={filePath.url} alt={altText} style={{ ...iconMaxWidth }} data-testid="icon-without-link" />
        );
      })}
    </div>
  );
};
export default DisclaimerIconBlock;
