import { FileMandatoryDTO } from './CommonFieldsDTO';
import {
  INavigationCarousel,
  INavigationCarouselBlock,
  INavigationCarouselImageLinkBlock,
  INavigationCarouselReference,
} from '../interfaces/INavigationCarousel';
import { TNavigationCarousel, TNavigationCarouselImageLink } from '../types/TNavigationCarousel';

export const NavigationCarouselBlockDTO = ({
  navigation_carousel_block,
}: INavigationCarouselBlock): TNavigationCarousel => NavigationCarouselReferenceDTO(navigation_carousel_block);

export const NavigationCarouselReferenceDTO = ({
  navigation_carousel_reference,
}: INavigationCarouselReference): TNavigationCarousel => NavigationCarouselDTO(navigation_carousel_reference[0]);

export const NavigationCarouselDTO = (navigationCarousel: INavigationCarousel): TNavigationCarousel => {
  const expirationDate = navigationCarousel.expiration_row ? new Date(navigationCarousel.expiration_row) : null;

  return {
    contentTypeUid: 'navigation_carousel',
    expirationRow: expirationDate,
    isExpired: expirationDate !== null && expirationDate.valueOf() - new Date().valueOf() < 0,
    visibleOnDesktopOnly: navigationCarousel.visible_on_desktop_only,
    imageLinkModularBlock: navigationCarousel.image_link_modular_block.map(NavigationCarouselImageLinkDTO),
    uid: navigationCarousel.uid,
  };
};

const NavigationCarouselImageLinkDTO = ({
  image_link_block,
}: INavigationCarouselImageLinkBlock): TNavigationCarouselImageLink => ({
  imageLinkPath: FileMandatoryDTO(image_link_block.image_link_path),
  imageLinkText: image_link_block.image_link_text,
  imageLinkUrl: image_link_block.image_link_url,
  openInNewWindow: image_link_block.open_in_new_window,
  uid: image_link_block._metadata.uid,
});
