export class HEADLINE_COLORS {
  static readonly WHITE = 'white';
  static readonly BLACK = 'black';
}

export class TEXT_ALIGNMENTS {
  static readonly Left = 'left';
  static readonly Center = 'center';
  static readonly Right = 'right';
}

export class VERTICAL_ALIGNMENTS {
  static readonly Top = 'top';
  static readonly Center = 'center';
  static readonly Bottom = 'bottom';
}

export class HORIZONTAL_ALIGNMENTS {
  static readonly Left = 'left';
  static readonly Center = 'center';
  static readonly Right = 'right';
}

export class NAME_IMAGE_MAX_WIDTHS {
  static readonly Mobile = 155;
  static readonly Desktop = 280;
}
