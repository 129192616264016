import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import 'photoswipe/dist/photoswipe.css';
import { DataSourceArray } from 'photoswipe';
import { Row } from 'react-bootstrap';
import { Gallery, GalleryProps } from 'react-photoswipe-gallery';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import styles from './HorizontalSlider.module.scss';
import { HorizontalSliderProps } from './types/IHorizontalSlider';

const HorizontalSlider = ({ children, className }: HorizontalSliderProps) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  const [screenWidth] = useMediaQuery();

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [leftArrowVisibility, setLeft] = useState(false);
  const [rightArrowVisibility, setRight] = useState(false);

  useEffect(() => {
    checkArrowsVisibility();
    checkIsScrollable();
  }, [screenWidth]);

  const mouseCoords = useRef({
    startX: 0,
    scrollLeft: 0,
  });

  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    if (e.type === 'mousemove') {
      e.preventDefault();
    }
    if (!sliderRef.current) {
      return;
    }

    const slider = sliderRef.current as HTMLDivElement;
    const startX = ('pageX' in e ? e.pageX : e.touches[0].pageX) - slider.offsetLeft;
    const scrollLeft = slider.scrollLeft;
    mouseCoords.current = { startX, scrollLeft };
    setIsMouseDown(true);
  };
  const handleDragEnd = (e: React.MouseEvent | React.TouchEvent) => {
    if (e.type === 'mousemove') {
      e.preventDefault();
    }
    if (!sliderRef.current) {
      return;
    }

    const slider = sliderRef.current;
    setIsMouseDown(false);
    setTimeout(() => {
      checkArrowsVisibility();
      slider.classList.remove('pe-none');
    }, 100);
  };
  const handleDrag = (e: React.MouseEvent | React.TouchEvent) => {
    if (e.type === 'mousemove') {
      e.preventDefault();
    }
    if (!isMouseDown || !sliderRef.current) {
      return;
    }

    const slider = sliderRef.current as HTMLDivElement;
    const x = ('pageX' in e ? e.pageX : e.touches[0].pageX) - slider.offsetLeft;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;

    if (isMouseDown) {
      const xDiff = Math.abs(('pageX' in e ? e.pageX : e.touches[0].pageX) - mouseCoords.current.startX);
      if (xDiff > 5) {
        slider.classList.add('pe-none');
      }
    }
  };

  const onClickArrow = (scrollOffset: number) => {
    if (!sliderRef.current) {
      return;
    }

    const slider = sliderRef.current;
    slider.classList.add(styles['scroll-smooth']);
    slider.scrollLeft += scrollOffset;
    slider.classList.remove(styles['scroll-smooth']);
    setTimeout(() => {
      checkArrowsVisibility();
    }, 100);
  };

  const checkArrowsVisibility = () => {
    if (!sliderRef.current) {
      return;
    }

    const slider = sliderRef.current;
    const maxScroll = slider.scrollWidth - slider.clientWidth;
    setLeft(!(slider.scrollLeft < 100));
    setRight(!(slider.scrollLeft > maxScroll - 100));
  };

  const checkIsScrollable = () => {
    if (!sliderRef.current) {
      return;
    }

    const slider = sliderRef.current;
    setIsScrollable(slider.scrollWidth > slider.clientWidth);
  };

  const uiElements: GalleryProps['uiElements'] = [
    {
      name: 'bulletsIndicator',
      order: 9,
      isButton: false,
      appendTo: 'wrapper',
      onInit: (el, pswpInstance) => {
        let prevIndex = -1;
        const thumbnails: HTMLElement[] = [];

        el.style.position = 'absolute';
        el.style.bottom = '20px';
        el.style.left = '10px';
        el.style.right = '0';
        el.style.display = 'grid';
        el.style.gridGap = '10px';
        el.style.gridTemplateColumns = 'repeat(auto-fit, 40px)';
        el.style.gridTemplateRows = 'repeat(auto-fit, 40px)';
        el.style.justifyContent = 'center';

        const dataSource = pswpInstance.options.dataSource as DataSourceArray;

        for (let i = 0; i < dataSource.length; i++) {
          const slideData = dataSource[i];

          const thumbnail = document.createElement('div');
          thumbnail.style.transition = 'transform 0.15s ease-in';
          thumbnail.style.opacity = '0.6';
          thumbnail.style.cursor = 'pointer';
          thumbnail.onclick = (e: MouseEvent) => {
            const target = e.target as HTMLImageElement | HTMLDivElement;
            const thumbnailEl =
              target.tagName === 'IMG' ? target.parentElement : (e.target as HTMLImageElement | HTMLDivElement);
            if (thumbnailEl) {
              pswpInstance.goTo(thumbnails.indexOf(thumbnailEl));
            }
          };

          const thumbnailImage = document.createElement('img');
          thumbnailImage.setAttribute('src', slideData.msrc || '');
          thumbnailImage.style.width = '100%';
          thumbnailImage.style.height = '100%';
          thumbnailImage.style.objectFit = 'cover';

          thumbnail.appendChild(thumbnailImage);

          el.appendChild(thumbnail);

          thumbnails.push(thumbnail);
        }

        pswpInstance.on('change', () => {
          if (prevIndex >= 0) {
            const prevThumbnail = thumbnails[prevIndex];
            prevThumbnail.style.opacity = '0.6';
            prevThumbnail.style.cursor = 'pointer';
            prevThumbnail.style.transform = 'scale(1)';
          }

          const currentThumbnail = thumbnails[pswpInstance.currIndex];
          currentThumbnail.style.opacity = '1';
          currentThumbnail.style.cursor = 'unset';
          currentThumbnail.style.transform = 'scale(1.2)';

          prevIndex = pswpInstance.currIndex;
        });
      },
    },
  ];

  const childrenCount = React.Children.count(children);

  return (
    <div data-testid="horizontal-slider" className={`${className} position-relative`}>
      <div
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        onMouseMove={handleDrag}
        onTouchStart={handleDragStart}
        onTouchEnd={handleDragEnd}
        onTouchMove={handleDrag}
        className="d-md-block overflow-hidden p-0"
      >
        <Row
          ref={sliderRef}
          data-testid="horizontal-slider-scrollable"
          onScroll={checkArrowsVisibility}
          className={`${styles['scrollable-row']} w-100 flex-row flex-nowrap`}
        >
          <Gallery options={{ bgOpacity: 1 }} {...(childrenCount > 1 && { uiElements: uiElements })}>
            <div
              className={classNames('flex gap-4', {
                'justify-content-center': !isScrollable,
                'justify-content-start': isScrollable,
              })}
            >
              {children}
            </div>
          </Gallery>
        </Row>
      </div>
      <div
        data-testid="left-arrow"
        className={classNames(
          styles['arrow'],
          styles['left'],
          {
            [styles['fade-in']]: leftArrowVisibility,
            [styles['fade-out']]: !leftArrowVisibility,
          },
          'carousel-control-prev-icon',
        )}
        onClick={() => onClickArrow(-392)}
      >
        <i className={styles['icon']} />
      </div>
      <div
        data-testid="right-arrow"
        className={classNames(
          styles['arrow'],
          styles['right'],
          {
            [styles['fade-in']]: rightArrowVisibility,
            [styles['fade-out']]: !rightArrowVisibility,
          },
          'carousel-control-next-icon',
        )}
        onClick={() => onClickArrow(392)}
      >
        <i className={styles['icon']} />
      </div>
    </div>
  );
};

export default HorizontalSlider;
