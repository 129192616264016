import classNames from 'classnames';
import { Item } from 'react-photoswipe-gallery';
import VideoIframe from 'components/contentstack/atoms/VideoIframe/VideoIframe';
import { ImageHelpers } from 'helpers/imageHelpers';
import { TQuickLinkVideo } from 'types/TQuickLinks';
import styles from '../QuickLinks.module.scss';

export const VideoLink = ({ link }: { link: TQuickLinkVideo }) => {
  const buildContent = () => {
    return (
      <div className={styles['video-container']}>
        <VideoIframe videoRefId={link.videoRefId} videoLocation={link.videoLocation} seoData={link.seo} />
      </div>
    );
  };

  return (
    <Item original={link.imageUrl} thumbnail={link.imageUrl} content={buildContent()}>
      {({ ref, open }) => (
        <a
          ref={ref as React.MutableRefObject<HTMLAnchorElement>}
          className={classNames('flex-column d-md-block position-relative', styles['quick-link'], {
            'd-none ': link.hideLinkOnMobile,
          })}
          target={(link.openInNewWindow && '_blank') || ''}
          onClick={(event) => {
            event.preventDefault();
            open(event);
          }}
        >
          <div className={styles['img-wrap']}>
            <div className={styles['play-btn']}>
              <span className="ss-play"></span>
            </div>
            <img
              className="img-responsive"
              src={ImageHelpers.modifiedContentStackImageUrl(link.imageUrl, '360')}
              alt={link.imageAlt || link.linkTitle}
              draggable={false}
            />
          </div>
          <div className={`${styles['text-info']} mt-3`}>
            <p className={`${styles.title} text-decoration-none text-center`}>{link.linkTitle}</p>
            <p
              className={`${styles.description} text-decoration-none text-center`}
              dangerouslySetInnerHTML={{ __html: link.linkDescription }}
            />
          </div>
        </a>
      )}
    </Item>
  );
};
