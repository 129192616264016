import { LNEFeaturedArticlesSectionDTO } from './LNEFeaturedArticlesSectionDTO';
import { ILNEFeaturedArticlesBlock } from '../interfaces/ILNEFeaturedArticlesBlock';
import { TLNEFeaturedArticlesBlock } from '../types/TLNEFeaturedArticlesBlock';

export const LNEFeaturedArticlesBlockDTO = (data: ILNEFeaturedArticlesBlock): TLNEFeaturedArticlesBlock => ({
  contentTypeUid: 'featured_articles_block',
  featuredArticlesBlock: data?.featured_articles_block
    ? LNEFeaturedArticlesSectionDTO(data.featured_articles_block)
    : undefined,
});
