import { RESOURCE_TYPES } from 'helpers/constants/resourceTypes';
import {
  IQuickLinkImage,
  IQuickLinkPdf,
  IQuickLinkVideo,
  IQuickLinkWeb,
  IQuickLinksReference,
  IQuickLinksContent,
  IQuickLinksBlock,
} from 'interfaces/IQuickLinks';
import { TQuickLinkImage, TQuickLinkPdf, TQuickLinkVideo, TQuickLinkWeb, TQuickLinks } from 'types/TQuickLinks';
import { SeoVideoDataDTO } from './SeoDataDTO';

export const QuickLinksBlockDTO = ({ quick_links_block }: IQuickLinksBlock): TQuickLinks =>
  QuickLinksReferenceDTO(quick_links_block);

export const QuickLinksReferenceDTO = ({ quick_links_reference }: IQuickLinksReference): TQuickLinks =>
  QuickLinksDTO(quick_links_reference[0]);

export const QuickLinksDTO = (quickLinks: IQuickLinksContent): TQuickLinks => {
  const linksData = quickLinks.quick_links.map(
    (link): TQuickLinkVideo | TQuickLinkImage | TQuickLinkPdf | TQuickLinkWeb => {
      if ('image_block' in link) {
        const linkBlock = link as IQuickLinkImage;
        return {
          uid: linkBlock.image_block._metadata.uid,
          resourceType: RESOURCE_TYPES.IMAGE,
          linkTitle: linkBlock.image_block.link_title,
          linkDescription: linkBlock.image_block.link_description,
          imageUrl: linkBlock.image_block.content_associated_image.url,
          imageAlt: linkBlock.image_block.content_image_alt,
          openInNewWindow: linkBlock.image_block.open_in_new_window,
          hideLinkOnMobile: linkBlock.image_block.hide_link_on_mobile,
          resourceUrl: linkBlock.image_block.content_path.url,
        };
      }
      if ('video_block' in link) {
        const linkBlock = link as IQuickLinkVideo;
        return {
          resourceType: RESOURCE_TYPES.VIDEO,
          uid: linkBlock.video_block._metadata.uid,
          linkTitle: linkBlock.video_block.link_title,
          linkDescription: linkBlock.video_block.link_description,
          imageUrl: linkBlock.video_block.content_associated_image.url,
          imageAlt: linkBlock.video_block.content_image_alt,
          openInNewWindow: linkBlock.video_block.open_in_new_window,
          hideLinkOnMobile: linkBlock.video_block.hide_link_on_mobile,
          videoLocation: linkBlock.video_block.video_location,
          videoRefId: linkBlock.video_block.video_ref_id,
          seo: linkBlock.video_block.seo ? SeoVideoDataDTO(linkBlock.video_block.seo) : null,
        };
      }

      if ('pdf_block' in link) {
        const linkBlock = link as IQuickLinkPdf;
        return {
          uid: linkBlock.pdf_block._metadata.uid,
          resourceType: RESOURCE_TYPES.PDF,
          linkTitle: linkBlock.pdf_block.link_title,
          linkDescription: linkBlock.pdf_block.link_description,
          imageUrl: linkBlock.pdf_block.content_associated_image.url,
          imageAlt: linkBlock.pdf_block.content_image_alt,
          openInNewWindow: linkBlock.pdf_block.open_in_new_window,
          hideLinkOnMobile: linkBlock.pdf_block.hide_link_on_mobile,
          resourceUrl: linkBlock.pdf_block.pdf_file.url,
        };
      }
      if ('web_page_block' in link) {
        const linkBlock = link as IQuickLinkWeb;
        return {
          uid: linkBlock.web_page_block._metadata.uid,
          resourceType: RESOURCE_TYPES.WEB,
          linkTitle: linkBlock.web_page_block.link_title,
          linkDescription: linkBlock.web_page_block.link_description,
          imageUrl: linkBlock.web_page_block.content_associated_image.url,
          imageAlt: linkBlock.web_page_block.content_image_alt,
          openInNewWindow: linkBlock.web_page_block.open_in_new_window,
          hideLinkOnMobile: linkBlock.web_page_block.hide_link_on_mobile,
          webPageUrl: linkBlock.web_page_block.web_page_url,
        };
      }
      throw new Error('Invalid quick link type.');
    },
  );

  return {
    contentTypeUid: 'quick_links',
    uid: quickLinks.uid,
    displayOnMobile: quickLinks.display_on_mobile,
    headline: quickLinks.headline,
    linksData,
  };
};
