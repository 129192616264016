import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import LNEFeaturedArticle from './LNEFeaturedArticle';
import styles from './LNEFeaturedArticlesBlock.module.scss';
import { ILNEFeaturedArticlesBlockProps } from './types/ILNEFeaturedArticlesBlockProps';
import { TLNEArticlesGroup } from '../../../../types/TLNEFeaturedArticlesSection';

const LNEFeaturedArticlesBlock = ({ data }: ILNEFeaturedArticlesBlockProps) => {
  const articles: TLNEArticlesGroup[] = [];

  /* remove archived and expired articles */
  data?.featuredArticlesBlock?.featuredArticlesReference?.[0]?.articlesGroup?.map((article) => {
    const articleReference = article?.articleReference?.[0];
    const articleFieldsGroup = articleReference?.articleFieldsGroup;
    const expirationDate = articleFieldsGroup?.dateSelectors?.expirationDate || '';

    if (
      Boolean(articleReference?.slug) &&
      !articleFieldsGroup?.isArchived &&
      (expirationDate === '' || new Date(expirationDate).getTime() - new Date().getTime() > 0)
    ) {
      articles.push(article);
    }
  });

  /* first article is featured */
  const featuredArticle = articles?.[0];

  /* other articles go in sidebar */
  const sidebarArticles = articles?.slice(1);

  if (!articles) {
    return <></>;
  }

  return (
    <div data-testid="lne-featured-articles-block" className={styles['lne-featured-articles-block']}>
      <Container>
        <Row as="ul">
          {Boolean(sidebarArticles.length) ? (
            /* at least 2 articles to display */
            <>
              {/* featured article */}
              <Col as="li" xs={12} md={6} lg={8} className={`${styles['article-block']} ${styles['featured-article']}`}>
                <LNEFeaturedArticle data={featuredArticle} index={0} />
              </Col>

              {/* sidebar articles */}
              <Col as="li" xs={12} md={6} lg={4} className={`${styles['article-block']} ${styles.sidebar}`}>
                {sidebarArticles?.map((item, index) => {
                  return <LNEFeaturedArticle data={item} index={index + 1} key={index} />;
                })}
              </Col>
            </>
          ) : (
            /* only 1 article to display */
            <Col
              as="li"
              xs={12}
              lg={{ span: 8, offset: 2 }}
              offset
              className={`${styles['article-block']} ${styles['featured-article']}`}
            >
              <LNEFeaturedArticle data={featuredArticle} index={0} />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default LNEFeaturedArticlesBlock;
