import { FileDTO } from './CommonFieldsDTO';
import {
  ILNEFeaturedCollection,
  ILNEFeaturedCollectionReference,
  ILNEFeaturedCollectionsBlock,
} from '../interfaces/ILNEFeaturedCollectionsBlock';
import {
  TLNEFeaturedCollection,
  TLNEFeaturedCollectionReference,
  TLNEFeaturedCollectionsBlock,
} from '../types/TLNEFeaturedCollectionsBlock';

export const LNEFeaturedCollectionsDTO = (data: ILNEFeaturedCollection): TLNEFeaturedCollection => ({
  articlesBlock: data.articles_block,
  code: data.code,
  collectionImage: FileDTO(data.collection_image),
  collectionName: data.collection_name,
  slug: data.slug,
});

export const LNEFeaturedCollectionReferenceDTO = (
  data: ILNEFeaturedCollectionReference,
): TLNEFeaturedCollectionReference => ({
  featuredCollectionReference: data?.featured_collection_reference
    ? data.featured_collection_reference.map(LNEFeaturedCollectionsDTO)
    : [],
});

export const LNEFeaturedCollectionsBlockDTO = (data: ILNEFeaturedCollectionsBlock): TLNEFeaturedCollectionsBlock => ({
  contentTypeUid: 'featured_collections_block',
  featuredCollectionsBlock: data?.featured_collections_block
    ? LNEFeaturedCollectionReferenceDTO(data?.featured_collections_block)
    : undefined,
});
