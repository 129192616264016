import React, { RefObject, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { Container } from 'react-bootstrap';
import Carousel, { CarouselRef } from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/CarouselItem';
import CarouselPlayButton from './CarouselPlayButton';
import styles from './FullWidthImage.module.scss';
import ImagePanel from './ImagePanel';
import { IFullWidthImageProps } from './types/IFullWidthImageProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import { tablet, desktop } from '../../../../helpers/utils/screensizes';

const cx = classNames.bind(styles);

const FullWidthImage = ({ data: { isContentExpired, imagePanelBlock }, interval = 8000 }: IFullWidthImageProps) => {
  const [carouselInterval, setCarouselInterval] = useState<number | null>(interval);
  const [containerRef, isVisible] = useAnimation();
  const [carouselButtons, setCarouselButtons] = useState<HTMLElement | undefined>(undefined);
  const carouselRef = useRef<CarouselRef>(null);
  const imagePanelsBlockNotExpired = imagePanelBlock?.filter((imagePanel) => !imagePanel.isExpired);
  const isExpired = isContentExpired || !Boolean(imagePanelsBlockNotExpired?.length);
  const isMulti = imagePanelsBlockNotExpired?.length > 1;
  const screenSize = useMediaQuery()[0];
  const isMobile = screenSize > tablet;
  const isDesktop = screenSize > desktop;

  useEffect(() => {
    if (carouselRef?.current !== null) {
      setCarouselButtons(carouselRef.current.element?.querySelector('.carousel-indicators') as HTMLElement);
    }
  }, [carouselRef?.current?.element]);

  if (isExpired || !Boolean(imagePanelsBlockNotExpired?.length)) {
    return false as unknown as JSX.Element;
  }

  return (
    <Container
      fluid
      data-testid="full-width-image"
      ref={containerRef as RefObject<HTMLDivElement>}
      className={cx('full-width-image', 'content-block', 'animate panel-container p-0', {
        'in-view': isVisible,
        'single-image': imagePanelsBlockNotExpired?.length === 1,
      })}
    >
      <Carousel
        ref={carouselRef}
        fade={true}
        indicators={true}
        controls={false}
        interval={carouselInterval}
        pause="hover"
        data-testid="navigation-carousel"
      >
        {imagePanelsBlockNotExpired.map((imagePanel) => (
          <CarouselItem data-testid="carousel-item" key={imagePanel.uid}>
            <ImagePanel imagePanel={imagePanel} isMobile={isMobile} isDesktop={isDesktop} />
          </CarouselItem>
        ))}
      </Carousel>

      {isMulti && carouselButtons && (
        <CarouselPlayButton
          domElement={carouselButtons}
          onPlay={() => setCarouselInterval(interval)}
          onPause={() => setCarouselInterval(null)}
          initialState={interval !== null}
        />
      )}
    </Container>
  );
};

export default FullWidthImage;
