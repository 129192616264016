import { Container } from 'react-bootstrap';
import styles from './MarketingHeroVideo.module.scss';
import { IMarketingHeroVideoProps } from './types/IMarketingHeroVideoProps';
import ResponsiveVideo from '../../../atoms/ResponsiveVideo';

const MarketingHeroVideo = ({ video }: IMarketingHeroVideoProps) => {
  if (video.videoPath === null) {
    return false as unknown as JSX.Element;
  }

  const { videoPath, loopVideo, verticalVideoPosition, horizontalVideoPosition } = video;

  return (
    <Container fluid className={`p-0 ${styles.container}`} data-testid="marketing-hero-video">
      <ResponsiveVideo
        src={videoPath?.url}
        contentType={videoPath?.contentType}
        verticalPosition={verticalVideoPosition}
        horizontalPosition={horizontalVideoPosition}
        autoplay={true}
        controls={false}
        loop={loopVideo}
        posterImage={video.posterImage}
      />
    </Container>
  );
};

export default MarketingHeroVideo;
