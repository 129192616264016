import styles from './ResponsiveImage.module.scss';
import { IResponsiveImageProps } from './types/IResponsiveImageProps';
import { HORIZONTAL_ALIGNMENTS, VERTICAL_ALIGNMENTS } from '../../../../helpers/constants/CommonFieldsConstants';
import { desktop, tablet } from '../../../../helpers/utils/screensizes';
import { EHorizontalAlignments, EVerticalAlignments } from '../../../../types/CommonFields';

const ResponsiveImage = ({
  mobileImage,
  tabletImage,
  desktopImage,
  altText,
  verticalPosition = EVerticalAlignments.Center,
  horizontalPosition = EHorizontalAlignments.Center,
  customClass = '',
}: IResponsiveImageProps) => {
  if (!desktopImage?.url && !tabletImage?.url && !mobileImage?.url) {
    return false as unknown as JSX.Element;
  }

  const verticalStyle = styles[VERTICAL_ALIGNMENTS[verticalPosition]];
  const horizontalStyle = styles[HORIZONTAL_ALIGNMENTS[horizontalPosition]];

  return (
    <picture>
      {mobileImage?.url && <source media={`(max-width: ${tablet - 1}px`} srcSet={mobileImage?.url} />}
      {tabletImage?.url && <source media={`(max-width: ${desktop - 1}px`} srcSet={tabletImage?.url} />}

      <img
        className={`${styles.image} ${horizontalStyle} ${verticalStyle} ${customClass}`}
        loading="lazy"
        src={desktopImage?.url || tabletImage?.url || mobileImage?.url}
        alt={altText}
        data-testid="responsive-image"
      />
    </picture>
  );
};

export default ResponsiveImage;
