import { FileDTO } from './CommonFieldsDTO';
import { SeoVideoDataDTO } from './SeoDataDTO';
import {
  IFullWidthVideo,
  IFullWidthVideoAssociatedProduct,
  IFullWidthVideoReference,
  IFullWidthVideoFile,
  IFullWidthVideoLinkGroup,
  IFullWidthVideoGroup,
  IFullWidthVideoPanel,
  IFullWidthVideoBlock,
} from '../interfaces/IFullWidthVideo';
import {
  TFullWidthVideo,
  TFullWidthVideoAssociatedProduct,
  TFullWidthVideoFile,
  TFullWidthVideoLinkGroup,
  TFullWidthVideoGroup,
  TFullWidthVideoPanel,
} from '../types/TFullWidthVideo';

export const FullWidthVideoBlockDTO = ({ full_width_video_block }: IFullWidthVideoBlock): TFullWidthVideo =>
  FullWidthVideoReferenceDTO(full_width_video_block);

export const FullWidthVideoReferenceDTO = ({ full_width_video_reference }: IFullWidthVideoReference): TFullWidthVideo =>
  FullWidthVideoDTO(full_width_video_reference[0]);

export const FullWidthVideoDTO = (fullWidthVideo: IFullWidthVideo): TFullWidthVideo => ({
  contentTypeUid: 'full_width_video',
  automaticRotation: fullWidthVideo.automatic_rotation,
  title: fullWidthVideo.title,
  expirationRow: fullWidthVideo.expiration_row,
  panelsBlock: fullWidthVideo.panels_block.map(VideoPanelDTO),
  uid: fullWidthVideo.uid,
});

export const VideoPanelDTO = (panelBlock: IFullWidthVideoPanel): TFullWidthVideoPanel => {
  const videoPanel = panelBlock.video_panel;

  return {
    heroStyling: videoPanel.hero_styling,
    nameImage: videoPanel.name_image || '',
    nameImageAltText: videoPanel.name_image_alt_text,
    headline: videoPanel.headline,
    description: videoPanel.description,
    textPlacement: videoPanel.text_placement,
    panelExpiration: videoPanel.panel_expiration,
    videoGroup: VideoGroupDTO(videoPanel.video_group),
    associatedProduct: AssociatedProductDTO(videoPanel.associated_product),
    linkGroup: videoPanel.link_group.map(LinkGroupDTO),
    seoData: videoPanel.seo ? SeoVideoDataDTO(videoPanel.seo) : null,
  };
};

const VideoGroupDTO = (videoGroup: IFullWidthVideoGroup): TFullWidthVideoGroup => ({
  autoplay: videoGroup.auto_play,
  controls: videoGroup.controls,
  loop: videoGroup.loop_video,
  videoLocation: videoGroup.video_location,
  videoFile: videoGroup.video_file !== null ? VideoFileDTO(videoGroup.video_file) : null,
  videoRefId: videoGroup.video_ref_id,
  recommendedYouTubeVideos: videoGroup.recommended_youtube_videos,
  videoCaption: videoGroup.video_caption,
  videoMetadata: videoGroup.video_metadata,
  posterImage: FileDTO(videoGroup.poster_image),
  horizontalPosition: videoGroup.horizontal_position,
  verticalPosition: videoGroup.vertical_position,
  couscousTex: videoGroup.couscous_tex,
  videoCopyInfoText: videoGroup.video_copy_info_text,
  videoCopyInfoUrl: videoGroup.video_copy_info_url,
  openNewWindow: videoGroup.open_new_window,
});

const VideoFileDTO = (videoFile: IFullWidthVideoFile): TFullWidthVideoFile => ({
  contentType: videoFile.content_type,
  fileSize: videoFile.file_size,
  filename: videoFile.filename,
  title: videoFile.title,
  url: videoFile.url,
});

const AssociatedProductDTO = (
  associatedProduct: IFullWidthVideoAssociatedProduct,
): TFullWidthVideoAssociatedProduct => ({
  productSlug: associatedProduct.product_slug,
  showPriceInformation: associatedProduct.show_price_information,
});

const LinkGroupDTO = (linkGroup: IFullWidthVideoLinkGroup): TFullWidthVideoLinkGroup => ({
  redirectLinkText: linkGroup.redirect_link_text,
  redirectLinkUrl: linkGroup.redirect_link_url,
  openNewWindow: linkGroup.open_new_window,
});
