import React from 'react';
import { IAnchorWrapperProps } from './types/IAnchorWrapperProps';

const AnchorWrapper = ({ title, href, openNewWindow, children, ...props }: IAnchorWrapperProps) => {
  return (
    <a
      href={href}
      title={title}
      target={openNewWindow ? '_blank' : '_self'}
      rel={openNewWindow ? 'noreferrer' : undefined}
      {...props}
    >
      {children}
    </a>
  );
};

export default AnchorWrapper;
