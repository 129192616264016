import { SWIFT_TYPE_ENGIN_KEYS, SWIFT_TYPE_URL } from 'helpers/constants/swiftType';

interface SwiftTypeUrlParams {
  query: string;
  fields: string[];
  filters?: string[];
  filtersSuggest?: Record<string, string>;
  functionalBoosts?: Record<string, string>;
  limit?: number;
  page?: number;
  endpoint?: 'suggest' | 'search';
}

export type Locales = 'en_US' | 'en_CA' | 'fr_CA' | 'es_MX' | 'es_419';

export const getSwiftTypeUrl = (url: SwiftTypeUrlParams, locale: Locales): string | null => {
  const { endpoint = 'suggest', query, fields, limit = 4, functionalBoosts, filtersSuggest, page } = url;
  const engineKey = SWIFT_TYPE_ENGIN_KEYS[locale];

  if (!engineKey || !SWIFT_TYPE_URL) return null;

  const fetchFields = fields.join('&fetch_fields[page][]=');

  const boosts = Object.entries(functionalBoosts || [])
    .map(([key, value]) => `&functional_boosts[page][${key}]=${value}`)
    .join('');

  const filters = Object.entries(filtersSuggest || [])
    .map(([key, value]) => `&filters[page][${key}]=${value}`)
    .join('');

  return `${SWIFT_TYPE_URL}/${endpoint}?engine_key=${engineKey}&q=${query}&per_page=${limit}&page=${page}&fetch_fields[page][]=${fetchFields}${boosts}${filters}`;
};

export const getSwiftTypeUrlForSearchList = (url: SwiftTypeUrlParams, locale: Locales): string | null => {
  const { endpoint = 'search', query, filters, fields, limit = 12, page = 1, functionalBoosts } = url;
  const engineKey = SWIFT_TYPE_ENGIN_KEYS[locale];

  if (!engineKey || !SWIFT_TYPE_URL) return null;

  const fetchFilters = filters?.join('&filters[page][type][]=');

  const fetchFields = fields.join('&fetch_fields[page][]=');
  const boosts = Object.entries(functionalBoosts || [])
    .map(([key, value]) => `&functional_boosts[page][${key}]=${value}`)
    .join('');

  return `${SWIFT_TYPE_URL}/${endpoint}?engine_key=${engineKey}&q=${query}&per_page=${limit}&page=${page}&spelling=always&facets[page][]=type&filters[page][type][]=${fetchFilters}&fetch_fields[page][]=${fetchFields}${boosts}`;
};

export const getSwiftTypeUrlForSearchFilter = (url: SwiftTypeUrlParams, locale: Locales): string | null => {
  const { endpoint = 'search', query, fields, limit = 12, page = 1, functionalBoosts } = url;
  const engineKey = SWIFT_TYPE_ENGIN_KEYS[locale];

  if (!engineKey || !SWIFT_TYPE_URL) return null;

  const fetchFields = fields.join('&fetch_fields[page][]=');
  const boosts = Object.entries(functionalBoosts || [])
    .map(([key, value]) => `&functional_boosts[page][${key}]=${value}`)
    .join('');

  return `${SWIFT_TYPE_URL}/${endpoint}?engine_key=${engineKey}&q=${query}&per_page=${limit}&page=${page}&spelling=always&facets[page][]=type&fetch_fields[page][]=${fetchFields}${boosts}`;
};
