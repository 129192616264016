import { FileDTO } from './CommonFieldsDTO';
import {
  ILNEArticleCategory,
  ILNEArticleCategoryBlock,
  ILNEArticleCategoryReference,
} from '../interfaces/ILNEArticleCategoryBlock';
import {
  TLNEArticleCategory,
  TLNEArticleCategoryBlock,
  TLNEArticleCategoryReference,
} from '../types/TLNEArticleCategoryBlock';

export const LNEArticleCategoryDTO = (data: ILNEArticleCategory): TLNEArticleCategory => ({
  categoryName: data?.category_name || '',
  categoryImage: data?.category_image ? FileDTO(data.category_image) : undefined,
  code: data?.code || undefined,
});

export const LNEArticleCategoryReferenceDTO = (data: ILNEArticleCategoryReference): TLNEArticleCategoryReference => ({
  articleCategoryReference: data?.article_category_reference
    ? data.article_category_reference?.map(LNEArticleCategoryDTO)
    : [],
});

export const LNEArticleCategoryBlockDTO = (data: ILNEArticleCategoryBlock): TLNEArticleCategoryBlock => ({
  contentTypeUid: 'article_category_block',
  articleCategoryBlock: data?.article_category_block
    ? LNEArticleCategoryReferenceDTO(data.article_category_block)
    : undefined,
});
