import React, { RefObject, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import Carousel, { CarouselRef } from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/CarouselItem';
import styles from './NavigationCarousel.module.scss';
import { INavigationCarouselProps } from './types/INavigationCarouselProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import { tablet } from '../../../../helpers/utils/screensizes';
import { EHorizontalAlignments, EVerticalAlignments } from '../../../../types/CommonFields';
import AnchorWrapper from '../../atoms/AnchorWrapper/AnchorWrapper';
import CarouselPlayButton from '../../atoms/CarouselPlayButton';
import ResponsiveImage from '../../atoms/ResponsiveImage';

const cx = classNames.bind(styles);

const NavigationCarousel = ({
  data: { isExpired, visibleOnDesktopOnly, imageLinkModularBlock },
  interval = 8000,
}: INavigationCarouselProps) => {
  const [carouselInterval, setCarouselInterval] = useState<number | null>(interval);
  const [containerRef, isVisible] = useAnimation();
  const [carouselButtons, setCarouselButtons] = useState<HTMLElement | undefined>(undefined);
  const carouselRef = useRef<CarouselRef>(null);
  const isMulti = imageLinkModularBlock.length > 1;
  const screenSize = useMediaQuery()[0];
  const isMobile = screenSize < tablet;

  useEffect(() => {
    if (carouselRef?.current !== null) {
      setCarouselButtons(carouselRef.current.element?.querySelector('.carousel-indicators') as HTMLElement);
    }
  }, [carouselRef?.current?.element]);

  if (isExpired) {
    return false as unknown as JSX.Element;
  }

  if (visibleOnDesktopOnly && isMobile) {
    return null;
  }

  return (
    <section
      ref={containerRef as RefObject<HTMLDivElement>}
      className={cx('animate content-block navigation-carousel', styles['navigation-carousel'], {
        'in-view': isVisible,
        isSingle: !isMulti,
      })}
      data-testid="navigation-carousel"
    >
      <Carousel
        ref={carouselRef}
        fade={true}
        indicators={true}
        controls={false}
        interval={carouselInterval}
        pause="hover"
      >
        {imageLinkModularBlock.map((imageLink) => (
          <CarouselItem data-testid="carousel-item" key={imageLink.uid}>
            {imageLink.imageLinkUrl && (
              <AnchorWrapper
                href={imageLink.imageLinkUrl}
                title={imageLink.imageLinkText}
                openNewWindow={imageLink.openInNewWindow}
                data-testid={'anchor'}
                className={'something'}
              >
                <ResponsiveImage
                  desktopImage={imageLink.imageLinkPath}
                  altText={imageLink.imageLinkText}
                  verticalPosition={EVerticalAlignments.Center}
                  horizontalPosition={EHorizontalAlignments.Center}
                />
              </AnchorWrapper>
            )}

            {!imageLink.imageLinkUrl && (
              <ResponsiveImage
                desktopImage={imageLink.imageLinkPath}
                altText={imageLink.imageLinkText}
                verticalPosition={EVerticalAlignments.Center}
                horizontalPosition={EHorizontalAlignments.Center}
              />
            )}
          </CarouselItem>
        ))}
      </Carousel>

      {isMulti && carouselButtons && (
        <CarouselPlayButton
          domElement={carouselButtons}
          onPlay={() => setCarouselInterval(interval)}
          onPause={() => setCarouselInterval(null)}
          initialState={interval !== null}
        />
      )}
    </section>
  );
};

export default NavigationCarousel;
