import React from 'react';
import classNames from 'classnames';
import HorizontalSlider from 'components/contentstack/atoms/HorizontalSlider/HorizontalSlider';
import { RESOURCE_TYPES } from 'helpers/constants/resourceTypes';
import useAnimation from 'helpers/hooks/useAnimation';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mobile } from 'helpers/utils/screensizes';
import { TQuickLinkImage, TQuickLinkPdf, TQuickLinkVideo, TQuickLinkWeb } from 'types/TQuickLinks';
import { ImageLink } from './Links/ImageLink';
import { PdfLink } from './Links/PdfLink';
import { VideoLink } from './Links/VideoLink';
import { WebLink } from './Links/WebLink';
import styles from './QuickLinks.module.scss';
import { IQuickLinksProps } from './types/IQuickLinksProps';

const QuickLinks = ({ displayOnMobile, headline, linksData }: IQuickLinksProps) => {
  const [screenWidth] = useMediaQuery();
  const [containerRef, isVisible] = useAnimation();

  return (
    <section
      ref={containerRef as any}
      className={classNames(
        'animate',
        styles.quickLinkContainer,
        'content-block',
        'd-md-block',
        { 'in-view': isVisible },
        {
          'd-none': !displayOnMobile,
        },
      )}
    >
      {headline && (
        <h3 className={`${styles['section-title']} text-center`} dangerouslySetInnerHTML={{ __html: headline }}></h3>
      )}
      <HorizontalSlider>
        {linksData.map((link) => {
          if (link.hideLinkOnMobile && screenWidth <= mobile) {
            return null;
          }

          switch (link.resourceType) {
            case RESOURCE_TYPES.PDF:
              return <PdfLink key={link.uid} link={link as TQuickLinkPdf} />;
            case RESOURCE_TYPES.IMAGE:
              return <ImageLink key={link.uid} link={link as TQuickLinkImage} />;
            case RESOURCE_TYPES.VIDEO:
              return <VideoLink key={link.uid} link={link as TQuickLinkVideo} />;
            case RESOURCE_TYPES.WEB:
              return <WebLink key={link.uid} link={link as TQuickLinkWeb} />;
            default:
              return null;
          }
        })}
      </HorizontalSlider>
    </section>
  );
};

export default QuickLinks;
