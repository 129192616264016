import { useState } from 'react';
import styles from './PlayPauseButton.module.scss';
import { ICarouselPlayButtonProps } from './types/ICarouselPlayButtonProps';

const PlayPauseButton = ({ onPlay, onPause, initialState = true }: ICarouselPlayButtonProps) => {
  const [play, setPlay] = useState<boolean>(initialState);

  const handleOnClick = () => {
    setPlay((prevState) => {
      !prevState ? onPlay() : onPause();

      return !prevState;
    });
  };

  return (
    <button
      data-testid="play-pause-button"
      aria-label="Play/Pause"
      className={`${styles.button} ${play ? styles.play : styles.pause}`}
      onClick={handleOnClick}
    />
  );
};

export default PlayPauseButton;
