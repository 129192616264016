import { Container } from 'react-bootstrap';
import { IMarketingHeroImageProps } from './types/IMarketingHeroImageProps';
import { EHorizontalAlignments, EVerticalAlignments } from '../../../../../types/CommonFields';
import ResponsiveImage from '../../../atoms/ResponsiveImage';

const MarketingHeroImage = ({ image, isMobile, isTablet }: IMarketingHeroImageProps) => {
  const { imageDesktop, imageMobile, horizontalImagePosition, verticalImagePosition, imageAltText } = image;

  if ((!imageDesktop && !imageMobile) || (isMobile && !imageMobile) || (!isMobile && !imageDesktop)) {
    return null;
  }

  return (
    <Container fluid className="p-0" data-testid="marketing-hero-image">
      <ResponsiveImage
        mobileImage={imageMobile}
        desktopImage={imageDesktop}
        verticalPosition={isMobile ? EVerticalAlignments.Top : verticalImagePosition}
        horizontalPosition={isMobile ? EHorizontalAlignments.Center : horizontalImagePosition}
        altText={imageAltText}
        customClass="marketing-hero"
      />
    </Container>
  );
};

export default MarketingHeroImage;
