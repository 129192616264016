import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { Col, Container, Row, Modal, Button } from 'react-bootstrap';
import { ImageHelpers } from 'helpers/imageHelpers';
import { THotspotBlock } from 'types/THotspots';
import HotspotContent from './HotspotContent';
import styles from './Hotspots.module.scss';
import { IHotspotsProps } from './types/IHotspotsProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import { tablet } from '../../../../helpers/utils/screensizes';

const cx = classNames.bind(styles);

const HotspotsComponent = ({ data }: IHotspotsProps) => {
  const { headline, hotspotBlock, mainImage, uid } = data;
  const [activeHotspot, setActiveHotspot] = useState<THotspotBlock>(data.hotspotBlock[0]);
  const [isBiggerThanTablet] = useMediaQuery(tablet);
  const isLowerThanTablet = !isBiggerThanTablet;
  const [isShowModal, setIsShowModal] = useState(false);
  const [containerRef, isVisible] = useAnimation();
  const ContentWrapper = useRef<HTMLDivElement>();
  const handleOnCloseModal = () => setIsShowModal(false);

  const handleHotspot = (hotspotBlock: THotspotBlock) => {
    setActiveHotspot(hotspotBlock);

    if (isLowerThanTablet) {
      setIsShowModal(true);
    }

    if (ContentWrapper.current) {
      ContentWrapper.current.focus();
    }
  };
  const moveFocusDown = () => {
    if (document) {
      const listItems: any = document?.querySelector(`.points-container-${uid}`)?.childNodes;
      const activeItem = document.activeElement;
      if (listItems && Boolean(listItems.length)) {
        for (let i = 0; i < listItems.length; i++) {
          const listLength = listItems.length;
          if (activeItem === listItems[i] && activeItem !== listItems[listLength - 1]) {
            listItems[i + 1].focus();
          }
        }
      }
    }
  };
  const moveFocusUp = () => {
    const listItems: any = document?.querySelector(`.points-container-${uid}`)?.childNodes;
    const activeItem = document.activeElement;
    if (listItems && Boolean(listItems.length)) {
      for (let i = 0; i < listItems.length; i++) {
        if (activeItem === listItems[i] && activeItem !== listItems[0]) {
          listItems[i - 1].focus();
        }
      }
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>, activeHotspost: THotspotBlock) => {
    if (e.key === 'Enter') {
      handleHotspot(activeHotspost);
    } else if (e.key === 'ArrowDown') {
      moveFocusDown();
    } else if (e.key === 'ArrowUp') {
      moveFocusUp();
    }
  };

  return (
    <div
      data-testid={`hotspot-container`}
      ref={containerRef as any}
      className={cx('animate', 'content-block', 'hotspot-container', { 'in-view': isVisible })}
    >
      {headline && (
        <Container data-testid={`hotspot-heading-container`} className={styles['hotspot-heading-container']}>
          <div className={`${styles['hotspots-heading-wrapper']} text-center`}>
            <h3 className={styles['hotspots-heading']} data-testid={`hotspot-heading`}>
              {headline}
            </h3>
          </div>
        </Container>
      )}

      <Container data-testid={`hotspot-main-container`} className={styles['hotspot-main-container']}>
        <Row>
          {isBiggerThanTablet && (
            <Col className={'col-xs-12 col-md-4'}>
              <HotspotContent activeHotspot={activeHotspot} textAlignment="text-center" ref={ContentWrapper} />
            </Col>
          )}

          <Col className={`col-xs-12  col-md-8 ${styles['hotspot-wrap']}`}>
            <div className={styles['hotspots-main-img-wrap']}>
              <img
                src={ImageHelpers.modifiedContentStackImageUrl(mainImage.url, '550')}
                title={mainImage.title}
                loading="lazy"
                alt={mainImage.title}
                data-testid="hotspots-main-img"
                className={styles['hotspots-main-img']}
              />
              <div className={`points-container-${uid}`}>
                {hotspotBlock.map(
                  (hotspot, index) =>
                    hotspot && (
                      <div
                        className={cx('hotspots-point-wrapper', {
                          'hotspot-active': activeHotspot && activeHotspot.uid === hotspot.uid && isBiggerThanTablet,
                        })}
                        onClick={() => handleHotspot(hotspot)}
                        onKeyUp={(e) => handleKeyUp(e, hotspot)}
                        style={
                          {
                            '--left-alignment': `${hotspot.hotspotLeftAlignment}px`,
                            '--top-alignment': `${hotspot.hotspotTopAlignment}px`,
                          } as React.CSSProperties
                        }
                        role="button"
                        title={hotspot.hotspotAltText || `Hotspot ${index + 1}`}
                        tabIndex={0}
                        data-testid={`hotspots-point-${index + 1}`}
                        key={hotspot.uid}
                      >
                        <span className={`ss-plus ${styles['hotspots-point']}`}></span>
                      </div>
                    ),
                )}
              </div>
            </div>
          </Col>

          {isLowerThanTablet && (
            <div data-testid="hotspots-modal-container">
              <Modal show={isShowModal} onHide={handleOnCloseModal} size="lg" className={styles['hotspots-modal']}>
                <Modal.Body>
                  <HotspotContent activeHotspot={activeHotspot} textAlignment="text-left" ref={ContentWrapper} />
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    variant="light"
                    onClick={handleOnCloseModal}
                    className={styles['hotspots-close-btn']}
                    data-testid="close-hotspot-dialog"
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default HotspotsComponent;
