import { FileDTO } from './CommonFieldsDTO';
import { HEADLINE_COLORS } from '../helpers/constants/CommonFieldsConstants';
import {
  IMarketingHero,
  IMarketingHeroReference,
  IMarketingHeroImageContent,
  IMarketingHeroVideoContent,
  IMarketingHeroBlock,
} from '../interfaces/IMarketingHero';
import { EHorizontalAlignments, ETextAlignments, EVerticalAlignments } from '../types/CommonFields';
import { TMarketingHero, TMarketingHeroImageContent, TMarketingHeroVideoContent } from '../types/TMarketingHero';

export const MarketingHeroBlockDTO = ({ marketing_hero_block }: IMarketingHeroBlock): TMarketingHero => {
  return MarketingHeroReferenceDTO(marketing_hero_block);
};

export const MarketingHeroReferenceDTO = ({ marketing_hero_reference }: IMarketingHeroReference): TMarketingHero => {
  return MarketingHeroDTO(marketing_hero_reference[0]);
};

export const MarketingHeroDTO = (marketingHero: IMarketingHero): TMarketingHero => {
  const isImage = marketingHero.content_selection === 'Image';
  const isVideo = marketingHero.content_selection === 'Video';

  return {
    contentTypeUid: 'marketing_hero',
    uid: marketingHero.uid,
    displayOnMobile: marketingHero.display_on_mobile,
    nameImage: FileDTO(marketingHero.name_image),
    nameImageAlt: marketingHero.name_image_alt,
    nameImageMaxWidthMobile: marketingHero.name_image_max_width_mobile,
    nameImageMaxWidthDesktop: marketingHero.name_image_max_width_desktop,
    headline: marketingHero.headline,
    headlineColor: marketingHero.headline_color === '#000000' ? HEADLINE_COLORS.BLACK : HEADLINE_COLORS.WHITE,
    textJustification: marketingHero.text_justification || ETextAlignments.Center,
    isBackgroundOverlay: marketingHero.is_background_overlay,
    isImage,
    isVideo,
    imageContentGroup: isImage ? MarketingHeroImageContentDTO(marketingHero.image_content_group) : undefined,
    videoContentGroup: isVideo ? MarketingHeroVideoContentDTO(marketingHero.video_content_group) : undefined,
  };
};

export const MarketingHeroImageContentDTO = (image: IMarketingHeroImageContent): TMarketingHeroImageContent => ({
  imageDesktop: FileDTO(image.image_desktop),
  imageMobile: FileDTO(image.image_mobile),
  verticalImagePosition: image.vertical_image_position || EVerticalAlignments.Center,
  horizontalImagePosition: image.horizontal_image_position || EHorizontalAlignments.Center,
  imageAltText: image.image_alt_text,
});

export const MarketingHeroVideoContentDTO = (video: IMarketingHeroVideoContent): TMarketingHeroVideoContent => ({
  videoPath: FileDTO(video.video_path),
  loopVideo: video.loop_video,
  posterImage: FileDTO(video.poster_image),
  verticalVideoPosition: video.vertical_video_position || EVerticalAlignments.Center,
  horizontalVideoPosition: video.horizontal_video_position || EHorizontalAlignments.Center,
});
