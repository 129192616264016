import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import styles from './OneVideo.module.scss';
import useAnimation from '../../../../helpers/hooks/useAnimation';
import { TOneVideo } from '../../../../types/TOneVideo';
import VideoIframe from '../../atoms/VideoIframe';

const justificationEnum = {
  left: '1/2 Width on Left',
  right: '1/2 Width on Right',
  edge: 'Full-width edge-to-edge',
  full: 'Full-width',
};

const OneVideo = ({
  headline = '',
  description = '',
  videoLocation,
  videoReferenceID,
  autoplay = false,
  loop = false,
  recommendedYouTubeVideos = false,
  videoJustification = '1/2 Width on Left',
  textAlignment = 'below',
  seoData,
}: TOneVideo) => {
  const mdSize =
    videoJustification === justificationEnum.left || videoJustification === justificationEnum.right ? 6 : 12;
  const [containerRef, isVisible] = useAnimation();

  return (
    <div
      ref={containerRef as any}
      className={classNames('animate', 'content-block', { 'in-view': isVisible }, styles['one-video'])}
    >
      <div
        data-testid="one-video-wrapper"
        className={videoJustification === justificationEnum.edge ? styles['full-width'] : 'container'}
      >
        <Row
          className={classNames({
            'flex-md-row-reverse': videoJustification === justificationEnum.right,
            'flex-md-row': videoJustification === justificationEnum.left,
          })}
        >
          <Col
            data-testid="one-video-video"
            className={`${styles['video-container']} ${
              (videoJustification === justificationEnum.edge || videoJustification === justificationEnum.full) &&
              textAlignment === 'above'
                ? 'order-2'
                : ''
            }`}
            sm={12}
            md={mdSize}
          >
            <VideoIframe
              videoLocation={videoLocation}
              videoRefId={videoReferenceID}
              autoplay={autoplay}
              loop={loop}
              recommendedYouTubeVideos={recommendedYouTubeVideos}
              seoData={seoData}
            />
          </Col>
          <Col
            data-testid="one-video-text"
            className={`my-auto pt-3 text-center ${
              (videoJustification === justificationEnum.edge || videoJustification === justificationEnum.full) &&
              textAlignment === 'above'
                ? 'order-1'
                : ''
            }`}
            sm={12}
            md={mdSize}
          >
            <h3 dangerouslySetInnerHTML={{ __html: headline }}></h3>
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OneVideo;
