import { ETextAlignments } from '../../../../types/CommonFields';
import { TFullWidthImage, TFullWidthImagePanel } from '../../../../types/TFullWidthImage';
import { TTwoColumnRowColumnGroup } from '../../../../types/TTwoColumnRow';

export const parseColumnGroupItemToFullWidthImageData = (
  columnGroupItem: TTwoColumnRowColumnGroup,
): TFullWidthImage => ({
  contentTypeUid: 'full_width_image',
  expirationRow: null,
  isContentExpired: false,
  imagePanelBlock: [
    {
      uid: columnGroupItem.uid,
      isExpired: false,
      imageGroup: {
        imagePathDesktop: columnGroupItem.imageGroup.imagePath,
        imagePathMobile: null,
        imageAltText: columnGroupItem.imageGroup.imageAlt,
        verticalImagePosition: columnGroupItem.imageGroup.verticalImagePosition,
        horizontalImagePosition: columnGroupItem.imageGroup.horizontalImagePosition,
        couscousTex: '',
      },
      linkGroup: columnGroupItem.textLinksGroup.map((item) => ({
        redirectLinkText: item.linkText,
        redirectLinkUrl: item.linkUrl,
        openNewWindow: item.openInNewWindow,
        uid: item.uid,
      })),
      associateProduct: {
        product: '',
        showPriceInformation: false,
      },
      textPlacement: ETextAlignments.Center,
      nameImage: columnGroupItem.nameImage,
      nameImageAlt: columnGroupItem.nameImageAlt,
      nameImageMaxWidthMobile: columnGroupItem.nameImageMaxWidthMobile,
      nameImageMaxWidthDesktop: columnGroupItem.nameImageMaxWidthDesktop,
      headline: columnGroupItem.headline,
      description: columnGroupItem.description,
      openInNewWindow: columnGroupItem.openInNewWindow,
    } as unknown as TFullWidthImagePanel,
  ] as unknown as TFullWidthImagePanel[],
  uid: columnGroupItem.uid,
});
