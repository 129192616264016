import classnames from 'classnames';
import styles from './FooterNavigationGroup.module.scss';
import { IFooterNavigationGroupProps } from './types/IFooterNavigationGroupProps';

const FooterNavigationGroup = ({ navigationGroup }: IFooterNavigationGroupProps) => {
  const { linkGroup, copyrightText } = navigationGroup;

  if (!Boolean(linkGroup.length) && !copyrightText) {
    return false as unknown as JSX.Element;
  }

  return (
    <div
      className={classnames(`d-flex flex-column flex-md-row align-content-center ${styles.copyright}`, {
        'justify-content-end': !Boolean(linkGroup.length),
      })}
      data-testid="navigation-group"
    >
      {Boolean(linkGroup.length) && (
        <ul
          className={`list-inline me-md-auto ${styles.copyrightLinks}`}
          role="menubar"
          aria-label="Corporate"
          data-testid="navigation-group-links"
        >
          {linkGroup.map(({ linkText, linkUrl, openInNewWindow, uid }) => (
            <li key={uid} className={'list-inline-item me-3'} role="menuitem">
              <a
                href={linkUrl}
                target={openInNewWindow ? '_blank' : '_self'}
                rel={openInNewWindow ? 'noreferrer' : undefined}
              >
                {linkText}
              </a>
            </li>
          ))}
        </ul>
      )}

      <div className={`${styles.copyrightText}`}>{copyrightText}</div>
    </div>
  );
};

export default FooterNavigationGroup;
