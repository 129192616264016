import styles from './DisclaimerSubTitleBlock.module.scss';
import IDisclaimerSubTitleBlock from './types/IDisclaimerSubTitleBlock';

const DisclaimerSubTitleBlock = ({ subTitleBlock }: IDisclaimerSubTitleBlock) => {
  if (!Boolean(subTitleBlock.subTitle)) {
    return null;
  }

  return (
    <p className={styles['sub-title']} data-testid="disclaimer-sub-title">
      {subTitleBlock.subTitle}
    </p>
  );
};
export default DisclaimerSubTitleBlock;
