export enum EContentBlocksUid {
  ABOVE_PRODUCT_LIST = 'above_product_list',
  BUTTON_ROW = 'button_row_block',
  DEFINITIONS = 'definitions',
  DISCLAIMERS = 'disclaimers',
  FAQ = 'faq',
  FEATURE_BAND = 'feature_band',
  FREE_ENTRY = 'free_entry',
  RICH_TEXT_EDITOR_NO_SPOSTO = 'rich_text_editor_no_sposto',
  FULL_WIDTH_IMAGE = 'full_width_image',
  FULL_WIDTH_TEXT = 'full_width_text',
  FULL_WIDTH_VIDEO = 'full_width_video',
  HOTSPOTS = 'hotspots',
  IMAGE_CAROUSEL = 'carousel',
  INLINE_SLIDER = 'in_line_slider',
  LANDING_SUB_NAV = 'landing_sub_nav',
  LNE_ARTICLE_CATEGORY_BLOCK = 'article_category_block',
  LNE_FEATURED_ARTICLES_BLOCK = 'featured_articles_block',
  LNE_FEATURED_COLLECTIONS_BLOCK = 'featured_collections_block',
  MARKETING_HERO = 'marketing_hero',
  NAVIGATION_CAROUSEL = 'navigation_carousel',
  ONE_TO_FOUR_COLUMNS = 'one_to_four_columns',
  ONE_VIDEO = 'one_video',
  PLAIN_FEATURES = 'plain_features',
  PRODUCT_VIEW_EXPLORER = 'product_view_explorer_block',
  QUICK_LINKS = 'quick_links',
  TWO_COLUMNS_ROW = 'two_columns_row',
  Z8_ACCORDION = 'z8_accordion',
  FOOT_NOTES = 'pdp_global_footnotes',
}
