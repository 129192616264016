import { ISeoVideo } from 'interfaces/ISeoVideo';
import { TSeoVideoData } from 'types/TSeoVideoData';
import { ISeoData, ISeoDataSameAs } from '../interfaces/ISeoData';
import { TSeoData, TSeoDataSameAs } from '../types/TSeoData';

export const SeoVideoDataDTO = (seoData: ISeoVideo): TSeoVideoData => {
  return {
    name: seoData.name || null,
    thumbnailUrl: seoData.thumbnail_url || null,
    uploadDate: (seoData.upload_date && new Date(seoData?.upload_date)) || null,
    contentUrl: seoData.content_url || null,
    description: seoData.description || null,
    duration: seoData.duration || null,
    embedUrl: seoData.embebed_url || null,
  };
};

export const SeoDataDTO = (seoData?: ISeoData): TSeoData | undefined => {
  if (!seoData) {
    return;
  }

  return {
    name: seoData.name,
    alternateName: seoData.alternate_name,
    url: seoData.url,
    logo: seoData.logo,
    sameAs: Array.isArray(seoData.same_as)
      ? seoData.same_as.map(SeoDataSameAsDTO)
      : [SeoDataSameAsDTO(seoData.same_as)],
  };
};

export const SeoDataSameAsDTO = (sameAsData: ISeoDataSameAs): TSeoDataSameAs => ({
  sameAsUrl: sameAsData.same_as_url,
});
