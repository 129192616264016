import { RefObject } from 'react';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import DisclaimerDescriptionBlock from './DisclaimerDescriptionBlock';
import DisclaimerIconBlock from './DisclaimerIconBlock';
import styles from './DisclaimersBlock.module.scss';
import DisclaimerSpacerBlock from './DisclaimerSpacerBlock';
import DisclaimerSubTitleBlock from './DisclaimerSubTitleBlock';
import DisclaimerTitleBlock from './DisclaimerTitleBlock';
import IDisclaimersBlockProps from './types/IDisclaimersBlockProps';
import { DISCLAIMER_BLOCK_NAMES } from '../../../../helpers/constants/DisclaimerBlockNames';
import useAnimation from '../../../../helpers/hooks/useAnimation';

const DisclaimersBlock = ({ disclaimer }: IDisclaimersBlockProps) => {
  const [containerRef, isVisible] = useAnimation();

  if (!Boolean(disclaimer.disclaimerContentBlocks.length)) {
    return false as unknown as JSX.Element;
  }

  return (
    <Container
      data-testid="disclaimers-block"
      ref={containerRef as RefObject<HTMLDivElement>}
      className={classNames('animate', 'content-block', { 'in-view': isVisible }, styles.disclaimers)}
    >
      {disclaimer.disclaimerContentBlocks?.map((contentBlock, index) => (
        <div
          key={`disclaimer-${disclaimer.uid}-block-${index}`}
          data-testid={`disclaimer-${contentBlock.contentTypeUid}-${contentBlock.uid}`}
        >
          {contentBlock.contentTypeUid === DISCLAIMER_BLOCK_NAMES.TITLE_BLOCK && (
            <DisclaimerTitleBlock titleBlock={contentBlock} />
          )}

          {contentBlock.contentTypeUid === DISCLAIMER_BLOCK_NAMES.SUBTITLE_BLOCK && (
            <DisclaimerSubTitleBlock subTitleBlock={contentBlock} />
          )}

          {contentBlock.contentTypeUid === DISCLAIMER_BLOCK_NAMES.DESCRIPTION_BLOCK && (
            <DisclaimerDescriptionBlock descriptionBlock={contentBlock} />
          )}

          {contentBlock.contentTypeUid === DISCLAIMER_BLOCK_NAMES.ICON_BLOCK && (
            <DisclaimerIconBlock iconBlock={contentBlock} />
          )}

          {contentBlock.contentTypeUid === DISCLAIMER_BLOCK_NAMES.SPACE_BLOCK && (
            <DisclaimerSpacerBlock spacerBlock={contentBlock} />
          )}
        </div>
      ))}
    </Container>
  );
};
export default DisclaimersBlock;
