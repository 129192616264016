import styles from './VideoPanel.module.scss';
import { useData } from '../../../../../../helpers/hooks/useData';
import VideoIframe from '../../../../atoms/VideoIframe';
import { IVideoIframe } from '../../../../atoms/VideoIframe/types/IVideoIframe';
import VideoUploaded from '../../../../atoms/VideoUploaded';
import { IVideoUploaded } from '../../../../atoms/VideoUploaded/types/IVideoUploaded';

export interface Props {
  contentstack?: IVideoUploaded;
  youTubeOrVimeo?: IVideoIframe;
  itemLength?: number;
}

const VideoPanel = ({ contentstack, youTubeOrVimeo, itemLength }: Props) => {
  const [contentstackData, isValidContentstack] = useData<IVideoUploaded | undefined, IVideoUploaded>(contentstack);
  const [youTubeOrVimeoData, isValidYouTubeOrVimeo] = useData<IVideoIframe | undefined, IVideoIframe>(youTubeOrVimeo);

  if (isValidContentstack || isValidYouTubeOrVimeo) {
    return (
      <div
        data-testid="video-container"
        className={` ${styles['video-container']} ${itemLength && itemLength === 1 && styles['item-centered']}`}
      >
        {isValidContentstack && (
          <VideoUploaded
            src={contentstackData.src}
            autoplay={contentstackData.autoplay}
            contentType={contentstackData.contentType}
            controls={contentstackData.controls}
            loop={contentstackData.loop}
            posterImage={contentstackData.posterImage}
            seoData={contentstackData.seoData}
          />
        )}
        {isValidYouTubeOrVimeo && (
          <VideoIframe
            videoLocation={youTubeOrVimeoData.videoLocation}
            videoRefId={youTubeOrVimeoData.videoRefId}
            autoplay={youTubeOrVimeoData.autoplay}
            controls={youTubeOrVimeoData.controls}
            loop={youTubeOrVimeoData.loop}
            recommendedYouTubeVideos={youTubeOrVimeoData.recommendedYouTubeVideos}
            seoData={youTubeOrVimeoData.seoData}
          />
        )}
      </div>
    );
  }

  return null;
};

export default VideoPanel;
