import { FileDTO, FileMandatoryDTO } from './CommonFieldsDTO';
import { IHotspotBlock, IHotspots, IHotspotsBlock, IHotspotsReference } from '../interfaces/IHotspot';
import { THotspotBlock, THotspots } from '../types/THotspots';

export const HotspotsBlockDTO = ({ hotspots_block }: IHotspotsBlock): THotspots => HotspotsReferenceDTO(hotspots_block);

export const HotspotsReferenceDTO = ({ hotspots_reference }: IHotspotsReference): THotspots =>
  HotspotsDTO(hotspots_reference[0]);

export const HotspotsDTO = (hotspot: IHotspots): THotspots => ({
  contentTypeUid: 'hotspots',
  displayOnMobile: hotspot.display_on_mobile,
  headline: hotspot.headline,
  hotspotBlock: hotspot.hotspot_block.map(HotspotBlockDTO),
  mainImage: FileMandatoryDTO(hotspot.main_image),
  uid: hotspot.uid,
});

export const HotspotBlockDTO = ({ hotspot }: IHotspotBlock): THotspotBlock => ({
  hotspotImage: FileDTO(hotspot.hotspot_image),
  uid: hotspot._metadata.uid,
  hotspotHeadline: hotspot.hotspot_headline,
  hotspotDescription: hotspot.hotspot_description,
  hotspotAltText: hotspot.hotspot_alt_text,
  hotspotLeftAlignment: hotspot.hotspot_left_alignment,
  hotspotTopAlignment: hotspot.hotspot_top_alignment,
});
