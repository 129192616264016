import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { appLocales } from 'helpers/constants/appLocales';
import { Locales } from 'helpers/utils/swiftType';
import { TLocalizationInfo } from 'types/TLocalizationInfo';
import { getLocalizationInfo } from '../../project.config';

interface Input {
  darkList?: string[];
  whiteList?: string[];
}

export const useLocale = ({ darkList, whiteList }: Input = {}) => {
  const router = useRouter();
  const localizationInfo: TLocalizationInfo = getLocalizationInfo(router.locale);
  const locale = localizationInfo.appLocale as Locales;
  const isUsaStore = locale === appLocales.EN_US;
  const isCanadaStore = locale === appLocales.EN_CA || locale === appLocales.FR_CA;
  const isMexicoStore = locale === appLocales.ES_MX;
  const isLatamStore = [appLocales.ES_MX, appLocales.PT_BR, appLocales.ES_LATAM].includes(locale);

  let defaultAuthorized = true;
  if (darkList) defaultAuthorized = true;
  if (whiteList) defaultAuthorized = false;
  const [authorized, setAuthorized] = useState(defaultAuthorized);

  useEffect(() => {
    if (darkList?.includes(locale)) {
      setAuthorized(false);
    } else if (whiteList?.includes(locale)) {
      setAuthorized(true);
    }
  }, [darkList, locale, whiteList]);

  return { locale, authorized, isCanadaStore, isUsaStore, isMexicoStore, localizationInfo, isLatamStore };
};
