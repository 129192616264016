import { createPortal } from 'react-dom';
import { ICarouselPlayButtonProps } from './types/ICarouselPlayButtonProps';
import PlayPauseButton from '../../../atoms/PlayPauseButton';

const CarouselPlayButton = ({ domElement, onPlay, onPause, initialState }: ICarouselPlayButtonProps) => {
  if (typeof domElement === null || typeof window !== 'object') {
    return null;
  }

  return createPortal(<PlayPauseButton onPlay={onPlay} onPause={onPause} initialState={initialState} />, domElement);
};

export default CarouselPlayButton;
