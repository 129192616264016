import { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames/bind';
import { Navbar } from 'react-bootstrap';
import { useCart } from 'frontastic';
import styles from './Header.module.scss';
import NavigationMenu from './NavigationMenu';
import Search from './Search';
import { IHeaderProps } from './types/IHeaderProps';
import UserBarBlock from './UserBarBlock';
import { appLocales } from '../../../helpers/constants/appLocales';
import { useFormat } from '../../../helpers/hooks/useFormat';
import useMediaQuery from '../../../helpers/hooks/useMediaQuery';
import { desktop } from '../../../helpers/utils/screensizes';
import { getLocalizationInfo } from '../../../project.config';

const cx = classNames.bind(styles);

const Header = ({ data }: IHeaderProps) => {
  const router = useRouter();
  const locale = getLocalizationInfo(router.locale).locale.split('@')[0];
  const { formatMessage } = useFormat({ name: 'common' });
  const english = formatMessage({ id: 'english', defaultMessage: 'English' });
  const french = formatMessage({ id: 'french', defaultMessage: 'Français' });
  const { addItemsBySku } = useCart();

  const [isBiggerThanDesktop] = useMediaQuery(desktop);
  const isMobileDevice = !isBiggerThanDesktop;

  const [showCloseMenuIcon, setShowCloseMenuIcon] = useState(false);
  const { aboveSearch, belowSearch, logoLink, logoPath, userBarBlock, navigationMenu } = data;
  const isShowAboveSearch = aboveSearch.region || aboveSearch.country || aboveSearch.continent;

  const handleOnToggleMenuIcon = () => {
    setShowCloseMenuIcon((current) => !current);
  };

  const [englishUrl, setEnglishUrl] = useState<string>('');
  const [frenchUrl, setFrenchUrl] = useState<string>('');

  useEffect(() => {
    setEnglishUrl(window?.location?.href?.replace('fr.nikon', 'en.nikon'));
    setFrenchUrl(window?.location?.href?.replace('en.nikon', 'fr.nikon'));
  }, []);

  /** SPOSTO ADD MULTIPLE ITEMS TO CART HANDLER
   * @summary Sposto will be dispatching an event that will contain an array of skus and,
   * possibly, the name of an event that they will be listening for to determine the status
   * of the addItemsBySku call.
   *
   * If the name exists, create a custom event and dispatch it with the property "isError" set
   * to true or false based on the status of the resonse from addItemsBySku
   *
   */

  const handleSpostoAddMultipleItemsToCart = useCallback(async (e) => {
    const skus: string[] = e.detail?.skus;
    const eventCallbackName: string | null = e.detail.event;

    if (skus && skus.length) {
      addItemsBySku(e.detail.skus).then((res: any) => {
        if (eventCallbackName) {
          const event = new CustomEvent(e.detail.event, {
            detail: {
              isError: !res?.cartId,
            },
          });
          window.dispatchEvent(event);
        }
      });
    }
  }, []);

  // Listen for add to cart events from Sposto code...
  useEffect(() => {
    window.addEventListener('siAddItemsToCart', handleSpostoAddMultipleItemsToCart);
    return () => {
      window.removeEventListener('siAddItemsToCart', handleSpostoAddMultipleItemsToCart);
    };
  }, []);

  return (
    <header className={`${styles.header} site-header`} data-testid="header">
      {isShowAboveSearch && (
        <div className={`${styles.regionalText} ${styles.regionalTextMobile}`} data-testid="header-upper-div">
          {aboveSearch.region && <span>{aboveSearch.region}</span>}
          {aboveSearch.country && <span>{aboveSearch.country}</span>}
          {aboveSearch.continent && <span>{aboveSearch.continent}</span>}
        </div>
      )}

      <Navbar expand="lg" data-testid="header-navbar">
        <Navbar.Brand href={logoLink.href} title={logoLink.title} data-testid="header-navbar-brand">
          {logoPath && (
            <img
              src={logoPath.url}
              className={styles.logo}
              title={logoLink.title}
              width={68}
              height={68}
              alt={logoLink.title}
              loading="lazy"
              data-testid="header-logo-img"
            />
          )}
        </Navbar.Brand>

        <div className={styles.navIcons}>
          {Boolean(userBarBlock.length) && (
            <UserBarBlock data={userBarBlock} isDesktopIconNav={false} locale={locale} />
          )}

          <Navbar.Toggle
            onClick={handleOnToggleMenuIcon}
            data-testid="navbar-toggle"
            className={cx(styles.navbarToggle, {
              navbarToggleClose: showCloseMenuIcon,
            })}
          />
        </div>

        <Navbar.Collapse className={styles.navbarCollapse}>
          <div>
            <div
              className={`${styles.regionalText} ${styles.regionalTextDesktop}`}
              data-testid="header-upper-div-collapse"
            >
              {aboveSearch.region && <span>{aboveSearch.region}</span>}
              {aboveSearch.country && <span>{aboveSearch.country}</span>}
              {aboveSearch.continent && <span>{aboveSearch.continent}</span>}
            </div>

            {locale === appLocales.PT_BR && <div className={styles.noSearchSpacer} />}
            {locale !== appLocales.PT_BR && <Search toggleSearch={handleOnToggleMenuIcon} />}

            <div
              className={`${styles.regionalText} ${styles.regionalTextDesktop}`}
              data-testid="header-below-div-collapse"
            >
              {(locale === appLocales.EN_CA || locale === appLocales.FR_CA) && (
                <>
                  <span>
                    <Link href={englishUrl}>
                      <a className={styles.localText}>
                        <span>{english}</span>
                        <span className="sr-only">{english}</span>
                      </a>
                    </Link>
                  </span>
                  <span>
                    <Link href={frenchUrl}>
                      <a className={styles.localText}>
                        <span>{french}</span>
                        <span className="sr-only">{french}</span>
                      </a>
                    </Link>
                  </span>
                </>
              )}

              {belowSearch.length &&
                belowSearch.map(({ linkText, linkUrl, openInNewWindow, uid }) => (
                  <span key={uid}>
                    <a
                      className={styles.localText}
                      href={linkUrl}
                      target={openInNewWindow ? '_blank' : '_self'}
                      rel="noreferrer"
                    >
                      <span>{linkText}</span>
                      <span className="sr-only"> (View in a new window)</span>
                    </a>
                  </span>
                ))}
            </div>
          </div>

          <div className={cx(styles.navContainer)}>
            <NavigationMenu
              menuItems={navigationMenu}
              isMobileDevice={isMobileDevice}
              ariaRole="menubar"
              ariaLabel="main"
            />

            {Boolean(userBarBlock.length) && <UserBarBlock data={userBarBlock} isDesktopIconNav locale={locale} />}
          </div>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
