import { Col, Row } from 'react-bootstrap';
import styles from './FootNotes.module.scss';
import { IFootNotesProps } from './types/IFootNotesProps';

const FootNotes = ({ data }: IFootNotesProps) => {
  if (!data.globalFootNotes) {
    return false as unknown as JSX.Element;
  }

  return (
    <div className={styles.footNotes} data-testid={'footnote'}>
      <Row className="g-0">
        <Col>
          <p className={styles.text}>{data.globalFootNotes}</p>
        </Col>
      </Row>
    </div>
  );
};

export default FootNotes;
