import {
  ILandingSubNav,
  ILandingSubNavBlock,
  ILandingSubNavReference,
  ISubCategoriesGroup,
} from '../interfaces/ILandingSubNav';
import { TLandingSubNav, TSubCategoriesGroup } from '../types/TLandingSubNav';

export const LandingSubNavBlockDTO = ({ landing_sub_nav_block }: ILandingSubNavBlock): TLandingSubNav =>
  LandingSubNavReferenceDTO(landing_sub_nav_block);

export const LandingSubNavReferenceDTO = ({ landing_sub_nav_reference }: ILandingSubNavReference): TLandingSubNav =>
  LandingSubNavPropsDTO(landing_sub_nav_reference[0]);

export const LandingSubNavPropsDTO = (landingSubNavProp: ILandingSubNav): TLandingSubNav => ({
  contentTypeUid: 'landing_sub_nav',
  uid: landingSubNavProp.uid,
  title: landingSubNavProp.title,
  subCategoriesGroup: (landingSubNavProp.sub_categories_group || []).map(subCategoryDTO),
});

export const subCategoryDTO = (sub_categories_group: ISubCategoriesGroup): TSubCategoriesGroup => ({
  subCategoryName: sub_categories_group.sub_category_name,
  uid: sub_categories_group._metadata.uid,
  subCategoryImagePath: {
    contentType: sub_categories_group.sub_category_image_path.content_type,
    fileSize: sub_categories_group.sub_category_image_path.file_size,
    filename: sub_categories_group.sub_category_image_path.filename,
    url: sub_categories_group.sub_category_image_path.url,
    title: sub_categories_group.sub_category_image_path.title,
    parentUid: sub_categories_group.sub_category_image_path.parent_uid,
  },
  subCategoryAltImage: sub_categories_group.sub_category_alt_image,
  subCategoryUrl: sub_categories_group.sub_category_url,
});
