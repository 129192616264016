import {
  ICommercetoolsProduct,
  IFile,
  IIConFile,
  ILink,
  INavigationMenu,
  INavigationMenuBlock,
  INavigationSubMenuBlock,
  IPageMetaData,
} from '../interfaces/CommonFields';
import {
  TCommercetoolsProduct,
  TFile,
  TFileMandatory,
  TIConFile,
  TLink,
  TNavigationMenu,
  TNavigationMenuItem,
  TNavigationSubMenuItem,
  TPageMetaData,
} from '../types/CommonFields';

export const FileMandatoryDTO = (file: IFile): TFileMandatory => ({
  contentType: file.content_type,
  fileSize: file.file_size,
  filename: file.filename,
  title: file.title,
  parentUid: file.parent_uid,
  url: file.url,
});

export const FileDTO = (file: IFile | null): TFile => {
  if (!file) {
    return null;
  }

  return FileMandatoryDTO(file);
};

export const IconDTO = (iconFile: IIConFile): TIConFile => {
  if (iconFile.filePath === null) {
    return null;
  }

  return {
    filePath: FileDTO(iconFile.filePath),
    maxWidth: iconFile.maxWidth,
    altText: iconFile.altText,
    redirectLinkUrl: iconFile.redirectLinkUrl,
  };
};

export const LinkDTO = (link: ILink): TLink => ({
  title: link.title,
  href: link.href,
});

export const NavigationMenuDTO = ({ menu_modular_block }: INavigationMenu): TNavigationMenu =>
  menu_modular_block.map(NavigationMenuItemDTO);

export const NavigationMenuItemDTO = ({ menu_block: menuBlock }: INavigationMenuBlock): TNavigationMenuItem => ({
  menuLink: LinkDTO(menuBlock.menu_link),
  openInNewTab: menuBlock.open_in_new_browser_tab,
  hasSubMenu: menuBlock.sub_menu_modular_block.length > 0,
  subMenu: menuBlock.sub_menu_modular_block.map(NavigationMenuItemSubMenuItemDTO),
  uid: menuBlock._metadata.uid,
});

export const NavigationMenuItemSubMenuItemDTO = ({
  sub_menu_block: subMenu,
}: INavigationSubMenuBlock): TNavigationSubMenuItem => ({
  subMenuLink: LinkDTO(subMenu.sub_menu_link),
  openInNewTab: subMenu.open_in_new_browser_tab,
  displayOnlyMobile: subMenu.display_only_mobile,
  uid: subMenu._metadata.uid,
});

export const CtProductIDTO = (ctProductItem: ICommercetoolsProduct): TCommercetoolsProduct | undefined => {
  if (!ctProductItem) {
    return undefined;
  }

  return {
    data: ctProductItem.data,
    type: ctProductItem.type,
  };
};

export const PageMetaDataDTO = (metaData?: IPageMetaData): TPageMetaData => {
  if (!metaData) {
    return {
      metaDescription: '',
      metaImage: null,
      metaTitle: '',
      metaKeywords: '',
    } as TPageMetaData;
  }

  return {
    metaDescription: metaData.meta_description || '',
    metaImage: (metaData.meta_image && FileDTO(metaData.meta_image)) || null,
    metaTitle: metaData.meta_title || '',
    metaKeywords: metaData.meta_keywords || '',
  };
};
