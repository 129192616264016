import classNames from 'classnames/bind';
import { Container } from 'react-bootstrap';
import NameImage from 'components/commercetools-ui/atoms/name-image/name-image';
import { NAME_IMAGE_MAX_WIDTHS } from 'helpers/constants/CommonFieldsConstants';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import styles from './MarketingHeroHeadline.module.scss';
import { IMarketingHeroHeadlineProps } from './types/IMarketingHeroHeadlineProps';
import { tablet } from '../../../../../helpers/utils/screensizes';
import { ETextAlignments } from '../../../../../types/CommonFields';

const cx = classNames.bind(styles);

const justificationClasses = {
  Left: 'translate-middle-y start-0 top-50',
  Center: 'translate-middle start-50 top-50',
  Right: 'translate-middle-y end-0 top-50',
};

const MarketingHeroHeadline = ({
  nameImage,
  nameImageAlt,
  nameImageMaxWidthMobile = NAME_IMAGE_MAX_WIDTHS.Mobile,
  nameImageMaxWidthDesktop = NAME_IMAGE_MAX_WIDTHS.Desktop,
  headline,
  headlineColor,
  textJustification,
  isBackgroundOverlay,
}: IMarketingHeroHeadlineProps) => {
  const screenSize = useMediaQuery()[0];
  const isMobile = screenSize < tablet;

  if (!headline && !nameImage) {
    return false as unknown as JSX.Element;
  }

  const imageMaxWidth = isMobile ? nameImageMaxWidthMobile : nameImageMaxWidthDesktop;

  const textPlacement = justificationClasses[textJustification];

  return (
    <div className={styles.container} data-testid="marketing-hero-headline">
      <Container className={'position-relative'}>
        <div
          className={cx(`${styles.headline} ${styles[headlineColor]} ${textPlacement}`, {
            bgOverlay: isBackgroundOverlay,
            'text-center': textJustification === ETextAlignments.Center,
          })}
          data-testid="marketing-hero-headline-text"
        >
          {nameImage && (
            <NameImage src={nameImage.url} alt={nameImageAlt} testid="headline-name-image" maxWidth={imageMaxWidth} />
          )}
          {headline && <h2 dangerouslySetInnerHTML={{ __html: headline }} data-testid="headline-text" />}
        </div>
      </Container>
    </div>
  );
};

export default MarketingHeroHeadline;
