import { IFootNotes, IFootNotesBlock, IFootNotesReference } from '../interfaces/IFootNotes';
import { TFootNotes } from '../types/TFootNotes';

export const FootNoteBlockDTO = ({ footnote_block }: IFootNotesBlock): TFootNotes =>
  FootNoteReferenceDTO(footnote_block);

export const FootNoteReferenceDTO = ({ footnote_reference }: IFootNotesReference): TFootNotes =>
  FootNoteDTO(footnote_reference[0]);

export const FootNoteDTO = (data: IFootNotes): TFootNotes => ({
  contentTypeUid: 'pdp_global_footnotes',
  title: data.title,
  globalFootNotes: data.global_footnotes,
  uid: data.uid,
});
