import { useState } from 'react';
import classNames from 'classnames';
import { Col, Container, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import AccordionBody from 'react-bootstrap/AccordionBody';
import AccordionItem from 'react-bootstrap/AccordionItem';
import { ImageHelpers } from 'helpers/imageHelpers';
import AccordionHeader from './AccordionHeader';
import { IZ8AccordionProps } from './types/IZ8AccordionProps';
import styles from './Z8Accordion.module.scss';
import useAnimation from '../../../../helpers/hooks/useAnimation';
import { useData } from '../../../../helpers/hooks/useData';
import { IZ8Accordion } from '../../../../interfaces/IZ8Accordion';

const Z8Accordion = ({ data }: IZ8AccordionProps) => {
  const [eventKey, setEventKey] = useState(0);
  const [containerRef, isVisible] = useAnimation();

  const [panel_block, isValid] = useData<IZ8Accordion, IZ8Accordion['panel_block']>(data, {
    return: 'panel_block',
    minLength: 1,
  });

  const handleAccordionItemClick = (idx: number) => {
    if (eventKey !== idx) {
      setEventKey(idx);
    }
  };

  if (isValid) {
    return (
      <Container
        data-testid="container"
        ref={containerRef as any}
        className={classNames(
          'animate',
          'content-block',
          { 'in-view': isVisible },
          { 'd-block': data.display_on_mobile },
          { 'd-none d-md-block': !data.display_on_mobile },
        )}
      >
        <Row className={`g-0`}>
          <Col
            sm={12}
            md={5}
            data-testid="accordion-col"
            className={`flex items-center ${data.media_justification === 'Right' ? 'order-1' : 'order-2'}`}
          >
            <Accordion
              defaultActiveKey="0"
              className={`${styles['accordion-container']} ${
                styles[`media-on-${data.media_justification.toLowerCase()}`]
              }`}
            >
              {panel_block.map((item, idx) => {
                const uuid = item.media_block._metadata.uid;
                return (
                  <AccordionItem
                    eventKey={String(idx)}
                    key={`accordion-item-${idx}-${uuid}`}
                    className={styles['accordion-item']}
                  >
                    <AccordionHeader as="h4" data-testid="headline" onClick={() => handleAccordionItemClick(idx)}>
                      <div dangerouslySetInnerHTML={{ __html: item.media_block.headline || '' }}></div>
                    </AccordionHeader>
                    <AccordionBody className="flex flex-col items-center">
                      {item.media_block.media_group.media_path && (
                        <img
                          data-testid="mobile-image"
                          src={ImageHelpers.modifiedContentStackImageUrl(
                            item.media_block.media_group.media_path.url,
                            '500',
                          )}
                          key={`mobile-image-${idx}-${uuid}`}
                          loading="lazy"
                          alt={item.media_block.media_group.media_alt}
                          title={item.media_block.media_group.media_title}
                          className="d-block d-md-none"
                        />
                      )}
                      <p data-testid="description">{item.media_block.description}</p>
                    </AccordionBody>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </Col>
          <Col
            sm={12}
            md={7}
            data-testid="desktop-image-container"
            className={`d-none d-md-flex items-center justify-center ${styles['accordion-images']} ${
              data.media_justification === 'Right' ? 'order-2' : 'order-1'
            }`}
          >
            {panel_block.map((item, idx) => {
              const uuid = item.media_block._metadata.uid;
              return (
                <div key={`desktop-image-${idx}-${uuid}`}>
                  {item.media_block.media_group.media_path && (
                    <img
                      data-testid="desktop-image"
                      src={ImageHelpers.modifiedContentStackImageUrl(
                        item.media_block.media_group.media_path.url,
                        '1200',
                      )}
                      loading="lazy"
                      alt={item.media_block.media_group.media_alt}
                      title={item.media_block.media_group.media_title}
                      className={eventKey === idx ? 'd-block' : 'd-none'}
                    />
                  )}
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    );
  } else {
    return <></>;
  }
};

export default Z8Accordion;
