import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { kebabCase } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import ColumnContent from './ColumnContent';
import styles from './OneToFourColumn.module.scss';
import useAnimation from '../../../../helpers/hooks/useAnimation';
import { useData } from '../../../../helpers/hooks/useData';
import { TContentGroup, TOneToFourColumn } from '../../../../types/TOneToFourColumn';

export interface Props {
  data: TOneToFourColumn;
  className?: string;
}

const OneToFourColumn = ({ data, className }: Props) => {
  const [content_group_for_one_column, isOneColumn] = useData<TOneToFourColumn, TContentGroup[]>(data, {
    return: 'contentGroup',
    length: 1,
  });

  const [content_group_for_two_column, isTwoColumn] = useData<TOneToFourColumn, TContentGroup[]>(data, {
    return: 'contentGroup',
    length: 2,
  });

  const [content_group_for_three_column, isThreeColumn] = useData<TOneToFourColumn, TContentGroup[]>(data, {
    return: 'contentGroup',
    length: 3,
  });

  const [content_group_for_four_column, isFourColumn] = useData<TOneToFourColumn, TContentGroup[]>(data, {
    return: 'contentGroup',
    length: 4,
  });

  const [content_group, setContentGroup] = useState<TContentGroup[]>([]);
  const [isValid, setIsValid] = useState(false);
  const [containerRef, isVisible] = useAnimation();

  const backgroundColor = useMemo(() => {
    if (!data.backgroundColor) {
      return '';
    }
    return styles[`bg-${kebabCase(data.backgroundColor)}`];
  }, [data.backgroundColor]);

  const textColor = useMemo(() => {
    if (!data.textColor) {
      return '';
    }
    return styles[`text-${kebabCase(data.textColor)}`];
  }, [data.textColor]);

  useEffect(() => {
    if (isOneColumn) {
      setIsValid(isOneColumn);
      setContentGroup(content_group_for_one_column);
    } else if (isTwoColumn) {
      setIsValid(isTwoColumn);
      setContentGroup(content_group_for_two_column);
    } else if (isThreeColumn) {
      setIsValid(isThreeColumn);
      setContentGroup(content_group_for_three_column);
    } else if (isFourColumn) {
      setIsValid(isFourColumn);
      setContentGroup(content_group_for_four_column);
    }
  }, [
    isOneColumn,
    isTwoColumn,
    isThreeColumn,
    isFourColumn,
    content_group_for_one_column,
    content_group_for_two_column,
    content_group_for_three_column,
    content_group_for_four_column,
  ]);

  if (isValid) {
    return (
      <div
        data-testid="one-to-four-column-container"
        ref={containerRef as any}
        className={classNames(
          'animate',
          'content-block',
          'one-to-four-column',
          `grid-${content_group.length}`,
          { 'in-view': isVisible },
          `layer-${content_group[0].layerTextOverMedia}`,
          `image-${content_group[0].imageJustification}`,
          styles['one-to-four-column-container'],
          styles[`grid-${content_group.length}`],
          {
            [styles['bg']]: !!backgroundColor,
          },
          backgroundColor,
          textColor,
        )}
      >
        <div
          data-testid="custom-container"
          className={
            isOneColumn && content_group[0].layerTextOverMedia !== 'adjacent'
              ? 'has-text-overlay'
              : isOneColumn && content_group[0].imageJustification === 'full-width-edge-to-edge'
              ? ''
              : 'container'
          }
        >
          <Row className={isOneColumn && content_group[0].layerTextOverMedia !== 'adjacent' ? 'g-0' : ''}>
            {content_group.map((item, key) => {
              return (
                <Col
                  key={`item-${key}`}
                  sm={12}
                  md={isTwoColumn || isFourColumn ? 6 : isThreeColumn ? 4 : 12}
                  lg={isTwoColumn ? 6 : isThreeColumn ? 4 : isFourColumn ? 3 : 12}
                  className={className}
                >
                  <ColumnContent data={item} length={content_group.length} />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default OneToFourColumn;
