import {
  IRichTextEditorNoSposto,
  IRichTextEditorNoSpostoBlock,
  IRichTextEditorNoSpostoReference,
} from 'interfaces/IRichTextEditorNoSposto';
import { TRichTextEditorNoSposto } from 'types/TRichTextEditorNoSposto';

export const RichTextEditorNoSpostoBlockDTO = ({
  rich_text_no_sposto_block,
}: IRichTextEditorNoSpostoBlock): TRichTextEditorNoSposto =>
  RichTextEditorNoSpostoReferenceDTO(rich_text_no_sposto_block);

export const RichTextEditorNoSpostoReferenceDTO = ({
  rich_text_no_sposto_reference,
}: IRichTextEditorNoSpostoReference): TRichTextEditorNoSposto =>
  RichTextEditorNoSpostoDTO(rich_text_no_sposto_reference[0]);

export const RichTextEditorNoSpostoDTO = (data: IRichTextEditorNoSposto): TRichTextEditorNoSposto => {
  const expirationDate = data?.expiration_row ? new Date(data?.expiration_row) : null;

  return {
    contentTypeUid: 'rich_text_editor_no_sposto',
    expirationRow: expirationDate,
    isExpired: expirationDate !== null && expirationDate.valueOf() - new Date().valueOf() < 0,
    content: data?.content,
    uid: data?.uid,
  };
};
