import React from 'react';
import Link from 'next/link';
import styles from './SkipContent.module.scss';

const SkipContent = () => {
  return (
    <>
      <div className={styles['skip-link-container']}>
        <Link href="#nkn-main-content" tabIndex={0}>
          <a
            id="skipToBody"
            className={styles['skip-link']}
            onClick={() => document.getElementById('nkn-main-content')?.focus()}
          >
            Skip to Main Content
          </a>
        </Link>
        <Link href="#nkn-footer" tabIndex={0}>
          <a
            id="skipToFooter"
            className={styles['skip-link']}
            onClick={() => document.getElementById('nkn-footer')?.focus()}
          >
            Additional Site Navigation
          </a>
        </Link>
      </div>
    </>
  );
};

export default SkipContent;
