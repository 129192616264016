import { TFAQs } from '../../../../types/TFAQPanel';

export const structuredSchemaOrgData = (data: TFAQs | null) => {
  const mainEntityData = () => {
    const mainEntityDataArr = [];
    if (data && data.panelGroup.length > 0) {
      for (let i = 0; i < data.panelGroup.length; i++) {
        const panel = data.panelGroup[i];
        if (panel.contentGroup.length > 0) {
          for (let j = 0; j < panel.contentGroup.length; j++) {
            const content = panel.contentGroup[j];
            const entity = {
              '@type': 'Question',
              name: content.contentTitle ? content.contentTitle : panel.panelTitle,
              acceptedAnswer: {
                '@type': 'Answer',
                text: content.contentDescription
                  ? content.contentDescription
                  : content.linkCopyText
                  ? `<a href=${content.linkUrl}>${content.linkCopyText}</a>`
                  : '',
              },
            };
            mainEntityDataArr.push(entity);
          }
        }
      }
    }
    return mainEntityDataArr;
  };

  const schemaOrgData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: mainEntityData(),
  };

  return JSON.stringify(schemaOrgData);
};
