import { TInlineSlider } from 'types/TInlineSlider';
import { TNikonProduct } from 'types/TNikonProduct';
import { TRichTextEditorNoSposto } from 'types/TRichTextEditorNoSposto';
import AboveProductList from './AboveProductList';
import ButtonRow from './ButtonRow';
import Definitions from './Definitions';
import DisclaimersBlock from './DisclaimersBlock';
import FAQPanel from './FAQPanel';
import FeatureBand from './FeatureBand';
import FootNotes from './FootNotes';
import FreeEntry from './FreeEntry';
import FullWidthImage from './FullWidthImage';
import FullWidthText from './FullWidthText';
import FullWidthVideo from './FullWidthVideo';
import Hotspots from './Hotspots';
import ImageCarousel from './ImageCarousel';
import InlineSlider from './InlineSlider/InlineSlider';
import LandingSubNav from './LandingSubNav';
import MarketingHero from './MarketingHero';
import NavigationCarousel from './NavigationCarousel';
import OneToFourColumn from './OneToFourColumn';
import { OneVideo } from './OneVideo';
import PlainFeatures from './PlainFeatures';
import ProductViewExplorer from './ProductViewExplorer/ProductViewExplorer';
import QuickLinks from './QuickLinks';
import RichTextEditorNoSposto from './RichTextEditorNoSposto/RichTextEditorNoSposto';
import TwoColumnRow from './TwoColumnImage';
import { IContentBlocksProps } from './Types/IContentBlocksProps';
import Z8Accordion from './Z8Accordion';
import { EContentBlocksUid } from '../../../helpers/constants/EContentBlocksUid';
import { IZ8Accordion } from '../../../interfaces/IZ8Accordion';
import { TAboveProductList } from '../../../types/TAboveProductList';
import { TButtonRow } from '../../../types/TButtonRow';
import { TCarousel } from '../../../types/TCarousel';
import { TDefinition } from '../../../types/TDefinition';
import { TDisclaimers } from '../../../types/TDisclaimers';
import { TFAQs } from '../../../types/TFAQPanel';
import { TFeatureBand } from '../../../types/TFeatureBand';
import { TFootNotes } from '../../../types/TFootNotes';
import { TFreeEntry } from '../../../types/TFreeEntry';
import { TFullWidthImage } from '../../../types/TFullWidthImage';
import { TFullWidthText } from '../../../types/TFullWidthText';
import { TFullWidthVideo } from '../../../types/TFullWidthVideo';
import { THotspots } from '../../../types/THotspots';
import { TLandingSubNav } from '../../../types/TLandingSubNav';
import { TLNEArticleCategoryBlock } from '../../../types/TLNEArticleCategoryBlock';
import { TLNEFeaturedArticlesBlock } from '../../../types/TLNEFeaturedArticlesBlock';
import { TLNEFeaturedCollectionsBlock } from '../../../types/TLNEFeaturedCollectionsBlock';
import { TMarketingHero } from '../../../types/TMarketingHero';
import { TNavigationCarousel } from '../../../types/TNavigationCarousel';
import { TOneToFourColumn } from '../../../types/TOneToFourColumn';
import { TOneVideo } from '../../../types/TOneVideo';
import { TPlainFeatures } from '../../../types/TPlainFeatures';
import { TQuickLinks } from '../../../types/TQuickLinks';
import { TTwoColumnRow } from '../../../types/TTwoColumnRow';
import LNEArticleCategoryBlock from '../LearnAndExplore/LNEArticleCategoryBlock';
import LNEFeaturedArticlesBlock from '../LearnAndExplore/LNEFeaturedArticlesBlock';
import LNEFeaturedCollectionsBlock from '../LearnAndExplore/LNEFeaturedCollectionsBlock/LNEFeaturedCollectionsBlock';

const ContentBlocks = ({
  data,
  product,
  tipsAndTechniquesCount,
  ideasAndInspirationCount,
  productsAndInnovationCount,
}: IContentBlocksProps) => {
  const isArchived = Boolean(product?.isArchived);

  if (!Boolean(data.length)) {
    return false as unknown as JSX.Element;
  }

  const filterLensesProduct = (product: TNikonProduct | undefined) => {
    if (product && Boolean(product?.categories?.length)) {
      const filterCategory = product.categories.filter((category) =>
        category?.categoryKey?.toUpperCase().includes('LENS'),
      );
      return Boolean(filterCategory?.length);
    }

    return false;
  };

  return (
    <div data-testid="content-blocks" className="content-blocks">
      {data.map((block, index) => {
        const key = `${block?.uid}-${index}`;

        switch (block?.contentTypeUid) {
          case EContentBlocksUid.ABOVE_PRODUCT_LIST:
            return <AboveProductList data={block as TAboveProductList} key={key} />;

          case EContentBlocksUid.BUTTON_ROW:
            return isArchived ? null : <ButtonRow data={block as unknown as TButtonRow} key={key} />;

          case EContentBlocksUid.DEFINITIONS:
            return isArchived ? (
              filterLensesProduct(product as TNikonProduct) ? (
                <Definitions data={block as unknown as TDefinition} key={key} />
              ) : null
            ) : (
              <Definitions data={block as unknown as TDefinition} key={key} />
            );

          case EContentBlocksUid.DISCLAIMERS:
            return <DisclaimersBlock disclaimer={block as unknown as TDisclaimers} key={key} />;

          case EContentBlocksUid.FAQ:
            return isArchived ? null : <FAQPanel faqs={block as unknown as TFAQs} key={key} />;

          case EContentBlocksUid.FEATURE_BAND:
            return isArchived ? null : <FeatureBand data={block as unknown as TFeatureBand} key={key} />;

          case EContentBlocksUid.FREE_ENTRY:
            return isArchived ? null : <FreeEntry data={block as unknown as TFreeEntry} key={key} />;

          case EContentBlocksUid.RICH_TEXT_EDITOR_NO_SPOSTO:
            return <RichTextEditorNoSposto data={block as unknown as TRichTextEditorNoSposto} key={key} />;

          case EContentBlocksUid.FULL_WIDTH_IMAGE:
            return isArchived ? null : <FullWidthImage data={block as unknown as TFullWidthImage} key={key} />;

          case EContentBlocksUid.FULL_WIDTH_TEXT:
            return isArchived ? null : <FullWidthText data={block as unknown as TFullWidthText} key={key} />;

          case EContentBlocksUid.FULL_WIDTH_VIDEO:
            return isArchived ? null : <FullWidthVideo data={block as unknown as TFullWidthVideo} key={key} />;

          case EContentBlocksUid.HOTSPOTS:
            return isArchived ? null : <Hotspots data={block as unknown as THotspots} key={key} />;

          case EContentBlocksUid.IMAGE_CAROUSEL:
            return isArchived ? null : <ImageCarousel data={block as unknown as TCarousel} key={key} />;

          case EContentBlocksUid.INLINE_SLIDER:
            return isArchived ? null : <InlineSlider data={block as unknown as TInlineSlider} key={key} />;

          case EContentBlocksUid.LANDING_SUB_NAV:
            return isArchived ? null : <LandingSubNav data={block as unknown as TLandingSubNav} key={key} />;

          case EContentBlocksUid.LNE_ARTICLE_CATEGORY_BLOCK:
            return isArchived ? null : (
              <LNEArticleCategoryBlock
                data={block as unknown as TLNEArticleCategoryBlock}
                ideasAndInspirationCount={ideasAndInspirationCount}
                productsAndInnovationCount={productsAndInnovationCount}
                tipsAndTechniquesCount={tipsAndTechniquesCount}
                key={key}
              />
            );

          case EContentBlocksUid.LNE_FEATURED_ARTICLES_BLOCK:
            return isArchived ? null : (
              <LNEFeaturedArticlesBlock data={block as unknown as TLNEFeaturedArticlesBlock} key={key} />
            );

          case EContentBlocksUid.LNE_FEATURED_COLLECTIONS_BLOCK:
            return isArchived ? null : (
              <LNEFeaturedCollectionsBlock data={block as unknown as TLNEFeaturedCollectionsBlock} key={key} />
            );

          case EContentBlocksUid.MARKETING_HERO:
            return isArchived ? null : <MarketingHero data={block as unknown as TMarketingHero} key={key} />;

          case EContentBlocksUid.NAVIGATION_CAROUSEL:
            return isArchived ? null : <NavigationCarousel data={block as unknown as TNavigationCarousel} key={key} />;

          case EContentBlocksUid.ONE_TO_FOUR_COLUMNS:
            return isArchived ? null : <OneToFourColumn data={block as unknown as TOneToFourColumn} key={key} />;

          case EContentBlocksUid.ONE_VIDEO:
            return isArchived ? null : <OneVideo {...(block as TOneVideo)} key={key} />;

          case EContentBlocksUid.PLAIN_FEATURES:
            return isArchived ? null : <PlainFeatures plainFeatures={block as unknown as TPlainFeatures} key={key} />;

          case EContentBlocksUid.PRODUCT_VIEW_EXPLORER:
            return <ProductViewExplorer data={block} product={product} key={key} />;

          case EContentBlocksUid.QUICK_LINKS:
            return isArchived ? null : <QuickLinks {...(block as TQuickLinks)} key={key} />;

          case EContentBlocksUid.TWO_COLUMNS_ROW:
            return isArchived ? null : <TwoColumnRow data={block as unknown as TTwoColumnRow} key={key} />;

          case EContentBlocksUid.Z8_ACCORDION:
            return isArchived ? null : <Z8Accordion data={block as IZ8Accordion} key={key} />;

          case EContentBlocksUid.FOOT_NOTES:
            return isArchived ? null : <FootNotes data={block as unknown as TFootNotes} />;

          default:
            return null;
        }
      })}
    </div>
  );
};

export default ContentBlocks;
