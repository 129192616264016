import {
  IProductViewExplorer,
  IProductViewExplorerBlock,
  IProductViewExplorerReference,
} from '../interfaces/IProductViewExplorer';

export const ProductViewExplorerBlockDTO = ({
  product_view_explorer_block,
}: IProductViewExplorerBlock): IProductViewExplorer => ProductViewExplorerReferenceDTO(product_view_explorer_block);

export const ProductViewExplorerReferenceDTO = ({
  product_view_explorer_reference,
}: IProductViewExplorerReference): IProductViewExplorer => ProductViewExplorerDTO(product_view_explorer_reference[0]);

// @todo refactor this when create a TProductViewExplorer and refactor the component
export const ProductViewExplorerDTO = (productViewExplorer: IProductViewExplorer): IProductViewExplorer => ({
  contentTypeUid: 'product_view_explorer_block',
  ...productViewExplorer,
});
