import React from 'react';
import styles from './FullWidthTextLink.module.scss';
import { ELinkTargets } from './types/ELinkTargets';
import { ILinkProps } from './types/ILinkProps';
import useMediaQuery from '../../../../../helpers/hooks/useMediaQuery';
import { tablet } from '../../../../../helpers/utils/screensizes';

const FullWidthTextLink = ({ data }: ILinkProps) => {
  const { linkText, linkUrl, openIn, visibleOnDesktopOnly, uid } = data;
  const [isBiggerThanTabletSize] = useMediaQuery(tablet);
  const linkTarget = ELinkTargets[openIn as keyof typeof ELinkTargets];

  const handleOnClick = (event: React.MouseEvent) => {
    if (linkTarget === '_popup') {
      event.preventDefault();
      window.open(linkUrl, undefined, linkTarget);
    }
  };

  if ((visibleOnDesktopOnly && !isBiggerThanTabletSize) || !linkText || !linkUrl) {
    return null;
  }

  return (
    <li className={styles.linkLiContainer}>
      <a
        href={linkUrl}
        rel="noreferrer"
        target={linkTarget}
        className={styles.linkBtn}
        onClick={handleOnClick}
        data-testid={`link-btn-${data.uid}`}
      >
        {linkText}
        <i className={styles.linkIcon} data-testid={`link-icon-${uid}`} />
      </a>
    </li>
  );
};

export default FullWidthTextLink;
