import { useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames/bind';
import { Row, Col } from 'react-bootstrap';
import styles from './FooterColumnGroup.module.scss';
import { IFooterColumnGroupProps } from './types/IFooterColumnGroupProps';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import { tablet } from '../../../../helpers/utils/screensizes';
import OneTrustSnippet from '../../../headless/OneTrustSnippet';

const cx = classNames.bind(styles);

const FooterColumnGroup = ({ columnGroupItems }: IFooterColumnGroupProps) => {
  const [isBiggerThanTablet] = useMediaQuery(tablet);
  const isMobile = !isBiggerThanTablet;
  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>(
    columnGroupItems.reduce((acc, { uid }) => {
      return {
        ...acc,
        [uid]: false,
      };
    }, {}),
  );

  const handleOnMenuToggleNormal = (uid: string) => {
    setOpenMenus((prevState) => {
      return {
        ...prevState,
        [uid]: !prevState[uid],
      };
    });
  };

  if (!Boolean(columnGroupItems.length)) {
    return false as unknown as JSX.Element;
  }

  return (
    <Row className={styles.footerLinks} data-testid="footer-column-group">
      {columnGroupItems.map(({ columnTitle, subMenu, uid }, colIndex) => (
        <Col md={12 / columnGroupItems.length} key={uid}>
          <h3
            className={'d-flex d-md-block align-items-center w-100'}
            role={isMobile ? 'button' : 'heading'}
            tabIndex={0}
            onClick={() => handleOnMenuToggleNormal(uid)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleOnMenuToggleNormal(uid);
              }
            }}
            data-testid={`mobile-dropdown-button-${colIndex}`}
          >
            {columnTitle}
            <i
              className={cx('bi d-md-none chevron-icon bi-chevron-down ml-auto', {
                open: openMenus[uid],
              })}
            />
          </h3>

          <ul
            className={cx('d-md-block', {
              'd-block': openMenus[uid],
              'd-none': !openMenus[uid],
            })}
            data-testid={`test-menu-${colIndex}`}
          >
            {subMenu.map(({ subMenuTitle, subMenuUrl, cssClass, visibleOnDesktopOnly, openInNewWindow, uid }) => (
              <li
                className={cx({
                  'd-none d-md-block': visibleOnDesktopOnly,
                })}
                key={uid}
              >
                {cssClass === 'ot-sdk-show-settings' ? (
                  <OneTrustSnippet />
                ) : (
                  <>
                    {openInNewWindow && (
                      <a href={subMenuUrl} className={cssClass} target="_blank" rel="noreferrer">
                        {subMenuTitle}
                      </a>
                    )}

                    {!openInNewWindow && (
                      <Link href={subMenuUrl}>
                        <a className={cssClass}>{subMenuTitle}</a>
                      </Link>
                    )}
                  </>
                )}
              </li>
            ))}
          </ul>
        </Col>
      ))}
    </Row>
  );
};

export default FooterColumnGroup;
