import {
  IInlineSlider,
  IInlineSliderBlock,
  IInlineSliderImage,
  IInlineSliderReference,
} from 'interfaces/IInlineSliderBlock';
import { TInlineSlider, TInlineSliderImage } from 'types/TInlineSlider';
import { FileDTO, FileMandatoryDTO } from './CommonFieldsDTO';

export const InlineSliderBlockDTO = ({ in_line_slider_block }: IInlineSliderBlock) =>
  InlineSliderReferenceDTO(in_line_slider_block);

export const InlineSliderReferenceDTO = ({ in_line_slider_reference }: IInlineSliderReference) =>
  InlineSliderPropsDTO(in_line_slider_reference[0]);

export const InlineSliderPropsDTO = (inlineSlider: IInlineSlider): TInlineSlider => ({
  contentTypeUid: 'in_line_slider',
  description: inlineSlider.description,
  displayOnMobile: inlineSlider.display_on_mobile,
  fileName: inlineSlider.file_name,
  headline: inlineSlider.headline,
  iconAltText: inlineSlider.icon_alt_text,
  imageGroup: inlineSlider?.image_group?.map(ImageDTO),
  iconImage: FileDTO(inlineSlider.icon_image),
  uid: inlineSlider.uid,
});

export const ImageDTO = (images: IInlineSliderImage): TInlineSliderImage => ({
  imageFile: FileMandatoryDTO(images.image_file),
  imageAltText: images.image_alt_text,
  imageTitleText: images.image_title_text,
  imageOneLiner: images.image_one_liner,
  uid: images._metadata.uid,
});
