import { FileDTO } from './CommonFieldsDTO';
import { IDefinition, IDefinitionBlock, IDefinitionItem, IDefinitionReference } from '../interfaces/IDefinition';
import { TDefinition, TDefinitionItem } from '../types/TDefinition';

export const DefinitionsBlockDTO = ({ definitions_block }: IDefinitionBlock): TDefinition =>
  DefinitionsReferenceDTO(definitions_block);

export const DefinitionsReferenceDTO = ({ definitions_reference }: IDefinitionReference): TDefinition =>
  DefinitionsProps(definitions_reference[0]);

export const DefinitionsProps = (definitions: IDefinition): TDefinition => ({
  contentTypeUid: 'definitions',
  fileName: definitions.file_name,
  headline: definitions.headline,
  definitionItems: definitions.definitions.map(DefinitionItemDTO),
  uid: definitions.uid,
});

export const DefinitionItemDTO = (definition: IDefinitionItem): TDefinitionItem => ({
  definitionIcon: FileDTO(definition.definition_icon),
  definitionHeadline: definition.definition_headline,
  definitionDescription: definition.definition_description,
  uid: definition._metadata.uid,
});
