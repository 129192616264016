import { ContentBlocksDTO } from './ContentBlocksDTO';
import { FooterDTO } from './FooterDTO';
import { HeaderDTO } from './HeaderDTO';
import { PromoBannerDTO } from './PromoBannerDTO';
import { IErrorPage } from '../interfaces/IErrorPage';
import { TErrorPage } from '../types/TErrorPage';

export const ErrorPageDTO = (data: IErrorPage): TErrorPage => ({
  contentTypeUid: 'error_page',
  contentBlocks: ContentBlocksDTO(data.content_blocks),
  footer: data?.footer_reference?.[0] ? FooterDTO(data.footer_reference[0]) : undefined,
  header: data?.header_reference?.[0] ? HeaderDTO(data.header_reference[0]) : undefined,
  promoBanner: PromoBannerDTO(data.promo_banner_reference[0]),
  uid: data.uid,
});
