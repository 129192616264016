import { IButtonRow, IButtonRowBlock, IButtonRowButtonLink, IButtonRowReference } from '../interfaces/IButtonRow';
import { TButtonRow, TButtonRowButtonLink } from '../types/TButtonRow';

export const ButtonRowBlockDTO = ({ button_row_block }: IButtonRowBlock): TButtonRow => {
  return ButtonRowReferenceDTO(button_row_block);
};

export const ButtonRowReferenceDTO = ({ button_row_reference }: IButtonRowReference): TButtonRow => {
  return ButtonRowDTO(button_row_reference[0]);
};

export const ButtonRowDTO = (buttonRow: IButtonRow): TButtonRow => ({
  contentTypeUid: 'button_row_block',
  headline: buttonRow.headline,
  description: buttonRow.description,
  expirationRow: buttonRow.expiration_row ? new Date(buttonRow.expiration_row) : null,
  buttonLinksGroup: buttonRow.button_links_group.map(ButtonLinksDTO),
  uid: buttonRow.uid,
});

const ButtonLinksDTO = (buttonLink: IButtonRowButtonLink): TButtonRowButtonLink => ({
  buttonLinkText: buttonLink.button_link_text,
  linkUrl: buttonLink.link_url,
  buttonColor: buttonLink.button_color,
  openInNewWindow: buttonLink.open_in_new_window,
  uid: buttonLink._metadata.uid,
});
