import React from 'react';
import classNames from 'classnames/bind';
import { Button, Nav } from 'react-bootstrap';
import { IMAGE_SOURCE } from 'helpers/constants/imageSources';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { ImageHelpers } from 'helpers/imageHelpers';
import { ICartFlyoutProps } from './types/ICartFlyoutProps';
import { getAppropriateSkuName } from '../../../../helpers/productHelpers';
import styles from '../UserBarBlock/UserBarBlock.module.scss';

const cx = classNames.bind(styles);

const CartFlyout = ({ isEmpty, totalItems, data, locale, cartRedirectLink }: ICartFlyoutProps) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const imageUnavailable = IMAGE_SOURCE.getUnavailableImage(locale);

  return (
    <section id="mini-cart-flyout" className={cx({ flyout: true, cart: true })} data-testid="mini-cart-flyout">
      <div
        className={cx('content', {
          'empty-content': isEmpty,
        })}
      >
        {isEmpty && (
          <>
            <div className={`${styles.title} h3`} data-testid="empty-cart-title">
              <span className={styles.num}>{totalItems}</span>
              <span className="sr-only" data-single-suffix="item" data-plural-suffix="items"></span>
            </div>
            <p className={styles.instruction} data-testid="empty-cart-instruction">
              {formatCartMessage({ id: 'cartFlyer.empty', defaultMessage: 'Your Cart is Empty' })}
            </p>
          </>
        )}
        {!isEmpty && (
          <>
            <ul data-quantity="Quantity:" className={styles.cartItems} data-testid="cart-items-list">
              {data?.lineItems.slice(0, 3).map((lineItem, index) => {
                const imageIndex = Math.max(
                  lineItem.variant?.metaImages?.findIndex((image) => image === 'PLP') ?? 0,
                  0,
                );
                return (
                  <li key={`${lineItem.lineItemId}_${index}`}>
                    <Nav.Link href={lineItem._url}>
                      <img
                        src={
                          lineItem.variant?.images?.[imageIndex]
                            ? ImageHelpers.modifiedCTImageUrl(lineItem.variant.images[imageIndex], 'small')
                            : imageUnavailable
                        }
                        loading="lazy"
                        onError={({ currentTarget }) => {
                          currentTarget.src = imageUnavailable;
                        }}
                        alt={`${lineItem.name}_image`}
                        data-testid={`cart-item-image-${lineItem.lineItemId}-${index}`}
                      />
                      <label>
                        <strong>{getAppropriateSkuName(lineItem.name || '', lineItem.variant)}</strong>
                        <span>Quantity: {lineItem.count}</span>
                      </label>
                    </Nav.Link>
                  </li>
                );
              })}
            </ul>
            {data?.lineItems && data.lineItems.length > 3 && (
              <Nav.Link href={cartRedirectLink} className={styles.seeAllProducts} data-testid="see-all-products-text">
                {formatCartMessage({
                  id: 'cartFlyer.sellAllProducts',
                  defaultMessage: ' See all {totalItems} products in your cart',
                  values: { totalItems: totalItems },
                })}
              </Nav.Link>
            )}

            <div data-subtotal-prefix="Subtotal" className={`${styles.subtotal} h3`} data-testid="subtotal">
              <span>{formatCartMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })} </span>
              <span>{CurrencyHelpers.formatForCurrency(data?.sum ? data.sum : 0, locale)}</span>
            </div>
            <Button
              href={cartRedirectLink}
              className="nk-btn-rounded btn-black"
              data-testid={`view-cart-button-${data?.cartId}`}
            >
              {formatCartMessage({ id: 'cartFlyer.viewCart', defaultMessage: 'View Cart' })}
            </Button>
          </>
        )}
      </div>
    </section>
  );
};

export default CartFlyout;
