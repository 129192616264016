import React from 'react';
import Link from 'next/link';
import { Badge } from 'react-bootstrap';
import styles from './LNEFeaturedArticlesBlock.module.scss';
import { ILNEFeaturedArticleProps } from './types/ILNEFeaturedArticlesBlockProps';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import { tablet } from '../../../../helpers/utils/screensizes';
import { getLNEArticleUrl, getLNECategorySlug } from '../utils';

const LNEFeaturedArticle = ({ data, index }: ILNEFeaturedArticleProps) => {
  const { formatMessage } = useFormat({ name: 'learn-and-explore' });
  const screenSize = useMediaQuery()[0];

  if (!data) {
    return <></>;
  }

  const reference = data?.articleReference?.[0];
  const article = reference?.articleFieldsGroup;
  const category = article?.articleCategory;
  const url = getLNEArticleUrl(category, reference?.slug);
  const newUntilDate = article?.dateSelectors?.newUntilDate || '';
  const isNew = newUntilDate !== '' && new Date(newUntilDate).getTime() - new Date().getTime() > 0;
  const isMobile = screenSize < tablet;
  let imageUrl = '';
  let imageAlt = '';

  if (index === 0) {
    imageUrl = article?.images?.representativeImage?.url || '';
    imageAlt = article?.images?.representativeImage?.title || '';
  } else if (!isMobile) {
    imageUrl =
      article?.images?.customRectangleRepresentativeImage?.url ||
      article?.images?.rectangleRepresentativeImage?.url ||
      '';
    imageAlt =
      article?.images?.customRectangleRepresentativeImage?.title ||
      article?.images?.rectangleRepresentativeImage?.title ||
      '';
  }

  return (
    <Link href={url}>
      <a className={styles['featured-article-block']} data-art-id={reference?.code}>
        {imageUrl && (
          <div className={styles.image}>
            <img src={imageUrl} loading="lazy" alt={imageAlt} />
            <span className={styles[article?.articleClassification?.toLowerCase() || '']}>
              {formatMessage({
                id: article?.articleClassification?.toLowerCase() || '',
                defaultMessage: article?.articleClassification,
              })}
            </span>
          </div>
        )}
        <p className={styles.category}>
          {isNew && (
            <Badge className={styles['new-badge']}>{formatMessage({ id: 'new', defaultMessage: 'New' })}</Badge>
          )}
          <span className={styles['category-text']}>
            {formatMessage({
              id: getLNECategorySlug(category),
              defaultMessage: category,
            })}
          </span>
        </p>
        <h3>{article?.articleTitle}</h3>
        <p className={styles.subtitle}>{article?.articleSubtitle}</p>
        {index === 0 && <p className={styles.summary}>{article?.articleSummary}</p>}
      </a>
    </Link>
  );
};

export default LNEFeaturedArticle;
