import React from 'react';
import { Button } from 'react-bootstrap';
import { IVideoTextProps } from './types/IVideoTextProps';
import styles from './VideoText.module.scss';

const VideoText = ({ headline, description, videoLinkGroup }: IVideoTextProps) => {
  return (
    <div className={`${styles['text-container']} display-table container`}>
      <div className="display-table-cell">
        <div className="content padding-wide col-xs-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
          {headline && <h2 dangerouslySetInnerHTML={{ __html: headline }}></h2>}

          {description && <div className={styles.description}>{description}</div>}

          {videoLinkGroup && (
            <Button
              href={videoLinkGroup.redirectLinkUrl}
              target={videoLinkGroup.openNewWindow ? '_blank' : '_self'}
              className={`${styles['video-button']} nk-btn-rounded nk-btn-primary`}
            >
              {videoLinkGroup.redirectLinkText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoText;
