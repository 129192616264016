import { ImageHelpers } from 'helpers/imageHelpers';
import styles from './FooterSocial.module.scss';
import { IFooterSocialProps } from './types/IFooterSocialProps';
import { useFormat } from '../../../../helpers/hooks/useFormat';

const FooterSocial = ({ socialMediaLinks }: IFooterSocialProps) => {
  const { formatMessage } = useFormat({ name: 'common' });

  if (!socialMediaLinks.length) {
    return false as unknown as JSX.Element;
  }

  return (
    <div className={styles.socialNav} data-testid="footer-social">
      {socialMediaLinks.map(({ name, iconImage, socialMediaUrl, uid }) => {
        return (
          <a
            key={uid}
            href={socialMediaUrl}
            target="_blank"
            rel="noreferrer"
            aria-label={formatMessage({ id: 'footer.socialLabel', values: { socialPlatform: name } })}
            data-testid={`social-media-link-${name}`}
          >
            {iconImage && (
              <img
                src={ImageHelpers.modifiedContentStackImageUrl(iconImage.url, '30')}
                width={30}
                height={30}
                alt={name}
                data-testid={`social-media-link-${name}-image`}
              />
            )}
            {!iconImage && <span>{name}</span>}
          </a>
        );
      })}
    </div>
  );
};

export default FooterSocial;
