import styles from './DisclaimerSpacerBlock.module.scss';
import IDisclaimerSpacerBlock from './types/IDisclaimerSpacerBlock';

const DisclaimerSpacerBlock = ({ spacerBlock }: IDisclaimerSpacerBlock) => {
  if (!spacerBlock.enableSpace) {
    return null;
  }

  return <hr className={styles.spacer} />;
};
export default DisclaimerSpacerBlock;
