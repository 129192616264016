export const searchMenus = {
  RESULT_OVERVIEW: 'Results Overview',
  PRODUCT: 'Products',
  ACCESSORIES: 'Accessories',
  PRESS_ROOM: 'Press Room',
  Parts: 'Parts',
  LEARN_EXPLORE: 'Learn & Explore',
  OTHER: 'Other',
  ONLINE_COURSE: 'Online Course',
  PDF: 'PDF',
  SUPPORT: 'Support',
};
export const fullFilters = {
  'Results Overview': 'Overview',
  Products: 'Products',
  Accessories: 'Accessories',
  Parts: 'Parts',
  Support: 'Support',
  PDF: 'PDF',
  'Press Room': 'Press',
  'Learn & Explore': 'LE',
  Other: 'Other',
};

export const fullReverseFilters = {
  Overview: 'Results Overview',
  Products: 'Products',
  Accessories: 'Accessories',
  Parts: 'Parts',
  Support: 'Support',
  PDF: 'PDF',
  Press: 'Press Room',
  LE: 'Learn & Explore',
  Other: 'Other',
};
