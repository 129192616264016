import { IFreeEntryProps } from './types/IFreeEntryProps';

const FreeEntry = ({ data: { isExpired, content, uid } }: IFreeEntryProps) => {
  if (isExpired || !Boolean(content?.length)) {
    return false as unknown as JSX.Element;
  }

  return (
    <div data-testid="free-entry" className="content-block">
      {content.map((block, index) => (
        <div data-testid="content-text" key={`${uid}-${index}`} dangerouslySetInnerHTML={{ __html: block }} />
      ))}
    </div>
  );
};

export default FreeEntry;
