import { useCallback, useEffect, useState } from 'react';
import { Product } from 'types/product';
import { useProduct } from 'frontastic';

const useProductByKey = (key: string) => {
  const [product, setProduct] = useState<Product>();

  const { getProductByKey } = useProduct();

  const fetchProduct = useCallback(async () => {
    if (!key) return;

    const response = await getProductByKey(key);
    setProduct(response);
  }, [key, getProductByKey]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  return product;
};

export default useProductByKey;
