import { IZ8Accordion, IZ8AccordionBlock, IZ8AccordionReference } from '../interfaces/IZ8Accordion';

export const Z8AccordionBlockDTO = ({ z8_accordion_block }: IZ8AccordionBlock): IZ8Accordion =>
  Z8AccordionReferenceDTO(z8_accordion_block);

export const Z8AccordionReferenceDTO = ({ z8_accordion_reference }: IZ8AccordionReference): IZ8Accordion =>
  Z8AccordionDTO(z8_accordion_reference[0]);

//@todo refactor this when create the TZ8Accordion and refactor the component
export const Z8AccordionDTO = (z8_accordion: IZ8Accordion): IZ8Accordion => ({
  contentTypeUid: 'z8_accordion',
  ...z8_accordion,
});
