import React from 'react';
import { ImageHelpers } from 'helpers/imageHelpers';

interface Props {
  isLazy?: boolean;
  className?: string;
  src: string;
  alt?: string;
  maxWidth?: number;
  testid: string;
}

const defaultNameImageMaxWidth = '280px';

const NameImage: React.FC<React.PropsWithChildren<Props>> = ({
  isLazy = false,
  className,
  src,
  alt,
  maxWidth,
  testid,
}) => {
  const imageSource = ImageHelpers.modifiedContentStackImageUrl(src, maxWidth + 'px');
  return (
    <img
      loading={isLazy ? 'lazy' : 'eager'}
      src={imageSource}
      alt={alt}
      style={{
        width: '100%',
        maxWidth: maxWidth + 'px' || defaultNameImageMaxWidth,
      }}
      data-testid={testid}
      className={className}
    />
  );
};

export default NameImage;
