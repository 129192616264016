import Link from 'next/link';
import { IVideoGroupProps } from './types/IVideoGroupProps';
import styles from './VideoGroup.module.scss';
import { VIDEO_LOCATIONS } from '../../../../../../helpers/constants/videoLocations';
import VideoIframe from '../../../../atoms/VideoIframe';
import VideoUploaded from '../../../../atoms/VideoUploaded';

const VideoGroup = ({ videoGroup, seoData }: IVideoGroupProps) => {
  return (
    <div className="video-group">
      <div className={styles['video-container']}>
        {videoGroup.videoLocation === 'Contentstack' && (
          <VideoUploaded
            autoplay={videoGroup.autoplay}
            contentType={videoGroup.videoFile?.contentType || ''}
            loop={videoGroup.loop}
            posterImage={videoGroup.posterImage}
            src={videoGroup.videoFile?.url || ''}
            seoData={seoData}
          />
        )}
        {(videoGroup.videoLocation === VIDEO_LOCATIONS.YOUTUBE ||
          videoGroup.videoLocation === VIDEO_LOCATIONS.YOUTUBE_PLAYLIST ||
          videoGroup.videoLocation === VIDEO_LOCATIONS.VIMEO) && (
          <VideoIframe
            videoLocation={videoGroup.videoLocation}
            videoRefId={videoGroup.videoRefId}
            autoplay={videoGroup.autoplay}
            controls={videoGroup.controls}
            loop={videoGroup.loop}
            recommendedYouTubeVideos={videoGroup.recommendedYouTubeVideos}
            seoData={seoData}
            data-testid="platform-video"
          />
        )}
      </div>
      <Link href={videoGroup.videoCopyInfoUrl}>
        <a className={styles['video-info']} target={videoGroup.openNewWindow ? '_blank' : '_self'}>
          {videoGroup.videoCopyInfoText}
        </a>
      </Link>
    </div>
  );
};

export default VideoGroup;
