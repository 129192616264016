import classNames from 'classnames';
import { Col, Container, Row } from 'react-bootstrap';
import { ImageHelpers } from 'helpers/imageHelpers';
import styles from './LandingSubNav.module.scss';
import { ILandingSubNavProps } from './types/ILandingSubNavProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';

const LandingSubNav = ({ data }: ILandingSubNavProps) => {
  const [containerRef, isVisible] = useAnimation();

  if (!data || data.subCategoriesGroup.length < 2) return null;

  return (
    <>
      <Container
        fluid
        data-testid="nkn-lsn-container"
        ref={containerRef as any}
        className={classNames('animate', 'content-block', { 'in-view': isVisible })}
      >
        <Row className={`${styles['nkn-lsn-item-row']} `} data-testid="nkn-lsn-item-row">
          <Col className={`${styles['nkn-lsn-item-list-wrap']}`} data-testid="nkn-lsn-item-list-wrap">
            <ul className={`${styles['nkn-lsn-item-list']}`} data-testid="nkn-lsn-item-list">
              {data.subCategoriesGroup.map((subCategory) => (
                <li
                  key={subCategory.uid}
                  data-testid={`nkn-lsn-item-${subCategory.uid}`}
                  className={` ${styles['nkn-lsn-item']}`}
                >
                  <div className={`${styles['nkn-lsn-content-wrap']}`}>
                    <a
                      href={subCategory.subCategoryUrl}
                      className={`${styles['nkn-lsn-a']}`}
                      data-testid={`nkn-lsn-a-${subCategory.uid}`}
                    >
                      {subCategory.subCategoryImagePath && (
                        <div className={`${styles['nkn-lsn-image-wrap']} ${styles['spacer']}`}>
                          <img
                            src={ImageHelpers.modifiedContentStackImageUrl(subCategory.subCategoryImagePath.url, '120')}
                            title={subCategory.subCategoryImagePath.title}
                            loading="lazy"
                            alt={subCategory.subCategoryAltImage}
                            data-testid={`nkn-lsn-img-${subCategory.uid}`}
                          />
                        </div>
                      )}
                      <div className={`${styles['nkn-lsn-content']}`}>
                        <span data-testid={`nkn-lsn-content-${subCategory.uid}`}>{subCategory.subCategoryName}</span>
                      </div>
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LandingSubNav;
