import { IFeatureBand, IFeatureBandBlock, IFeatureBandGroup, IFeatureBandReference } from 'interfaces/IFeatureBand';
import { TFeatureBand, TFeatureBandGroup } from '../types/TFeatureBand';

export const FeatureBandsBlockDTO = ({ feature_band_block }: IFeatureBandBlock): TFeatureBand =>
  FeatureBandsReferenceDTO(feature_band_block);

export const FeatureBandsReferenceDTO = ({ feature_band_reference }: IFeatureBandReference): TFeatureBand =>
  FeatureBandsDTO(feature_band_reference[0]);

export const FeatureBandsDTO = (data: IFeatureBand): TFeatureBand => ({
  contentTypeUid: 'feature_band',
  featureBandGroup: data?.feature_band_group?.map(FeatureBandGroupDTO) || [],
  uid: data.uid,
});
export const FeatureBandGroupDTO = (data: IFeatureBandGroup): TFeatureBandGroup => ({
  shortBand: data.short_band,
  uid: data._metadata.uid,
  longBand: data.long_band,
});
