import { RATINGS_BLOCK_STATUS } from 'helpers/constants/RatingsAndReviewBlocksConstants';
import { TURN_TO_MINIMUM_REVIEWS_THRESHOLD } from '../constants/turnTo';

export const getReviewStatus = (data: { numberOfReviews: number; userCanReview: boolean }) => {
  if (!data.userCanReview) {
    return RATINGS_BLOCK_STATUS.COMING_SOON;
  }

  if (TURN_TO_MINIMUM_REVIEWS_THRESHOLD > data.numberOfReviews) {
    return RATINGS_BLOCK_STATUS.BE_THE_FIRST_TO_WRITE;
  }

  return RATINGS_BLOCK_STATUS.REVIEW_COUNT;
};
