import { SeoVideoDataDTO } from './SeoDataDTO';
import { IOneVideo, IOneVideoBlock, IOneVideoReference } from '../interfaces/IOneVideo';
import { TOneVideo } from '../types/TOneVideo';

export const OneVideoBlockDTO = ({ one_video_block }: IOneVideoBlock): TOneVideo =>
  OneVideoReferenceDTO(one_video_block);

export const OneVideoReferenceDTO = ({ one_video_reference }: IOneVideoReference): TOneVideo =>
  OneVideoDTO(one_video_reference[0]);

export const OneVideoDTO = (videoBlock: IOneVideo): TOneVideo => ({
  contentTypeUid: 'one_video',
  videoLocation: videoBlock.video_location,
  videoReferenceID: videoBlock.video_ref_id,
  autoplay: videoBlock.autoplay,
  description: videoBlock.description,
  headline: videoBlock.headline,
  loop: videoBlock.loop_video,
  recommendedYouTubeVideos: videoBlock.recommended_youtube_videos,
  textAlignment: videoBlock.text_alignment,
  videoJustification: videoBlock.video_justification,
  seoData: videoBlock.seo ? SeoVideoDataDTO(videoBlock.seo) : null,
  uid: videoBlock.uid,
});
