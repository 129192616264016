import { IconDTO } from './CommonFieldsDTO';
import {
  IDisclaimersReference,
  IDisclaimersContentBlocks,
  IDisclaimersDescriptionBlock,
  IDisclaimersIconsBlock,
  IDisclaimersSpaceBlock,
  IDisclaimersSubTitleBlock,
  IDisclaimersTitleBlock,
  IDisclaimers,
  IDisclaimersBlock,
} from '../interfaces/IDisclaimers';
import {
  TDisclaimers,
  TDisclaimersContentBlocks,
  TDisclaimersDescriptionBlock,
  TDisclaimersIconsBlock,
  TDisclaimersSpaceBlock,
  TDisclaimersSubTitleBlock,
  TDisclaimersTitleBlock,
  TDisclaimersUnknownBlock,
} from '../types/TDisclaimers';

export const DisclaimersBlockDTO = ({ disclaimers_block }: IDisclaimersBlock): TDisclaimers =>
  DisclaimersReferenceDTO(disclaimers_block);

export const DisclaimersReferenceDTO = ({ disclaimers_reference }: IDisclaimersReference): TDisclaimers =>
  DisclaimersDTO(disclaimers_reference[0]);

export const DisclaimersDTO = (disclaimer: IDisclaimers): TDisclaimers => ({
  contentTypeUid: disclaimer._content_type_uid,
  title: disclaimer.title,
  uid: disclaimer.uid,
  disclaimerContentBlocks: disclaimer.content_blocks.map(DisclaimersContentBlockDTO),
});

export const DisclaimersContentBlockDTO = (contentBlock: IDisclaimersContentBlocks): TDisclaimersContentBlocks => {
  const blockName = Object.keys(contentBlock)[0];
  const blockValues = Object.values(contentBlock)[0];

  switch (blockName) {
    case 'title_block':
      return DisclaimersTitleBlockDTO(blockValues as IDisclaimersTitleBlock);

    case 'sub_title_block':
      return DisclaimersSubTitleBlockDTO(blockValues as IDisclaimersSubTitleBlock);

    case 'description_block':
      return DisclaimersDescriptionBlockDTO(blockValues as IDisclaimersDescriptionBlock);

    case 'icons_block':
      return DisclaimersIconsBlockDTO(blockValues as IDisclaimersIconsBlock);

    case 'space_block':
      return DisclaimersSpaceBlockDTO(blockValues as IDisclaimersSpaceBlock);

    default:
      const unknownBlock = blockValues as unknown as TDisclaimersUnknownBlock;
      return {
        blockName,
        uid: unknownBlock.uid,
      } as TDisclaimersUnknownBlock;
  }
};

export const DisclaimersTitleBlockDTO = (titleBlock: IDisclaimersTitleBlock): TDisclaimersTitleBlock => ({
  contentTypeUid: 'titleBlock',
  title: titleBlock.title,
  uid: titleBlock._metadata.uid,
});

export const DisclaimersSubTitleBlockDTO = (subTitleBlock: IDisclaimersSubTitleBlock): TDisclaimersSubTitleBlock => ({
  contentTypeUid: 'subTitleBlock',
  subTitle: subTitleBlock.sub_title,
  uid: subTitleBlock._metadata.uid,
});

export const DisclaimersDescriptionBlockDTO = (
  descriptionBlock: IDisclaimersDescriptionBlock,
): TDisclaimersDescriptionBlock => ({
  contentTypeUid: 'descriptionBlock',
  description: descriptionBlock.description,
  uid: descriptionBlock._metadata.uid,
});

export const DisclaimersIconsBlockDTO = (iconBlock: IDisclaimersIconsBlock): TDisclaimersIconsBlock => {
  const icon1 = IconDTO({
    filePath: iconBlock.icon_1_file_path,
    maxWidth: iconBlock.icon_1_max_width,
    altText: iconBlock.icon_1_alt_text,
    redirectLinkUrl: iconBlock?.icon_1_redirect_link_url,
  });

  const icon2 = IconDTO({
    filePath: iconBlock.icon_2_file_path,
    maxWidth: iconBlock.icon_2_max_width,
    altText: iconBlock.icon_2_alt_text,
    redirectLinkUrl: iconBlock?.icon_2_redirect_link_url,
  });

  return {
    contentTypeUid: 'iconBlock',
    icons: [...(icon1 ? [icon1] : []), ...(icon2 ? [icon2] : [])],
    uid: iconBlock._metadata.uid,
  };
};

export const DisclaimersSpaceBlockDTO = (titleBlock: IDisclaimersSpaceBlock): TDisclaimersSpaceBlock => ({
  contentTypeUid: 'spaceBlock',
  enableSpace: titleBlock.enable_space,
  uid: titleBlock._metadata.uid,
});
