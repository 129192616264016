import { SetStateAction, useEffect, useState } from 'react';
import { getObjectFromStringPath } from '../utils/getObjectFromStringPath';

interface Options {
  required?: boolean;
  minLength?: number;
  length?: number;
  return?: string;
}

export const useData = <K, T>(data: K, optionsInput?: Options): [T, boolean] => {
  const [newData, setData] = useState<K>(data);
  const [isValid, setIsValid] = useState(false);
  const [options] = useState(optionsInput);

  useEffect(() => {
    if (!data) {
      setIsValid(false);
      return;
    }

    if (options?.return) {
      let objData: Record<string, unknown> | null = null;
      const returnKeys = options.return.split('.');

      try {
        objData = getObjectFromStringPath(options.return, data) as Record<string, unknown> | null;
      } catch (e) {
        setIsValid(false);
        return;
      }

      if (!objData) {
        setIsValid(false);
        return;
      }

      if (options.required === true && objData == null) {
        setIsValid(false);
        return;
      }

      if (options.length != null && (!Array.isArray(objData) || objData.length !== options.length)) {
        setIsValid(false);
        return;
      }

      if (options.minLength != null && (!Array.isArray(objData) || objData.length < options.minLength)) {
        setIsValid(false);
        return;
      }

      setData(objData as SetStateAction<K>);
      setIsValid(true);
    }

    setIsValid(true);
  }, [data, options]);

  return [newData as unknown as T, isValid];
};
