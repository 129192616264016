import Link from 'next/link';
import { Col, Row } from 'react-bootstrap';
import styles from './ColumnContent.module.scss';
import { VideoPanel } from './VideoPanel';
import { useData } from '../../../../../helpers/hooks/useData';
import { EHorizontalAlignments, EVerticalAlignments } from '../../../../../types/CommonFields';
import { TContentGroup } from '../../../../../types/TOneToFourColumn';
import ResponsiveImage from '../../../atoms/ResponsiveImage';

interface Props {
  data: TContentGroup;
  length: number;
}

const ColumnContent = ({ data, length }: Props) => {
  const [item, isValid] = useData<TContentGroup, TContentGroup>(data);

  if (!isValid) {
    return null;
  }

  const isAdjacent =
    Boolean(
      item.media?.image?.file?.filename || item.media?.video?.contentstack || item.media?.video?.youTubeOrVimeo,
    ) && data.layerTextOverMedia === 'adjacent';
  const textJustification =
    !Boolean(item.media?.image?.file?.filename) &&
    !Boolean(item.media?.video?.contentstack) &&
    !Boolean(item.media?.video?.youTubeOrVimeo)
      ? 'center'
      : data.textJustification;
  const imageJustification =
    !Boolean(item.media?.image?.file?.filename) &&
    !Boolean(item.media?.video?.contentstack) &&
    !Boolean(item.media?.video?.youTubeOrVimeo)
      ? 'full-width'
      : data.imageJustification;

  return (
    <div
      data-testid="item-container"
      className={`${styles['item-container']} ${styles[item.layerTextOverMedia]} ${styles[`grid-${length}`]}`}
    >
      {isAdjacent && imageJustification !== 'full-width' && imageJustification !== 'full-width-edge-to-edge' ? (
        <Row
          className={`${styles[`text-justification-${textJustification}`]} ${
            styles[`image-justification-${imageJustification}`]
          }`}
        >
          {item.media?.image && (
            <Col>
              <ResponsiveImage
                desktopImage={item.media.image.file}
                altText={item.media.image.mediaAlt}
                verticalPosition={EVerticalAlignments.Center}
                horizontalPosition={EHorizontalAlignments.Center}
              />
            </Col>
          )}
          {item.media?.video && (
            <Col>
              <VideoPanel
                contentstack={item.media.video.contentstack}
                youTubeOrVimeo={item.media.video.youTubeOrVimeo}
                itemLength={length}
              />
            </Col>
          )}

          {(Boolean(item?.headline) ||
            Boolean(item?.description) ||
            Boolean(item.link?.linkRedirectUrl && item.link?.linkCopyText)) && (
            <Col>
              <div className={`${styles['content']}`}>
                {Boolean(item?.headline) && (
                  <h3 data-testid="headline" dangerouslySetInnerHTML={{ __html: item.headline || '' }} />
                )}
                {Boolean(item?.description) && (
                  <div
                    data-testid="description"
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: item.description || '' }}
                  />
                )}
                {Boolean(item.link?.linkRedirectUrl && item.link?.linkCopyText) && (
                  <Link
                    href={item.link?.linkRedirectUrl ? item.link?.linkRedirectUrl : ''}
                    data-testid="colContentLink"
                  >
                    <a className={styles['content-link']} target={item.link?.openInNewWindow ? '_blank' : '_self'}>
                      {item.link?.linkCopyText} {(isAdjacent || length > 1) && <span className={styles.icon}></span>}
                    </a>
                  </Link>
                )}
              </div>
            </Col>
          )}
        </Row>
      ) : (
        <>
          {item.media?.image && (
            <ResponsiveImage
              desktopImage={item.media.image.file}
              altText={item.media.image.mediaAlt}
              verticalPosition={EVerticalAlignments.Center}
              horizontalPosition={EHorizontalAlignments.Center}
            />
          )}
          {item.media?.video && (
            <VideoPanel
              contentstack={item.media.video.contentstack}
              youTubeOrVimeo={item.media.video.youTubeOrVimeo}
              itemLength={length}
            />
          )}
          {(Boolean(item?.headline) ||
            Boolean(item?.description) ||
            Boolean(item.link?.linkRedirectUrl && item.link?.linkCopyText)) && (
            <div className={`${styles['content']}`}>
              {Boolean(item?.headline) && (
                <h3 data-testid="headline" dangerouslySetInnerHTML={{ __html: item.headline || '' }} />
              )}
              {Boolean(item?.description) && (
                <div
                  data-testid="description"
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: item.description || '' }}
                />
              )}
              {Boolean(item.link?.linkRedirectUrl && item.link?.linkCopyText) && (
                <Link href={item.link?.linkRedirectUrl ? item.link?.linkRedirectUrl : ''} data-testid="colContentLink">
                  <a className={styles['content-link']} target={item.link?.openInNewWindow ? '_blank' : '_self'}>
                    {item.link?.linkCopyText} {(isAdjacent || length > 1) && <span className={styles.icon}></span>}
                  </a>
                </Link>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ColumnContent;
