import { IFAQs, IFAQPanelGroup, IFAQContentGroup, IFAQReference, IFAQBlock } from '../interfaces/IFAQPanel';
import { TFAQs, TFAQPanelGroup, TFAQContentGroup } from '../types/TFAQPanel';

export const FAQsBlockDTO = ({ faq_block }: IFAQBlock): TFAQs => FAQsReferenceDTO(faq_block);

export const FAQsReferenceDTO = ({ faq_reference }: IFAQReference): TFAQs => FAQsDTO(faq_reference[0]);

export const FAQsDTO = (faqProps: IFAQs): TFAQs => ({
  contentTypeUid: 'faq',
  title: faqProps.title,
  headline: faqProps.headline,
  uid: faqProps.uid,
  panelGroup: faqProps.panel_group ? faqProps.panel_group.map(FAQPanelGroupDTO) : [],
});

export const FAQPanelGroupDTO = (faqPanelGroup: IFAQPanelGroup): TFAQPanelGroup => ({
  panelTitle: faqPanelGroup.panel_title,
  contentGroup: faqPanelGroup.content_group ? faqPanelGroup.content_group.map(FAQContentGroupDTO) : [],
  uid: faqPanelGroup._metadata.uid,
});

export const FAQContentGroupDTO = (faqContentGroup: IFAQContentGroup): TFAQContentGroup => ({
  contentTitle: faqContentGroup?.content_title || '',
  contentDescription: faqContentGroup?.content_description || '',
  linkCopyText: faqContentGroup?.link_copy_text || '',
  linkUrl: faqContentGroup?.link_url || '',
  openInNewWindow: faqContentGroup.open_in_new_window,
  uid: faqContentGroup._metadata.uid,
});
