import classNames from 'classnames/bind';
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/CarouselItem';
import styles from './FullWidthVideo.module.scss';
import { IFullWidthVideoProps } from './types/IFullWidthVideoProps';
import { VideoPanel } from './VideoPanel';
import useAnimation from '../../../../helpers/hooks/useAnimation';

const cx = classNames.bind(styles);

const FullWidthVideo = ({ data }: IFullWidthVideoProps) => {
  const [containerRef, isVisible] = useAnimation();

  if (data?.expirationRow === null || new Date(data?.expirationRow).getTime() - new Date().getTime() > 0) {
    if (data.panelsBlock.length === 1) {
      const panelExpiration = data.panelsBlock[0]?.panelExpiration;
      if (panelExpiration == null || new Date(panelExpiration).getTime() - new Date().getTime() > 0) {
        return (
          <div
            data-testid="full-width-video"
            ref={containerRef as any}
            className={cx(
              'animate',
              'content-block',
              'd-block',
              'panel-container',
              { 'in-view': isVisible },
              styles['full-width-video'],
            )}
          >
            <div className={cx('video-container', { 'hero-container': data.panelsBlock[0].heroStyling })}>
              <VideoPanel videoPanel={data.panelsBlock[0]} />
            </div>
          </div>
        );
      } else return null;
    } else if (data.panelsBlock.length > 1) {
      const cousCousLabels: string[] = [];
      let isUnexpiredPanel = false;
      data.panelsBlock.map((videoPanel, index) => {
        if (
          videoPanel?.panelExpiration === null ||
          new Date(videoPanel?.panelExpiration).getTime() - new Date().getTime() > 0
        ) {
          isUnexpiredPanel = true;
          cousCousLabels.push(videoPanel.videoGroup.couscousTex || `Slide ${index + 1}`);
        }
      });

      if (isUnexpiredPanel) {
        return (
          <div
            data-testid="full-width-video"
            ref={containerRef as any}
            className={cx(
              'animate',
              'content-block',
              'd-block',
              'panel-container',
              { 'in-view': isVisible },
              styles['full-width-video'],
            )}
          >
            <Carousel
              fade={true}
              indicators={true}
              indicatorLabels={cousCousLabels}
              controls={false}
              interval={data.automaticRotation ? 8000 : null}
            >
              {data.panelsBlock.map((videoPanel, index) => {
                if (
                  videoPanel?.panelExpiration === null ||
                  new Date(videoPanel?.panelExpiration).getTime() - new Date().getTime() > 0
                ) {
                  return (
                    <CarouselItem
                      data-testid={`carousel-item`}
                      key={`carousel-item_${data.uid}_${index}`}
                      className={cx('video-container', { 'hero-container': videoPanel.heroStyling })}
                    >
                      <VideoPanel videoPanel={videoPanel} />
                    </CarouselItem>
                  );
                } else return null;
              })}
            </Carousel>
          </div>
        );
      } else return null;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default FullWidthVideo;
