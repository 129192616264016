import { IPlainFeatures, IPlainFeaturesBlock, IPlainFeaturesReference } from '../interfaces/IPlainFeatures';
import { TPlainFeatures, TPlainFeaturesGroup } from '../types/TPlainFeatures';

export const PlainFeaturesBlockDTO = ({ plain_features_block }: IPlainFeaturesBlock): TPlainFeatures =>
  PlainFeaturesReferenceDTO(plain_features_block);

export const PlainFeaturesReferenceDTO = ({ plain_features_reference }: IPlainFeaturesReference): TPlainFeatures =>
  PlainFeaturesDTO(plain_features_reference[0]);

export const PlainFeaturesDTO = (plainFeatures: IPlainFeatures): TPlainFeatures => ({
  contentTypeUid: 'plain_features',
  uid: plainFeatures.uid,
  title: plainFeatures.title,
  displayOnMobile: plainFeatures.display_on_mobile,
  generalHeadline: plainFeatures.general_headline,
  plainFeaturesGroup: plainFeatures.plain_features_group.map(
    (plainFeature): TPlainFeaturesGroup => ({
      headline: plainFeature.headline,
      description: plainFeature.description,
    }),
  ),
});
