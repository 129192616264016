import { FileMandatoryDTO } from './CommonFieldsDTO';
import { ICarousel, ICarouselBlock, ICarouselImage, ICarouselReference } from '../interfaces/IImageCarousel';
import { TCarousel, TCarouselImage } from '../types/TCarousel';

export const CarouselBlockDTO = ({ carousel_block }: ICarouselBlock) => CarouselReferenceDTO(carousel_block);

export const CarouselReferenceDTO = ({ carousel_reference }: ICarouselReference) =>
  CarouselPropsDTO(carousel_reference[0]);

export const CarouselPropsDTO = (carousel: ICarousel): TCarousel => ({
  contentTypeUid: 'carousel',
  description: carousel.description,
  displayOnMobile: carousel.display_on_mobile,
  fileName: carousel.file_name,
  headline: carousel.headline,
  textPosition: carousel.text_position,
  showWithGuttersOnDesktop: carousel.show_with_gutters_on_desktop,
  imageBlock: carousel.image_block.map(ImageDTO),
  uid: carousel.uid,
});

export const ImageDTO = ({ images }: ICarouselImage): TCarouselImage => ({
  imagePath: FileMandatoryDTO(images.image_path),
  caption: images.caption,
  imageAltText: images.image_alt_text,
  imageTitleText: images.image_title_text,
  imageOneLiner: images.image_one_liner,
  uid: images._metadata.uid,
});
