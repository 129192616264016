import React, { FC } from 'react';
import { TSeoVideoData } from 'types/TSeoVideoData';

const MetaDataVideo: FC<TSeoVideoData> = ({
  name,
  thumbnailUrl,
  uploadDate,
  contentUrl,
  description,
  duration,
  embedUrl,
}) => {
  const data = {
    '@context': 'https://schema.org',
    subjectOf: {
      '@type': 'VideoObject',
      name: name,
      thumbnailUrl: thumbnailUrl,
      uploadDate: uploadDate,
      contentUrl: contentUrl,
      description: description,
      duration: duration,
      embedUrl: embedUrl,
    },
  };

  return <script type="application/ld+json">{JSON.stringify(data)}</script>;
};

export default MetaDataVideo;
