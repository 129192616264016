import styles from './CarouselText.module.scss';
import { ICarouselTextProps } from './types/ICarouselTextProps';
import { ETextPosition } from '../../../../../types/CommonFields';

const CarouselText = ({ headline, description, textPosition }: ICarouselTextProps) => {
  if (!headline && !description) {
    return false as unknown as JSX.Element;
  }

  return (
    <div
      className={`text-center ${styles['nkn-cl-info']} ${textPosition === ETextPosition.Below && styles['down']}`}
      data-testid={`nkn-cl-info`}
    >
      {headline && (
        <h3 className={`${styles['nkn-cl-headline']}`} data-testid={`nkn-cl-headline`}>
          {headline}
        </h3>
      )}

      {description && (
        <p className={`${styles['nkn-cl-description']}`} data-testid={`nkn-cl-description`}>
          {description}
        </p>
      )}
    </div>
  );
};

export default CarouselText;
