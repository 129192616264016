import { IPromoBanner, IPromoBannerMessage, IPromoBannerReference } from '../interfaces/IPromoBanner';
import { TPromoBanner, TPromoBannerMessage } from '../types/TPromoBanner';

export const PromoBannerReferenceDTO = ({ promo_banner_reference }: IPromoBannerReference): TPromoBanner | undefined =>
  PromoBannerDTO(promo_banner_reference[0]);

export const PromoBannerDTO = (promoBanner?: IPromoBanner): TPromoBanner | undefined => {
  if (!promoBanner) {
    return;
  }

  return {
    contentTypeUid: 'promo_banner',
    bannerBackgroundColor: promoBanner.banner_background_color,
    bannerMessageTextColor: promoBanner.banner_message_text_color,
    displayOnlyDesktop: promoBanner.display_only_desktop,
    promoMessagesGroup: promoBanner.promo_messages_group.map(PromoBannerMessageDTO),
  };
};

const PromoBannerMessageDTO = (promoBannerMessage: IPromoBannerMessage): TPromoBannerMessage => ({
  promoMessage: promoBannerMessage.promo_message,
  url: promoBannerMessage.url,
  openInNewWindow: promoBannerMessage.open_in_new_window,
  uid: promoBannerMessage._metadata.uid,
});
