import { useEffect, useRef } from 'react';
import Carousel, { CarouselRef } from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/CarouselItem';
import { ImageHelpers } from 'helpers/imageHelpers';
import { ICarouselImageProps } from './types/ICarouselImageProps';

const CarouselImage = ({ imageBlock, showWithGuttersOnDesktop }: ICarouselImageProps) => {
  const carouselRef = useRef<CarouselRef>(null);

  useEffect(() => {
    if (carouselRef?.current !== null && carouselRef.current.element) {
      const carousel = carouselRef.current.element;
      const pre = carousel.querySelector('.carousel-control-prev') as HTMLElement;
      const next = carousel.querySelector('.carousel-control-next') as HTMLElement;

      if (pre) {
        pre.style.bottom = '-200px !important';
      }

      if (next) {
        next.style.bottom = '-200px !important';
      }
    }
  }, [carouselRef?.current?.element]);

  return (
    <Carousel
      ref={carouselRef}
      indicators={false}
      keyboard={true}
      touch={true}
      fade={true}
      className={`has-controls ${showWithGuttersOnDesktop && 'image-carousel-contained'}`}
      data-testid={`nkn-carousel`}
    >
      {imageBlock.map(({ imagePath, caption, imageAltText, uid }) => (
        <CarouselItem key={uid} data-testid={`nkn-cl-item`}>
          <img
            src={ImageHelpers.modifiedContentStackImageUrl(imagePath.url, '1440', '640')}
            loading="lazy"
            className={`nkn-cl-img`}
            alt={imageAltText}
          />

          {caption && <Carousel.Caption data-testid={`nkn-cl-cap-wrapper-${uid}`}>{caption}</Carousel.Caption>}
        </CarouselItem>
      ))}
    </Carousel>
  );
};

export default CarouselImage;
