type SourceObject = {
  [key: string]: unknown;
};

export const getObjectFromStringPath = (path: string, obj: SourceObject): unknown => {
  // Ensure path is in the correct format.
  // It should start with a word or a word followed by an array index, followed by zero or more ".word" or ".word[number]" parts
  if (!/^[\w]+(\[\d\])?(?:\.[\w]+(\[\d\])?)*$/.test(path)) {
    throw new Error('Invalid path');
  }

  let current: any = obj;
  const parts = path.split('.');

  for (const part of parts) {
    const match = part.match(/^([\w]+)(\[(\d)\])?$/);
    if (match) {
      const [, key, , indexStr] = match;
      if (indexStr !== undefined) {
        const index = parseInt(indexStr, 10);
        if (Array.isArray(current[key]) && index < current[key].length) {
          current = current[key][index];
        } else if (Array.isArray(current) && key === 'length' && index < current.length) {
          current = current[index];
        } else {
          throw new Error(`Invalid path: ${part}`);
        }
      } else {
        if (current[key]) {
          current = current[key];
        } else {
          throw new Error(`Invalid path: ${part}`);
        }
      }
    } else {
      throw new Error(`Invalid path: ${part}`);
    }
  }

  return current;
};
