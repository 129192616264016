import classNames from 'classnames';
import { ImageHelpers } from 'helpers/imageHelpers';
import { TQuickLinkPdf } from 'types/TQuickLinks';
import styles from '../QuickLinks.module.scss';

export const PdfLink = ({ link }: { link: TQuickLinkPdf }) => {
  return (
    <a
      className={classNames('flex-column d-md-block position-relative', styles['quick-link'], {
        'd-none ': link.hideLinkOnMobile,
      })}
      target={(link.openInNewWindow && '_blank') || ''}
      href={link.resourceUrl}
    >
      <div className={styles['img-wrap']}>
        <img
          className="img-responsive"
          src={ImageHelpers.modifiedContentStackImageUrl(link.imageUrl, '360')}
          alt={link.imageAlt || link.linkTitle}
          draggable={false}
        />
      </div>
      <div className={`${styles['text-info']} mt-3`}>
        <p className={`${styles.title} text-decoration-none text-center`}>{link.linkTitle}</p>
        <p
          className={`${styles.description} text-decoration-none text-center`}
          dangerouslySetInnerHTML={{ __html: link.linkDescription }}
        />
      </div>
    </a>
  );
};
