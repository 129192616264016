import React from 'react';
import classNames from 'classnames';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './Search.module.scss';

interface SearchItemProps {
  url: string;
  title: string;
  image: string;
  description: string;
  onKeyDown: any;
}

const SearchItem = ({ url, title, image, description, onKeyDown }: SearchItemProps) => {
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'search' });
  return (
    <li className={styles['search-results-list-item']} onKeyDown={onKeyDown}>
      <a href={url}>
        <img src={image} alt={title} className={styles['search-results-list-item-img']} />

        <div className={styles['search-results-list-item-content']}>
          <h4 title={title} className={classNames(styles['search-results-list-item-title'], 'truncate')}>
            {title}
          </h4>
          {Boolean(url.includes('-refurbished')) && (
            <h4 title={title} className={classNames(styles['search-results-list-item-title'], 'truncate')}>
              {formatCommonMessage({ id: 'search.refurbished', defaultMessage: `Refurbished` })}
            </h4>
          )}
          {description ? (
            <p title={description} className={classNames(styles['search-results-list-item-description'], 'truncate')}>
              {description}
            </p>
          ) : null}
        </div>
      </a>
    </li>
  );
};

export default SearchItem;
