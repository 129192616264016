import { RefObject, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Variant } from '@commercetools/frontend-domain-types/product';
import classNames from 'classnames';
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/CarouselItem';
import { createPortal } from 'react-dom';
import { ImageHelpers } from 'helpers/imageHelpers';
import styles from './ProductViewExplorer.module.scss';
import Thumbnail, { Props as ThumbnailProps } from './Thumbnail';
import { BackgroundColor } from './types/enums';
import { IProductViewExplorerProps } from './types/IProductViewExplorerProps';
import { IMAGE_SOURCE } from '../../../../helpers/constants/imageSources';
import useAnimation from '../../../../helpers/hooks/useAnimation';
import useProductByKey from '../../../../helpers/hooks/useProductByKey';

interface ThumbnailPortalProps extends ThumbnailProps {
  domRef: boolean | RefObject<HTMLDivElement>;
}

function filterAndRemoveDuplicates(arr?: Variant[]) {
  const seen = new Set(); // Set to track seen values
  return arr?.filter((item) => {
    const color = item.attributes?.color;
    if (color) {
      if (!seen.has(color.key)) {
        seen.add(color.key);
        return true;
      }
    }
    return false;
  });
}

const ThumbnailPortal = ({ domRef, ...props }: ThumbnailPortalProps) => {
  const [isBrowser, setIsBrowser] = useState(false);
  const [domElem, setDomElem] = useState<Element | null>(null);

  useEffect(() => {
    setIsBrowser(typeof window === 'object');
    let container = null;
    if (typeof domRef !== 'boolean') {
      container = domRef.current as Element;
    }
    const carousel = container?.querySelector('.carousel') || null;
    setDomElem(carousel);
  }, [domRef]);

  if (isBrowser && domElem) {
    return createPortal(<Thumbnail {...props} />, domElem);
  }
  return null;
};

const ProductViewExplorer = ({ data, product }: IProductViewExplorerProps) => {
  const { locale: routerLocale } = useRouter();
  const [carrouselIdx, setCarrouselIdx] = useState(0);
  const [variantSelectedIdx, setVariantSelectedIdx] = useState(0);
  const [containerRef, isVisible] = useAnimation();
  const imageUnavailable = IMAGE_SOURCE.getUnavailableImage(routerLocale);

  let productKey = data?.product_key;
  if (!productKey) {
    productKey = product?.key;
  }

  const productByKey = useProductByKey(productKey || '');
  const colorVariants = useMemo(() => filterAndRemoveDuplicates(productByKey?.variants) || [], [productByKey]);
  const [currentVariant, setCurrentVariant] = useState<Variant | undefined>(
    colorVariants?.[0] || productByKey?.variants?.[0],
  );
  const [variantImages, setVariantImages] = useState<string[]>([imageUnavailable]);

  useEffect(() => {
    const variant = colorVariants?.[0] || productByKey?.variants?.[0];
    setCurrentVariant(variant);

    if (variant?.images?.length) {
      setVariantImages(variant.images);
    }
  }, [colorVariants, productByKey?.variants]);

  if (currentVariant && data) {
    const handleOnVariantSelected = (idx: number) => {
      const variant = colorVariants[idx];
      setVariantSelectedIdx(idx);
      setCurrentVariant(variant);
      if (variant?.images?.length) {
        setVariantImages(variant?.images);
      }
    };

    return (
      <div
        data-testid="container"
        className={classNames('animate', styles['product-view-explorer'], { 'in-view': isVisible })}
        ref={containerRef as RefObject<HTMLDivElement>}
      >
        <Carousel
          data-testid="carousel"
          indicators={false}
          interval={null}
          keyboard
          touch
          className={classNames(
            'has-controls',
            'carousel-arrows-on-sides',
            'carousel-full-width',
            { 'white-arrows': data.background_color === BackgroundColor.YELLOW },
            styles['carousel'],
          )}
          onSelect={(idx) => setCarrouselIdx(idx)}
          prevLabel="Previous Image"
          nextLabel="Next Image"
          controls={(currentVariant.images?.length ?? 0) > 1}
        >
          {variantImages.map((url, idx) => {
            return (
              <CarouselItem
                data-testid={`carrousel-item-${idx}`}
                key={idx}
                style={{ backgroundColor: data.background_color || '' }}
              >
                <div className={styles['image-container']}>
                  <img
                    data-testid={`image-${idx}`}
                    src={ImageHelpers.modifiedCTImageUrl(url, 'large')}
                    loading="lazy"
                    title={`${currentVariant.attributes?.title} ${currentVariant.attributes?.color?.label} Color`}
                  />
                </div>
              </CarouselItem>
            );
          })}
        </Carousel>
        {Boolean(colorVariants.length) && (
          <ThumbnailPortal
            domRef={containerRef}
            variants={colorVariants}
            carrouselIdx={carrouselIdx}
            variantSelectedIdx={variantSelectedIdx}
            onVariantSelected={handleOnVariantSelected}
            backgroundColor={data.background_color}
          />
        )}
      </div>
    );
  } else {
    return false as unknown as JSX.Element;
  }
};

export default ProductViewExplorer;
