import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import styles from './FeatureBand.module.scss';
import { IFeatureBandProps } from './types/IFeatureBandProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';

const FeatureBand = ({ data: { featureBandGroup } }: IFeatureBandProps) => {
  const [containerRef, isVisible] = useAnimation();

  if (!Boolean(featureBandGroup.length)) {
    return false as unknown as JSX.Element;
  }

  return (
    <Container
      data-testid={`feature-band-container`}
      ref={containerRef as any}
      className={classNames('animate', 'content-block', { 'in-view': isVisible }, styles['nkn-fb-container'])}
    >
      <div className={styles.wrapper}>
        <ul className={`${styles['nkn-fb-tabs']}`} data-testid={`nkn-fb-tabs`}>
          {featureBandGroup.map(({ shortBand, longBand, uid }) => (
            <li key={uid} className={`${styles['nkn-fb-li']}`} data-testid={'band-item'}>
              <span className={`${styles['nkn-fb-shortBand']}`}>
                {shortBand.split('\n').map((item, index) => {
                  return <span key={index} dangerouslySetInnerHTML={{ __html: item }}></span>;
                })}
              </span>

              <span className={`${styles['nkn-fb-longBand']}`}>
                {longBand.split('\n').map((item, index) => {
                  return <span key={`${uid}_${index}`} dangerouslySetInnerHTML={{ __html: item }}></span>;
                })}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
};

export default FeatureBand;
