import { FileDTO, LinkDTO, NavigationMenuDTO } from './CommonFieldsDTO';
import {
  IHeader,
  IHeaderAboveSearch,
  IHeaderBelowSearchItem,
  IHeaderLogoPath,
  IHeaderReference,
  IHeaderUserBar,
} from '../interfaces/IHeader';
import { THeader, THeaderAboveSearch, THeaderBelowSearchItem, THeaderLogoPath, THeaderUserBar } from '../types/THeader';

export const HeaderReferencesDTO = (headerBlock: IHeaderReference): THeader => {
  return HeaderDTO(headerBlock.header_reference);
};

export const HeaderDTO = (header: IHeader): THeader => {
  return {
    contentTypeUid: 'header',
    aboveSearch: HeaderAboveSearchDTO(header.above_search),
    belowSearch: header.below_search.map(HeaderBelowSearchItemDTO),
    logoLink: LinkDTO(header.logo_link),
    logoPath: HeaderLogoPathDTO(header.logo_path),
    navigationMenu: NavigationMenuDTO(header.navigation_menu_reference[0]),
    // seo: SeoDataDTO(header.seo),
    userBarBlock: header.user_bar_block.map(HeaderUserBarDTO),
  };
};

export const HeaderAboveSearchDTO = (aboveSearch: IHeaderAboveSearch): THeaderAboveSearch => ({
  continent: aboveSearch.continent,
  country: aboveSearch.country,
  region: aboveSearch.region,
});

export const HeaderBelowSearchItemDTO = (belowSearchItem: IHeaderBelowSearchItem): THeaderBelowSearchItem => ({
  linkText: belowSearchItem.link_text,
  linkUrl: belowSearchItem.link_url,
  openInNewWindow: belowSearchItem.open_in_new_window,
  uid: belowSearchItem._metadata.uid,
});

export const HeaderLogoPathDTO = (logoPath: IHeaderLogoPath): THeaderLogoPath => {
  const file = FileDTO(logoPath);

  return {
    ...file,
    description: logoPath.description,
  } as THeaderLogoPath;
};

export const HeaderUserBarDTO = ({ user_bar: userBar }: IHeaderUserBar): THeaderUserBar => ({
  iconPath: FileDTO(userBar.icon_path),
  redirectLink: LinkDTO(userBar.redirect_link),
  uid: userBar._metadata.uid,
});
