import { FileDTO } from './CommonFieldsDTO';
import { SeoVideoDataDTO } from './SeoDataDTO';
import { IsImage } from '../helpers/utils/IsImage';
import {
  IContentGroup,
  IOneToFourColumn,
  IOneToFourColumnBlock,
  IOneToFourColumnReference,
} from '../interfaces/IOneToFourColumn';
import {
  TContentGroup,
  TImage,
  TLink,
  TOneToFourColumn,
  TVideo,
  TVideoLocationForVideo,
} from '../types/TOneToFourColumn';

const isValidContentGroup = (item: IContentGroup) => {
  const isValidMediaLocation = ['Contentstack', 'Vimeo', 'YouTube'].includes(item.media?.media_location || '');
  if (!isValidMediaLocation) {
    return false;
  }

  if (item.media?.media_location === 'Contentstack' && item.media.media_path) {
    return true;
  } else return !!(item.media?.media_location !== 'Contentstack' && item.media?.video_reference_id);
};

export const OneToFourColumnBlockDTO = ({ one_to_four_columns_block }: IOneToFourColumnBlock): TOneToFourColumn =>
  OneToFourColumnReferenceDTO(one_to_four_columns_block);

export const OneToFourColumnReferenceDTO = ({
  one_to_four_columns_reference,
}: IOneToFourColumnReference): TOneToFourColumn => OneToFourColumnDTO(one_to_four_columns_reference[0]);

export const OneToFourColumnDTO = (data: IOneToFourColumn): TOneToFourColumn => {
  const isValid = data.content_group?.some(isValidContentGroup) || false;

  let newContentGroup: TContentGroup[] | undefined = undefined;
  if (isValid) {
    newContentGroup = data.content_group?.reduce((prev: TContentGroup[], item) => {
      if (isValidContentGroup(item)) {
        prev.push(ContentGroupDTO(item));
      }
      return prev;
    }, []);
  }

  return {
    contentTypeUid: 'one_to_four_columns',
    title: data.title,
    contentGroup: newContentGroup,
    backgroundColor: data.background_color,
    textColor: data.text_color,
    uid: data.uid,
  };
};
const ContentGroupDTO = (data: IContentGroup): TContentGroup => {
  const isImage = IsImage(data.media?.media_path?.filename || '');

  let image: TImage | undefined = undefined;
  let video: TVideo | undefined = undefined;

  if (isImage) {
    image = {
      mediaAlt: data.media?.media_alt || data.media?.media_path?.filename || '',
      mediaTitle: data.media?.media_title || data.media?.media_path?.filename || '',
      file: (data.media?.media_path && FileDTO(data.media?.media_path)) || undefined,
    };
  } else {
    video = {
      contentstack: undefined,
      youTubeOrVimeo: undefined,
    };
    if (data.media?.media_location === 'Contentstack') {
      video.contentstack = {
        src: data.media?.media_path?.url || '',
        autoplay: data.media?.auto_play,
        contentType: data.media?.media_path?.content_type || '',
        controls: data.media?.controls,
        loop: data.media?.loop_video,
        seoData: data.seo ? SeoVideoDataDTO(data.seo) : null,
      };
    } else {
      video.youTubeOrVimeo = {
        videoLocation: (data.media?.media_location as TVideoLocationForVideo | undefined) || 'YouTube',
        videoRefId: data.media?.video_reference_id || '',
        autoplay: data.media?.auto_play,
        controls: data.media?.controls,
        loop: data.media?.loop_video,
        recommendedYouTubeVideos: data.media?.recommended_youtube_videos,
        seoData: data.seo ? SeoVideoDataDTO(data.seo) : null,
      };
    }
  }

  let link: TLink | undefined = undefined;
  if (data.link) {
    link = {
      linkCopyText: data.link.link_copy_text,
      linkRedirectUrl: data.link.link_redirect_url,
      openInNewWindow: data.link.open_in_new_window,
    };
  }

  return {
    description: data.description,
    headline: data.headline,
    layerTextOverMedia: data.layer_text_over_media,
    media: { image, video },
    link: link,
    imageJustification: data.image_justification || 'full-width',
    textJustification: data.text_justification || 'center',
    metadata: data._metadata,
  };
};
