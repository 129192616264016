import { IFullWidthText, IFullWidthTextBlock, IFullWidthTextReference, ILinks } from 'interfaces/IFullWidthText';
import { TFullWidthText, TLinks } from 'types/TFullWidthText';

export const FullWidthTextBlockDTO = ({ full_width_text_block }: IFullWidthTextBlock): TFullWidthText =>
  FullWidthTextReferenceDTO(full_width_text_block);

export const FullWidthTextReferenceDTO = ({ full_width_text_reference }: IFullWidthTextReference): TFullWidthText =>
  FullWidthTextDTO(full_width_text_reference[0]);

export const FullWidthTextDTO = (data: IFullWidthText): TFullWidthText => ({
  contentTypeUid: 'full_width_text',
  title: data.title,
  headline: data.headline,
  headlinePlacement: data.headline_placement,
  subHead: data.sub_head,
  subHeadPlacement: data.sub_head_placement,
  description: data.description,
  descriptionPlacement: data.description_placement,
  backgroundColor: data.background_color,
  textColor: data.text_color,
  links: data.links?.map(LinksDTO),
  uid: data.uid,
});

export const LinksDTO = (links: ILinks): TLinks => ({
  linkText: links.link_text,
  linkUrl: links.link_url,
  openIn: links.open_in,
  visibleOnDesktopOnly: links.visible_on_desktop_only,
  uid: links._metadata.uid,
});
