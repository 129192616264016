import React from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames/bind';
import { Button } from 'react-bootstrap';
import Link from 'components/commercetools-ui/atoms/link';
import { BIG_COMMERCE_ORDER_LINK, BIG_COMMERCE_WISHLIST_LINK } from 'helpers/constants/accountConstants';
import { appLocales } from 'helpers/constants/appLocales';
import { useFormat } from 'helpers/hooks/useFormat';
import { getLocalizationInfo } from 'project.config';
import { useAccount } from 'frontastic';
import { TLocalizationInfo } from '../../../../types/TLocalizationInfo';
import styles from '../UserBarBlock/UserBarBlock.module.scss';

const cx = classNames.bind(styles);

const AccountFlyout: React.FC<{ redirectLink: string }> = ({ redirectLink }) => {
  const router = useRouter();
  const localizationInfo: TLocalizationInfo = getLocalizationInfo(router.locale);
  const locale = localizationInfo.appLocale || 'en_US';
  const language = localizationInfo.language;
  const salesforceAccountPage = `https://www.nikonproductsupport.com/s/my-account?language=${locale}`;

  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { account, loggedIn, logout, login } = useAccount();

  let orderLink = '/account/order-history';

  if ([appLocales.EN_CA, appLocales.FR_CA].includes(locale)) {
    orderLink = `${BIG_COMMERCE_ORDER_LINK}&lang=${language}`;
  }

  const handleLogout = () => {
    logout().then(() => router.push('/'));
  };

  return (
    <section
      id="account-flyout"
      className={cx(`${styles.flyout} ${styles.account}`, {
        unrecognized: !loggedIn,
      })}
      data-testid="account-flyout"
    >
      <div className={`${styles.content}`}>
        {!loggedIn && (
          <>
            <div className={`${styles.title} h3`} data-testid="user-sign-in-title">
              <span>
                {formatAccountMessage({ id: 'account.flyout.welcomeGuest', defaultMessage: 'Welcome, Guest' })}
              </span>
            </div>
            <p className={styles.instruction} data-testid="user-sign-in-instruction">
              {formatAccountMessage({
                id: 'account.flyout.createAccount',
                defaultMessage: 'Sign in or create an account to access your information.',
              })}
            </p>
            <Button onClick={login} className="nk-btn-rounded btn-black" data-testid="user-sign-in-button">
              {formatAccountMessage({ id: 'account.flyout.signIn', defaultMessage: 'Sign in' })}
            </Button>
            {[appLocales.EN_CA, appLocales.FR_CA].includes(locale) && (
              <ul className={styles.guestLinks}>
                <li>
                  <Link href={orderLink} tabIndex={-1} data-testid="orders-link">
                    <span className={styles.linkTitle}>
                      {formatAccountMessage({ id: 'account.flyout.orderHistory', defaultMessage: 'Orders' })}
                    </span>
                  </Link>
                </li>
              </ul>
            )}
          </>
        )}
        {loggedIn && (
          <>
            <div className={`${styles.title} h3`} data-testid="user-sign-in-title">
              <span>{formatAccountMessage({ id: 'account.flyout.welcomeBack', defaultMessage: 'Welcome back!' })}</span>
            </div>
            <p className={styles.instruction} data-testid="user-sign-in-instruction">
              {formatAccountMessage({
                id: 'account.flyout.gladToSeeYou',
                defaultMessage: 'Glad to see you back',
              })}
              {account && `, ${account.firstName} ${account.lastName}`}
            </p>
            <ul className={styles.accountLinks}>
              {[appLocales.EN_US, appLocales.EN_CA, appLocales.FR_CA, appLocales.ES_MX].includes(locale) && (
                <li>
                  <Link href={salesforceAccountPage} tabIndex={-1} data-testid="my-account-link">
                    <span className={styles.linkTitle}>
                      {formatAccountMessage({ id: 'my.account', defaultMessage: 'My Account' })}
                    </span>
                  </Link>
                </li>
              )}
              {[appLocales.EN_US, appLocales.EN_CA, appLocales.FR_CA].includes(locale) && (
                <li>
                  <Link href={salesforceAccountPage} tabIndex={-1} data-testid="email-preferences-link">
                    <span className={styles.linkTitle}>
                      {formatAccountMessage({ id: 'email.preferences', defaultMessage: 'Email Preferences' })}
                    </span>
                  </Link>
                </li>
              )}
              {[appLocales.EN_CA, appLocales.FR_CA].includes(locale) && (
                <li>
                  <Link
                    href={`${BIG_COMMERCE_WISHLIST_LINK}?lang=${language}`}
                    tabIndex={-1}
                    data-testid="wishlist-link"
                  >
                    <span className={styles.linkTitle}>
                      {formatAccountMessage({ id: 'wishlist', defaultMessage: 'Wish List' })}
                    </span>
                  </Link>
                </li>
              )}
              {[appLocales.EN_US, appLocales.EN_CA, appLocales.FR_CA].includes(locale) && (
                <li>
                  <Link href={orderLink} tabIndex={-1} data-testid="orders-link">
                    <span className={styles.linkTitle}>
                      {formatAccountMessage({ id: 'orders', defaultMessage: 'Orders' })}
                    </span>
                  </Link>
                </li>
              )}
              {[appLocales.ES_MX].includes(locale) && (
                <li>
                  <Link
                    href="https://www.nikonproductsupport.com/s/NI_email_preferences?ctry=MX&lang=es_MX"
                    tabIndex={-1}
                    data-testid="email-preferences-link"
                  >
                    <span className={styles.linkTitle}>
                      {formatAccountMessage({ id: 'email.preferences', defaultMessage: 'Email Preferences' })}
                    </span>
                  </Link>
                </li>
              )}
              {[appLocales.PT_BR].includes(locale) && (
                <>
                  <li>
                    <Link
                      href="https://www.nikonproductsupport.com/s/NI_profile?ctry=BR&lang=pt_BR"
                      tabIndex={-1}
                      data-testid="my-account-link"
                    >
                      <span className={styles.linkTitle}>
                        {formatAccountMessage({ id: 'my.account', defaultMessage: 'My Account' })}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://www.nikonproductsupport.com/s/NI_ask_support?ctry=BR&lang=pt_BR"
                      tabIndex={-1}
                      data-testid="my-questions-link"
                    >
                      <span className={styles.linkTitle}>
                        {formatAccountMessage({ id: 'my.questions', defaultMessage: 'Minhas perguntas' })}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://www.nikonproductsupport.com/s/NI_support_questions?ctry=BR&lang=pt_BR"
                      tabIndex={-1}
                      data-testid="ask-a-question-link"
                    >
                      <span className={styles.linkTitle}>
                        {formatAccountMessage({ id: 'ask.a.question', defaultMessage: 'Fazer uma pergunta' })}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://secure.nikonsso.com/forgot-password?locale=pt_BR"
                      tabIndex={-1}
                      data-testid="forgot-password-link"
                    >
                      <span className={styles.linkTitle}>
                        {formatAccountMessage({ id: 'change.my.password', defaultMessage: 'Alterar minha senha' })}
                      </span>
                    </Link>
                  </li>
                </>
              )}
              <li>
                <Link href="#" tabIndex={-1} data-testid="sign-out-link" onClick={handleLogout}>
                  <span className={styles.linkTitle}>
                    {formatAccountMessage({ id: 'account.flyout.signOut', defaultMessage: 'Sign Out' })}
                  </span>
                </Link>
              </li>
            </ul>
          </>
        )}
      </div>
    </section>
  );
};

export default AccountFlyout;
