import classNames from 'classnames';
import CarouselImage from './CarouselImage';
import CarouselText from './CarouselText';
import styles from './ImageCarousel.module.scss';
import { ICarouselProps } from './types/ICarouselProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import { tablet } from '../../../../helpers/utils/screensizes';
import { ETextPosition } from '../../../../types/CommonFields';

const ImageCarousel = ({ data }: ICarouselProps) => {
  const [isDesktop] = useMediaQuery(tablet);
  const [containerRef, isVisible] = useAnimation();

  if (!Boolean(data.imageBlock.length) || (!data.displayOnMobile && !isDesktop)) {
    return null;
  }

  return (
    <div
      data-testid={`nkn-cl-container`}
      ref={containerRef as any}
      className={classNames('animate', 'content-block', { 'in-view': isVisible }, styles['nkn-cl-container'])}
    >
      {data.textPosition === ETextPosition.Above && (
        <CarouselText headline={data.headline} description={data.description} textPosition={data.textPosition} />
      )}

      <CarouselImage
        imageBlock={data.imageBlock}
        showWithGuttersOnDesktop={isDesktop && data.showWithGuttersOnDesktop}
      />

      {data.textPosition === ETextPosition.Below && (
        <CarouselText headline={data.headline} description={data.description} textPosition={data.textPosition} />
      )}
    </div>
  );
};

export default ImageCarousel;
