import classNames from 'classnames';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './PlainFeatures.module.scss';
import { IPlainFeaturesProps } from './types/IPlainFeaturesProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';

const PlainFeatures = ({ plainFeatures }: IPlainFeaturesProps) => {
  const [containerRef, isVisible] = useAnimation();

  return (
    <Container
      data-testid="plain-features"
      ref={containerRef as any}
      className={classNames(
        'animate',
        'content-block',
        { 'in-view': isVisible },
        { 'd-none d-md-block': !plainFeatures.displayOnMobile },
      )}
    >
      <Row className={styles['plain-features']}>
        <Col className={`justify-content-center text-center`}>
          <div key={`plainFeature_${plainFeatures.uid}`}>
            {plainFeatures.generalHeadline && <h2 data-testid="general-headline">{plainFeatures.generalHeadline}</h2>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={styles['plain-features']}>
          {plainFeatures.plainFeaturesGroup.map(({ headline, description }, index) => {
            return (
              <div className={styles['plain-features-block']} key={`plainFeature_${index}_${plainFeatures.uid}`}>
                {headline && (
                  <h3 data-testid="plain-features-headline" dangerouslySetInnerHTML={{ __html: headline || '' }}></h3>
                )}
                <div
                  className={styles.description}
                  data-testid="plain-features-description"
                  dangerouslySetInnerHTML={{ __html: description }}
                ></div>
              </div>
            );
          })}
        </Col>
      </Row>
    </Container>
  );
};

export default PlainFeatures;
