import className from 'classnames';
import { IVimeoPlayerProps } from './types/IVimeoPlayerProps';
import { MetaDataVideo } from '../../../../SEO/Video';
import styles from '../VideoIframe.module.scss';

const VimeoPlayer = ({
  classNames,
  iframeClassName,
  videoId,
  autoplay = false,
  loop = false,
  controls = true,
  seoData,
}: IVimeoPlayerProps) => {
  const url = new URL(`https://player.vimeo.com/video/${videoId}`);
  url.searchParams.set('byline', '1');

  if (autoplay) {
    url.searchParams.set('autoplay', '1');
    url.searchParams.set('muted', '1');
    url.searchParams.set('autopause', '0');
  }

  if (!controls) {
    url.searchParams.set('controls', '0');
  }

  if (loop) {
    url.searchParams.set('loop', '1');
  }

  return (
    <div data-testid="vimeo-player" className={className(styles['video-wrapper'], classNames)}>
      <iframe
        className={className(`${styles['video-iframe']}`, iframeClassName)}
        allow="autoplay fullscreen"
        title={seoData?.name || seoData?.description || 'vimeo-video'}
        allowFullScreen
        src={url.href}
      />
      <MetaDataVideo
        name={seoData?.name || null}
        contentUrl={seoData?.contentUrl || null}
        description={seoData?.description || null}
        duration={seoData?.duration || null}
        embedUrl={seoData?.embedUrl || null}
        thumbnailUrl={seoData?.thumbnailUrl || null}
        uploadDate={seoData?.uploadDate || null}
      />
    </div>
  );
};

export default VimeoPlayer;
