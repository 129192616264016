import React, { useRef } from 'react';
import classNames from 'classnames';
import { Item } from 'react-photoswipe-gallery';
import { ImageHelpers } from 'helpers/imageHelpers';
import { TQuickLinkImage } from 'types/TQuickLinks';
import styles from '../QuickLinks.module.scss';

export const ImageLink = ({ link }: { link: TQuickLinkImage }) => {
  const imageRef = useRef<HTMLImageElement | null>(null);

  return (
    <>
      <img
        ref={imageRef}
        src={ImageHelpers.modifiedContentStackImageUrl(link.resourceUrl, '1226')}
        style={{ display: 'none', position: 'absolute' }}
      />
      <Item
        original={link.resourceUrl}
        thumbnail={link.resourceUrl}
        width={imageRef.current ? imageRef.current.naturalWidth : 'auto'}
        height={imageRef.current ? imageRef.current.naturalHeight : 'auto'}
      >
        {({ ref, open }) => (
          <a
            className={classNames('flex-column d-md-block position-relative', styles['quick-link'], {
              'd-none ': link.hideLinkOnMobile,
            })}
            target={(link.openInNewWindow && '_blank') || ''}
            onClick={(event) => {
              event.preventDefault();
              open(event);
            }}
          >
            <div className={styles['img-wrap']}>
              <img
                className="img-responsive"
                ref={ref as React.MutableRefObject<HTMLImageElement>}
                src={ImageHelpers.modifiedContentStackImageUrl(link.imageUrl, '360')}
                alt={link.imageAlt || link.linkTitle}
                draggable={false}
              />
            </div>
            <div className={`${styles['text-info']} mt-3`}>
              <p className={`${styles.title} text-decoration-none text-center`}>{link.linkTitle}</p>
              <p
                className={`${styles.description} text-decoration-none text-center`}
                dangerouslySetInnerHTML={{ __html: link.linkDescription }}
              />
            </div>
          </a>
        )}
      </Item>
    </>
  );
};
