import styles from './DisclaimerDescriptionBlock.module.scss';
import IDisclaimerDescriptionBlock from './types/IDisclaimerDescriptionBlock';

const DisclaimerDescriptionBlock = ({ descriptionBlock }: IDisclaimerDescriptionBlock) => {
  if (!Boolean(descriptionBlock.description)) {
    return null;
  }

  return (
    <div
      className={styles.description}
      dangerouslySetInnerHTML={{ __html: descriptionBlock.description }}
      data-testid="disclaimer-description"
    />
  );
};
export default DisclaimerDescriptionBlock;
