import React, { RefObject } from 'react';
import classNames from 'classnames';
import styles from './TwoColumnRow.module.scss';
import { ITwoColumnRowProps } from './types/ITwoColumnRowProps';
import { parseColumnGroupItemToFullWidthImageData } from './utils';
import useAnimation from '../../../../helpers/hooks/useAnimation';
import FullWidthImage from '../FullWidthImage';

const TwoColumnRow = ({ data: { columnGroup, expirationRow } }: ITwoColumnRowProps) => {
  const isExpired = expirationRow !== null && expirationRow.valueOf() - new Date().valueOf() < 0;
  const [containerRef, isVisible] = useAnimation();

  if (isExpired || !columnGroup || !Boolean(columnGroup.length)) {
    return false as unknown as JSX.Element;
  }

  return (
    <div
      data-testid={'two-column-row'}
      ref={containerRef as RefObject<HTMLDivElement>}
      className={classNames(`animate content-block two-column-row ${styles['two-column-image-container']}`, {
        'in-view': isVisible,
      })}
    >
      {columnGroup.map((columnGroupItem) => (
        <FullWidthImage
          key={`full-width-image-key-${columnGroupItem.uid}`}
          data={parseColumnGroupItemToFullWidthImageData(columnGroupItem)}
        />
      ))}
    </div>
  );
};

export default TwoColumnRow;
