import { VIDEO_LOCATIONS } from 'helpers/constants/videoLocations';
import { IVideoIframe } from './types/IVideoIframe';
import styles from './VideoIframe.module.scss';
import VimeoPlayer from './VimeoPlayer';
import YouTubePlayer from './YouTubePlayer';

const VideoIframe = ({
  videoLocation,
  videoOrientation = 'horizontal',
  videoRefId,
  autoplay = false,
  controls = true,
  loop = false,
  seoData,
  recommendedYouTubeVideos = false,
}: IVideoIframe) => {
  if (videoLocation === VIDEO_LOCATIONS.YOUTUBE || videoLocation === VIDEO_LOCATIONS.YOUTUBE_PLAYLIST) {
    return (
      <YouTubePlayer
        classNames={`${styles['video-iframe-carousel']} ${styles[videoOrientation]}`}
        videoId={videoRefId}
        autoplay={autoplay}
        loop={loop}
        controls={controls}
        seoData={seoData}
        relatedVideos={recommendedYouTubeVideos}
        isPlaylist={videoLocation === VIDEO_LOCATIONS.YOUTUBE_PLAYLIST}
      />
    );
  }

  return (
    <VimeoPlayer
      videoId={videoRefId}
      classNames={`${styles[videoOrientation]}`}
      autoplay={autoplay}
      controls={controls}
      loop={loop}
      seoData={seoData}
    />
  );
};

export default VideoIframe;
