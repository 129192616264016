import useDebounce from 'helpers/hooks/useDebounce';
import { Locales, getSwiftTypeUrl } from 'helpers/utils/swiftType';
import { useState } from 'react';
import useSWR from 'swr';

interface UseTypeAheadReturn {
  searchTerm: string;
  searchResults: SearchResults[];
  error: any;
  handleSearchTermChange: (newTerm: string) => void;
  isLoading: boolean;
}

interface SearchResults {
  id: string;
  title: string;
  description: string;
  image: string;
  url: string;
}

const fields = ['url', 'niDefaultTitle', 'image', 'description', 'id'];
const functionalBoosts = { niBoostType: 'linear', niBoostProductIsNew: 'linear', niBoostProductIsOnSale: 'linear' };

const mapSearchResults = (records: any[]): SearchResults[] => {
  const refurbishedData: SearchResults[] = [];
  const regularData: SearchResults[] = [];

  records.forEach((record) => {
    if (Boolean(record?.url) && Boolean(record.url.includes('-refurbished'))) {
      refurbishedData.push({
        id: record.id,
        title: record.niDefaultTitle,
        description: record.description,
        image: record.image,
        url: record.url,
      });
    } else {
      regularData.push({
        id: record.id,
        title: record.niDefaultTitle,
        description: record.description,
        image: record.image,
        url: record.url,
      });
    }
  });
  return [...refurbishedData, ...regularData];
};

const useTypeAhead = (locale: Locales, defaultValue: string = ''): UseTypeAheadReturn => {
  const [searchTerm, setSearchTerm] = useState<string>(defaultValue);
  const [debouncedSearchTerm, isDebounceLoading] = useDebounce(searchTerm, 150, defaultValue);
  const swiftTypeEndpoint = getSwiftTypeUrl({ query: debouncedSearchTerm, fields, functionalBoosts }, locale);

  const { data, error, isValidating } = useSWR(
    searchTerm && swiftTypeEndpoint ? swiftTypeEndpoint : null,
    (url) => fetch(url).then((res) => res.json()),
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: true },
  );

  const handleSearchTermChange = (newTerm: string) => {
    setSearchTerm(newTerm);
  };

  const isLoading = isValidating || isDebounceLoading;

  return {
    searchTerm,
    searchResults: data?.records?.page && !isLoading ? mapSearchResults(data?.records?.page) : [],
    handleSearchTermChange,
    isLoading,
    error,
  };
};

export default useTypeAhead;
