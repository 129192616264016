import { useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import { appLocales } from 'helpers/constants/appLocales';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { ImageHelpers } from 'helpers/imageHelpers';
import { tablet } from 'helpers/utils/screensizes';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getLocalizationInfo } from 'project.config';
import styles from './InlineSlider.module.scss';
import { IInlineSliderProps } from './types/IInlineSliderProps';
import { useRouter } from 'next/router';

const InlineSlider = ({ data }: IInlineSliderProps) => {
  const sliderRef = useRef<Slider>(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    centerMode: true,
    centerPadding: '70px',
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const router = useRouter();
  const locale = getLocalizationInfo(router.locale).locale.split('@')[0];
  const [isDesktop] = useMediaQuery(tablet);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const keys = ['Enter', 'ArrowRight'];
    if (sliderRef.current && keys.includes(event.key)) {
      sliderRef.current.slickNext();
    } else if (event.key === 'ArrowLeft' && sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      const el = document.getElementsByClassName('slick-next')[0];
      if (el) {
        //@ts-ignore
        el.onkeydown = handleKeyDown;
      }
    }
  }, [sliderRef]);

  if (locale === appLocales.PT_BR || !Boolean(data.imageGroup.length) || (!data.displayOnMobile && !isDesktop)) {
    return null;
  }

  return (
    <>
      <Container className={`${styles.inlineContainer}`} fluid data-testid={`nkn-inline-container`}>
        <Row className={`${styles.inlineRow} no-margin`}>
          <Col md={isDesktop ? 4 : 5} className={`${styles.contentWrap} text-left`}>
            <div className={` ${styles.content}`}>
              {data.iconImage && (
                <img
                  src={ImageHelpers.modifiedContentStackImageUrl(data.iconImage.url, '92')}
                  alt={data.iconAltText || 'inline-slider-icon'}
                  data-testid={`nkn-inline-icon`}
                  className={`${styles['nkn-inline-icon']}`}
                />
              )}
              <h3
                className={`${styles['nkn-inline-title']}`}
                data-testid={`nkn-inline-headline`}
                dangerouslySetInnerHTML={{ __html: data.headline }}
              ></h3>
              <div className={`${styles['nkn-inline-description']}`} data-testid={`nkn-inline-description`}>
                {data.description}
              </div>
            </div>
          </Col>
          <Col md={isDesktop ? 8 : 7} className={`no-padding`}>
            <Slider
              {...settings}
              className="carousel-one-text-list"
              data-testid={`nkn-inline-carousel`}
              ref={sliderRef}
            >
              {data.imageGroup.map(({ imageFile, imageAltText, uid, imageOneLiner }) => (
                <div key={uid} data-testid={`nkn-inline-item`}>
                  <img
                    src={ImageHelpers.modifiedContentStackImageUrl(imageFile.url, '828')}
                    loading="lazy"
                    alt={imageAltText}
                  />
                  {imageOneLiner && (
                    <div
                      className={styles['one-liner-text']}
                      data-testid={`nkn-inline-cap-wrapper-${uid}`}
                      dangerouslySetInnerHTML={{ __html: imageOneLiner }}
                    ></div>
                  )}
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InlineSlider;
