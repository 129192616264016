import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg
    height="20"
    width="20"
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
  >
    <g>
      <path
        fill="currentColor"
        d="M24.4,38.5c-3.8,0-7.4-1.5-10.2-4.2S10,27.9,10,24.1c0-3.8,1.5-7.4,4.2-10.2s6.3-4.2,10.2-4.2
            s7.4,1.5,10.2,4.2l0,0l0,0c2.7,2.7,4.2,6.3,4.2,10.2c0,3.8-1.5,7.4-4.2,10.2S28.2,38.5,24.4,38.5z M24.4,12c-3.2,0-6.3,1.3-8.6,3.5
            c-2.3,2.3-3.5,5.3-3.5,8.6c0,3.2,1.3,6.3,3.5,8.6c2.3,2.3,5.3,3.5,8.6,3.5s6.3-1.3,8.6-3.5c2.3-2.3,3.5-5.3,3.5-8.6
            c0-3.2-1.3-6.3-3.5-8.6l0,0C30.6,13.2,27.6,12,24.4,12z"
      />
    </g>
    <g>
      <line className="st0" x1="33.8" y1="33.5" x2="40.8" y2="40.5"></line>
      <path
        fill="currentColor"
        d="M40.8,41.6c-0.3,0-0.6-0.1-0.8-0.3l-7-7c-0.4-0.4-0.4-1.2,0-1.6c0.4-0.4,1.2-0.4,1.6,0l7,7
            c0.4,0.4,0.4,1.2,0,1.6C41.3,41.5,41.1,41.6,40.8,41.6z"
      />
    </g>
  </svg>
);

export default Icon;
