import React from 'react';
import Script from 'next/script';

const OneTrustSnippet = () => {
  return (
    <>
      <Script
        src={`https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`}
        id="one-trust-load-script"
        data-domain-script="1a64fd49-dba5-4588-8c61-4063961f9115"
      />
      <Script id="one-trust-show-script">{`function OptanonWrapper() { }`}</Script>
      <a href="javascript:void(0)" className="ot-sdk-show-settings" data-testid={`ot-sdk-show-settings`}>
        Cookie Preferences
      </a>
    </>
  );
};

export default OneTrustSnippet;
