import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './Footer.module.scss';
import FooterColumnGroup from './FooterColumnGroup/FooterColumnGroup';
import FooterNavigationGroup from './FooterNavigation';
import FooterSocial from './FooterSocial';
import { IFooterProps } from './types/IFooterProps';
import ContentBlocks from '../ContentBlocks';

const Footer = ({ data }: IFooterProps) => {
  const { aboveFooterBlocks, columnGroup, navigationGroup, socialMediaGroup } = data;

  if (!Boolean(socialMediaGroup.length) && !Boolean(columnGroup.length) && !Boolean(navigationGroup.linkGroup.length)) {
    return false as unknown as JSX.Element;
  }

  return (
    <>
      <section className="above-footer" data-testid="above-footer">
        <ContentBlocks data={aboveFooterBlocks} />
      </section>
      <footer id="nkn-footer" className={styles.footer} data-testid="footer" tabIndex={-1}>
        <Container>
          <FooterSocial socialMediaLinks={socialMediaGroup} />
          <FooterColumnGroup columnGroupItems={columnGroup} />
        </Container>
        <FooterNavigationGroup navigationGroup={navigationGroup} />
      </footer>
    </>
  );
};

export default Footer;
