import React from 'react';
import classNames from 'classnames';
import { Button, Col, Container, Row } from 'react-bootstrap';
import styles from './ButtonRow.module.scss';
import { BUTTON_COLOR } from './constants';
import { IButtonRowProps } from './types/IButtonRowProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';

const ButtonRow = ({ data }: IButtonRowProps) => {
  const { headline, description, expirationRow, buttonLinksGroup, uid } = data;
  const isExpired = expirationRow !== null && expirationRow.valueOf() - new Date().valueOf() < 0;
  const [containerRef, isVisible] = useAnimation();

  if (isExpired) {
    return false as unknown as JSX.Element;
  }

  return (
    <Container
      data-testid="button-row-container"
      ref={containerRef as any}
      className={classNames('animate', 'content-block', { 'in-view': isVisible })}
    >
      <Row key={uid} className={styles['button-row']} data-testid="button-row">
        <Col className="justify-content-center text-center">
          {headline && (
            <h2 className={`${styles['nkn-resp-title']} h2-lg`} data-testid="button-row-headline">
              {headline}
            </h2>
          )}

          {description && (
            <p className={styles['nkn-resp-description']} data-testid="button-row-description">
              {description}
            </p>
          )}

          {Boolean(buttonLinksGroup.length) && (
            <div className={styles.buttons}>
              {data.buttonLinksGroup.map(({ buttonLinkText, linkUrl, openInNewWindow, uid, buttonColor }) => (
                <Button
                  key={uid}
                  href={linkUrl}
                  target={openInNewWindow ? '_blank' : '_self'}
                  className={`rounded-pill ${styles['button-row-btn']}
                  ${BUTTON_COLOR[typeof buttonColor === 'string' ? buttonColor : 'black']}`}
                  data-testid={`button-row-button-${uid}`}
                >
                  {buttonLinkText}
                </Button>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ButtonRow;
