import React, { forwardRef } from 'react';
import { ImageHelpers } from 'helpers/imageHelpers';
import styles from './HotspotContent.module.scss';
import { IHotspotContentProps } from './types/IHotspotContentProps';

const HotspotContent = forwardRef(function ({ activeHotspot, textAlignment }: IHotspotContentProps, ref) {
  return (
    <div className="text-center" data-testid="hotspot-content-container">
      {activeHotspot.hotspotImage && (
        <img
          src={ImageHelpers.modifiedContentStackImageUrl(activeHotspot.hotspotImage.url, '225')}
          title={activeHotspot.hotspotImage.title}
          loading="lazy"
          alt={activeHotspot.hotspotImage.title}
          className={`${styles['hotspots-content-img']}`}
          data-testid={`hotspots-content-img`}
        />
      )}
      <div
        className={`${styles['wrapper']}`}
        ref={ref as unknown as React.RefObject<HTMLDivElement>}
        data-testid={`hotspot-content-wrapper`}
        tabIndex={0}
      >
        {activeHotspot.hotspotHeadline && (
          <div
            className={`${textAlignment} ${styles['hotspots-content-title']}`}
            dangerouslySetInnerHTML={{ __html: activeHotspot.hotspotHeadline }}
            data-testid={`hotspots-content-heading`}
          />
        )}

        {activeHotspot.hotspotDescription && (
          <div
            className={`${textAlignment} ${styles['hotspots-content-description']}`}
            dangerouslySetInnerHTML={{ __html: activeHotspot.hotspotDescription }}
            data-testid={`hotspots-content-desc`}
          />
        )}
      </div>
    </div>
  );
});
HotspotContent.displayName = 'HotspotContent';
export default HotspotContent;
