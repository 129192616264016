import React from 'react';
import Link from 'next/link';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './LNEArticleCategoryBlock.module.scss';
import { ILNEArticleCategoryBlockProps } from './types/ILNEArticleCategoryBlockProps';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { getLNECategoryUrl } from '../utils';

const LNEArticleCategoryBlock = ({
  data,
  ideasAndInspirationCount,
  productsAndInnovationCount,
  tipsAndTechniquesCount,
}: ILNEArticleCategoryBlockProps) => {
  const { formatMessage } = useFormat({ name: 'learn-and-explore' });
  return (
    <div data-testid="lne-article-category-block" className={styles['lne-article-category-block']}>
      <Container>
        <Row>
          {data.articleCategoryBlock?.articleCategoryReference?.map((item, index) => {
            let count = 0;
            switch (item?.categoryName) {
              case 'Ideas & Inspiration':
                count = ideasAndInspirationCount || 0;
                break;
              case 'Products & Innovation':
                count = productsAndInnovationCount || 0;
                break;
              case 'Tips & Techniques':
                count = tipsAndTechniquesCount || 0;
                break;
            }

            return (
              <Col xs={12} md={6} lg={4} className={styles.category} key={index} data-category-coll-id={item?.code}>
                <Link href={getLNECategoryUrl(item?.categoryName)}>
                  <a>
                    <img src={item?.categoryImage?.url} loading="lazy" alt={item?.categoryImage?.title} />
                    <div className={styles.info}>
                      <h2>{item?.categoryName}</h2>
                      {count > 0 && (
                        <p>
                          {count}{' '}
                          {formatMessage({
                            id: 'articles',
                            defaultMessage: 'Articles',
                          })}
                        </p>
                      )}
                    </div>
                  </a>
                </Link>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default LNEArticleCategoryBlock;
