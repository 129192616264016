import React from 'react';
import classNames from 'classnames';
import { RATINGS_BLOCK_STATUS } from 'helpers/constants/RatingsAndReviewBlocksConstants';
import { getReviewStatus } from 'helpers/utils/ratingsAndReviewUtils';
import styles from './RatingStars.module.scss';
import { IRatingStarsProp } from './types/IRatingStarProp';
import { appLocales } from '../../../../helpers/constants/appLocales';
import { TURN_TO_MINIMUM_REVIEWS_THRESHOLD } from '../../../../helpers/constants/turnTo';
import { useFormat } from '../../../../helpers/hooks/useFormat';

const RatingStars = ({ product, variant, locale }: IRatingStarsProp) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const isReviewable = Boolean(variant.attributes.isReviewable);
  const userCanReview = variant.attributes.reviewableDate
    ? new Date(variant.attributes.reviewableDate || '') < new Date()
    : true;
  const isUsaStore = locale === appLocales.EN_US;
  const isArchived = Boolean(product.isArchived);
  const numberOfReviews = product.reviewRatingStatistics?.count || 0;
  const reviewStatus = getReviewStatus({
    numberOfReviews,
    userCanReview,
  });

  let averageRating = parseFloat(product.reviewRatingStatistics?.averageRating?.toFixed(1) || '0');

  if (TURN_TO_MINIMUM_REVIEWS_THRESHOLD > numberOfReviews || reviewStatus === RATINGS_BLOCK_STATUS.COMING_SOON) {
    averageRating = 0;
  }

  if (!isUsaStore || isArchived || !isReviewable) {
    return false as unknown as JSX.Element;
  }

  const roundedAverageRating = Math.round(averageRating * 2) / 2; // round to nearest 0.5 or integer
  const ratingClassName = styles[`rating-${roundedAverageRating.toString().replace('.', '-')}`];

  return (
    <div className={styles['rating-stars']} data-testid="rating-stars">
      <div
        className={classNames(styles['stars'], ratingClassName)}
        aria-label={formatProductMessage({
          id: 'ratings.ariaLabel',
          defaultMessage: '{averageRating} Star Rating out of 5',
          values: { averageRating },
        })}
        title={`${averageRating} Star Rating`}
      />

      <span className={styles.count} data-testid={reviewStatus}>
        {reviewStatus === RATINGS_BLOCK_STATUS.COMING_SOON &&
          formatProductMessage({
            id: 'ratings.reviewComingSoon',
            defaultMessage: 'Reviews are coming soon',
          })}

        {reviewStatus === RATINGS_BLOCK_STATUS.BE_THE_FIRST_TO_WRITE &&
          formatProductMessage({
            id: 'ratings.beTheFirstReview',
            defaultMessage: 'Be the first to write a review',
          })}

        {reviewStatus === RATINGS_BLOCK_STATUS.REVIEW_COUNT &&
          formatProductMessage({
            id: 'ratings.numberOfReviews',
            defaultMessage: '{numberOfReviews} reviews',
            values: { numberOfReviews: numberOfReviews },
          })}
      </span>
    </div>
  );
};

export default RatingStars;
