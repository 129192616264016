import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import MarketingHeroHeadline from './MarketingHeroHeadline';
import MarketingHeroImage from './MarketingHeroImage';
import MarketingHeroVideo from './MarketingHeroVideo';
import { IMarketingHeroProps } from './types/IMarketingHeroProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';
import { desktop, tablet } from '../../../../helpers/utils/screensizes';
import { ETextAlignments } from '../../../../types/CommonFields';

const MarketingHero = ({ data }: IMarketingHeroProps) => {
  const [containerRef, isVisible] = useAnimation();
  const [isBiggerThanTablet] = useMediaQuery(tablet);
  const [isBiggerThanDesktop] = useMediaQuery(desktop);
  const isMobile = !isBiggerThanTablet;
  const isTablet = isBiggerThanTablet && !isBiggerThanDesktop;

  const {
    isImage,
    displayOnMobile,
    headline,
    headlineColor,
    textJustification,
    isBackgroundOverlay,
    imageContentGroup: image,
    nameImage,
    nameImageAlt,
    nameImageMaxWidthMobile,
    nameImageMaxWidthDesktop,
    isVideo,
    videoContentGroup: video,
  } = data;

  if (
    (!image?.imageDesktop && !image?.imageMobile && !video?.videoPath) ||
    (!displayOnMobile && isMobile) ||
    (isImage && !isMobile && !image?.imageDesktop) ||
    (isImage && isMobile && !image?.imageMobile) ||
    (isVideo && !video?.videoPath)
  ) {
    return null;
  }

  return (
    <Container
      data-testid="marketing-hero"
      fluid
      ref={containerRef as any}
      className={classNames('marketing-hero', 'animate', 'position-relative', 'p-0', {
        'in-view': isVisible,
      })}
    >
      {(headline || nameImage) && (
        <MarketingHeroHeadline
          headline={headline}
          headlineColor={headlineColor}
          isBackgroundOverlay={isBackgroundOverlay}
          nameImage={nameImage}
          nameImageAlt={nameImageAlt}
          nameImageMaxWidthMobile={nameImageMaxWidthMobile}
          nameImageMaxWidthDesktop={nameImageMaxWidthDesktop}
          textJustification={isTablet ? ETextAlignments.Center : textJustification}
        />
      )}
      {isImage && image && <MarketingHeroImage image={image} isMobile={isMobile} isTablet={isTablet} />}
      {isVideo && video && <MarketingHeroVideo video={video} />}
    </Container>
  );
};

export default MarketingHero;
