import React from 'react';
import HotspotsComponent from './HotspotsComponent';
import { IHotspotsProps } from './types/IHotspotsProps';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import { tablet } from '../../../../helpers/utils/screensizes';

const Hotspots = ({ data }: IHotspotsProps) => {
  const { displayOnMobile } = data;
  const [isBiggerThanTablet] = useMediaQuery(tablet);
  const isMobile = !isBiggerThanTablet;

  if (!displayOnMobile && isMobile) {
    return null;
  }

  return <HotspotsComponent data={data} />;
};

export default Hotspots;
