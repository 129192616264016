import { MouseEvent, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames/bind';
import { Nav, NavDropdown } from 'react-bootstrap';
import styles from './NavigationMenu.module.scss';
import { INavigationMenuProps } from './types/INavigationMenuProps';

const cx = classNames.bind(styles);

const NavigationMenu = ({
  menuItems,
  isMobileDevice,
  ariaRole = 'menubar',
  ariaLabel = 'menu',
}: INavigationMenuProps) => {
  const [isShowSubMenu, setIsShowSubmenu] = useState(false);
  const router = useRouter();

  if (!menuItems.length) {
    return false as unknown as JSX.Element;
  }

  const handleOnMouseClickOnDropdown = async (event: MouseEvent<HTMLElement>): Promise<void> => {
    if (isMobileDevice) {
      setIsShowSubmenu((prevState) => !prevState);
      return;
    }

    const eventTarget = event.target as HTMLElement;
    const redirect = eventTarget.parentElement?.getAttribute('redirect');

    if (!Boolean(redirect)) {
      return;
    }

    window.location.href = redirect as string;
  };

  return (
    <div className={styles.navigationContainer}>
      <Nav
        data-testid="navigation-menu"
        className={cx(styles.navigationMenu, { showSubNav: isShowSubMenu, hideSubNav: !isShowSubMenu })}
        role={ariaRole}
        aria-label={ariaLabel}
      >
        {menuItems.map((menuItem, index) => {
          if (!menuItem.hasSubMenu) {
            return (
              <Nav.Item key={menuItem.uid} role="none">
                <Nav.Link
                  role="menuitem"
                  href={menuItem.menuLink.href}
                  data-testid="navigation-menu-item"
                  target={menuItem.openInNewTab ? '_blank' : '_self'}
                >
                  {menuItem.menuLink.title}
                </Nav.Link>
              </Nav.Item>
            );
          }

          return (
            <NavDropdown
              title={menuItem.menuLink.title}
              key={`${menuItem.uid}-${index}_subMenu`}
              // @ts-ignore
              redirect={menuItem.menuLink.href}
              onClick={handleOnMouseClickOnDropdown}
              renderMenuOnMount={true}
              role="menuitem"
              data-testid="navigation-subMenu"
            >
              <NavDropdown.Item role="none" href={undefined} className={styles.dropdownMenuTitle}>
                {menuItem.menuLink.title}
              </NavDropdown.Item>

              {menuItem.subMenu.map((subMenuItem) => (
                <NavDropdown.Item
                  key={subMenuItem.uid}
                  href={subMenuItem.subMenuLink.href}
                  target={subMenuItem.openInNewTab ? '_blank' : '_self'}
                  className={cx({ 'd-lg-none': subMenuItem.displayOnlyMobile })}
                  role="menuitem"
                >
                  {subMenuItem.subMenuLink.title}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          );
        })}
      </Nav>
    </div>
  );
};

export default NavigationMenu;
